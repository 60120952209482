import React, { createContext, useCallback, useContext, useState } from 'react';

import { ILanguage } from '~app/types/language.interface';
import { Player } from '~app/types/player.type';
import { PlayerClass } from '~app/types/player-class.type';
import { PlayerFaction } from '~app/types/player-faction.type';
import { PlayerRace } from '~app/types/player-race.type';
import { PlayerWorld } from '~app/types/player-world.type';
import { ISelectOption } from '~app/types/select-option.interface';
import { Team } from '~app/types/team.type';
import API from '~app/utils/apis';

interface DataContextProps {
    languages: ILanguage[] | null;
    languagesSelectOptions: ISelectOption[];
    fetchLanguages: () => Promise<void>;

    playerClasses: PlayerClass[] | null;
    playerClassesSelectOptions: ISelectOption[];
    fetchPlayerClasses: () => Promise<void>;

    playerFactions: PlayerFaction[] | null;
    playerFactionsSelectOptions: ISelectOption[];
    fetchPlayerFactions: () => Promise<void>;

    playerRaces: PlayerRace[] | null;
    playerRacesSelectOptions: ISelectOption[];
    fetchPlayerRaces: () => Promise<void>;

    playerWorlds: PlayerWorld[] | null;
    playerWorldsSelectOptions: ISelectOption[];
    fetchPlayerWorlds: () => Promise<void>;
    addWorld: (world: PlayerWorld) => void;

    players: Player[] | null;
    playersSelectOptions: ISelectOption[];
    fetchPlayers: () => Promise<void>;
    addPlayer: (player: Player) => void;

    fetchPlayersData: () => Promise<void>;

    teams: Team[] | null;
    teamsSelectOptions: ISelectOption[];
    fetchTeams: (tournamentId: number) => Promise<void>;
    addTeam: (team: Team) => void;
}

export const DataContext = createContext<DataContextProps>({} as DataContextProps);

export const DataContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [languages, setLanguages] = useState<ILanguage[] | null>(null);
    const [languagesSelectOptions, setLanguagesSelectOptions] = useState<ISelectOption[]>([]);

    const [playerClasses, setPlayerClasses] = useState<PlayerClass[] | null>(null);
    const [playerClassesSelectOptions, setPlayerClassesSelectOptions] = useState<ISelectOption[]>([]);

    const [playerFactions, setPlayerFactions] = useState<PlayerFaction[] | null>(null);
    const [playerFactionsSelectOptions, setPlayerFactionsSelectOptions] = useState<ISelectOption[]>([]);

    const [playerRaces, setPlayerRaces] = useState<PlayerRace[] | null>(null);
    const [playerRacesSelectOptions, setPlayerRacesSelectOptions] = useState<ISelectOption[]>([]);

    const [playerWorlds, setPlayerWorlds] = useState<PlayerWorld[] | null>(null);
    const [playerWorldsSelectOptions, setPlayerWorldsSelectOptions] = useState<ISelectOption[]>([]);

    const [players, setPlayers] = useState<Player[] | null>(null);
    const [playersSelectOptions, setPlayersSelectOptions] = useState<ISelectOption[]>([]);

    const [teams, setTeams] = useState<Team[] | null>(null);
    const [teamsSelectOptions, setTeamsSelectOptions] = useState<ISelectOption[]>([]);

    const fetchLanguages = async () => {
        const resp = await API.Languages.fetchList();
        setLanguages(resp);

        if (resp) {
            const options = resp.map((_) => {
                return {
                    value: _.id,
                    label: _.name,
                };
            });

            setLanguagesSelectOptions(options);
        }
    };

    const fetchPlayerClasses = async () => {
        const resp = await API.PlayerClasses.fetchList();
        setPlayerClasses(resp);

        if (resp) {
            const options = resp.map((_) => {
                return {
                    value: _.id || -1,
                    label: _.name || '',
                };
            });

            setPlayerClassesSelectOptions(options);
        }
    };

    const fetchPlayerFactions = async () => {
        const resp = await API.PlayerFactions.fetchList();
        setPlayerFactions(resp);

        if (resp) {
            const options = resp.map((_) => {
                return {
                    value: _.id || -1,
                    label: _.name || '',
                };
            });

            setPlayerFactionsSelectOptions(options);
        }
    };

    const fetchPlayerRaces = async () => {
        const resp = await API.PlayerRaces.fetchList();
        setPlayerRaces(resp);

        if (resp) {
            const options = resp.map((_) => {
                return {
                    value: _.id || -1,
                    label: _.name || '',
                };
            });

            setPlayerRacesSelectOptions(options);
        }
    };

    const fetchPlayerWorlds = async () => {
        const resp = await API.PlayerWorlds.fetchList();
        setPlayerWorlds(resp);

        if (resp) {
            const options = resp.map((_) => {
                return {
                    value: _.id || -1,
                    label: _.name || '',
                };
            });

            setPlayerWorldsSelectOptions(options);
        }
    };

    const fetchPlayers = async () => {
        const resp = await API.Players.fetchList();
        setPlayers(resp);

        if (resp) {
            const options = resp
                .map((_) => {
                    return {
                        value: _.id || -1,
                        label: _.nick || '',
                    };
                })
                .sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }

                    return 0;
                });

            setPlayersSelectOptions(options);
        }
    };

    const fetchTeams = async (tournamentId: number) => {
        const resp = await API.Teams.fetchList(tournamentId);
        setTeams(resp);

        if (resp) {
            const options = resp
                .map((_) => {
                    return {
                        value: _.id || -1,
                        label: _.name || '',
                    };
                })
                .sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }

                    return 0;
                });

            setTeamsSelectOptions(options);
        }
    };

    const fetchPlayersData = async () => {
        await Promise.all([
            fetchPlayerClasses(),
            fetchPlayerFactions(),
            fetchPlayerRaces(),
            fetchPlayerWorlds(),
            fetchPlayers(),
        ]);

        return;
    };

    const addPlayer = useCallback((player: Player) => {
        setPlayers((prev) => {
            const result = prev === null ? [] : [...prev];

            const existPlayer = result.find((_) => _.id === player.id);
            if (!existPlayer) {
                return [...result, player];
            }

            return [...result];
        });

        setPlayersSelectOptions((prev) => {
            const result = [...prev];

            const existPlayer = result.find((_) => _.value === player.id);
            if (!existPlayer) {
                return [...result, { value: player.id || -1, label: player.nick || '' }];
            }

            return result.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }

                return 0;
            });
        });
    }, []);

    const addTeam = useCallback((team: Team) => {
        setTeams((prev) => {
            const result = prev === null ? [] : [...prev];

            const existTeam = result.find((_) => _.id === team.id);
            if (!existTeam) {
                return [...result, team];
            }

            return [...result];
        });

        setTeamsSelectOptions((prev) => {
            const result = [...prev];

            const existTeam = result.find((_) => _.value === team.id);
            if (!existTeam) {
                return [...result, { value: team.id || -1, label: team.name || '' }];
            }

            return result.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }

                return 0;
            });
        });
    }, []);

    const addWorld = useCallback((world: PlayerWorld) => {
        setPlayerWorlds((prev) => {
            const result = prev === null ? [] : [...prev];

            const existPlayer = result.find((_) => _.id === world.id);
            if (!existPlayer) {
                return [...result, world];
            }

            return [...result];
        });

        setPlayerWorldsSelectOptions((prev) => {
            const result = [...prev];

            const existPlayer = result.find((_) => _.value === world.id);
            if (!existPlayer) {
                return [...result, { value: world.id || -1, label: world.name || '' }];
            }

            return result;
        });
    }, []);

    return (
        <DataContext.Provider
            value={{
                languages,
                languagesSelectOptions,
                fetchLanguages,

                playerClasses,
                playerClassesSelectOptions,
                fetchPlayerClasses,

                playerFactions,
                playerFactionsSelectOptions,
                fetchPlayerFactions,

                playerRaces,
                playerRacesSelectOptions,
                fetchPlayerRaces,

                playerWorlds,
                playerWorldsSelectOptions,
                fetchPlayerWorlds,

                players,
                playersSelectOptions,
                fetchPlayers,

                fetchPlayersData,

                teams,
                teamsSelectOptions,
                fetchTeams,

                addPlayer,
                addTeam,
                addWorld,
            }}>
            {children}
        </DataContext.Provider>
    );
};

export const useDataContext = () => useContext(DataContext);
