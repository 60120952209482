import { Team } from '~app/types/team.type';
import { requestSecure } from '~app/utils/request';

export const fetchList = async (tournamentId: number): Promise<Team[]> => {
    return requestSecure<Team[]>(`/teams?tournamentId=${tournamentId}`, { method: 'GET' });
};

export const create = async (name: string): Promise<Team> => {
    return requestSecure<Team>('/teams', { method: 'POST', data: { name } });
};
