import React, { useCallback, useEffect, useState } from 'react';

import styles from './editable-field.module.scss';

interface IEditableFieldProps {
    name: string;
    value?: string;
    valueValidator?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    rows?: number;
    onSave: (value?: string) => Promise<void>;
    onSavedDelay?: number;
    isDisabled?: boolean;
    defaultValue?: string | number;
}

export const EditableField = (props: IEditableFieldProps) => {
    const { name, value, valueValidator, rows = 1, onSave, isDisabled, onSavedDelay = 2000, defaultValue } = props;

    const [displayedValue, setDisplayedValue] = useState<any>(value);
    const [newValue, setNewValue] = useState<string | undefined>(value);
    const [mode, setMode] = useState<string>('');
    const [error, setError] = useState<string>('');

    const autoSizeTextarea = (element: any) => {
        const maxWord: string = element.value
            .split(' ')
            .reduce((maxW: string, item: string) => (item.length >= maxW.length ? item : maxW));
        const hiddenElement = document.getElementById(`editableFieldHidden-${name}`);
        if (hiddenElement) {
            hiddenElement.innerHTML = maxWord;
            hiddenElement.style.fontFamily = 'inherit';
            hiddenElement.style.fontSize = '12px';
        }

        element.style.height = 'auto';
        element.style.height = element.scrollHeight + 2 + 'px';
    };

    const onClick = () => {
        setMode('edit');
    };

    const onBlur = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        save().then();
    };

    const onFocus = (event: any) => {
        const val = event.target.value;
        event.target.value = '';
        event.target.value = val;
        autoSizeTextarea(event.target);
    };

    const onKeyDown = (event: any) => {
        if (event.keyCode == 13 && rows === 1) {
            event.preventDefault();
            save().then();
        }

        if (event.keyCode == 27) {
            event.preventDefault();
            setMode('');
            setNewValue(displayedValue);
        }
    };

    const onChange = (event: any) => {
        setNewValue(event.target.value);
        autoSizeTextarea(event.target);
    };

    const save = useCallback(async (): Promise<void> => {
        if (onSavedDelay === 0) {
            await onSave(newValue);
            setMode('');

            return;
        }

        if (displayedValue === newValue) {
            setMode('');

            return;
        }

        try {
            setMode('saving');
            await onSave(newValue);
            setDisplayedValue(newValue);
            setMode('saved');
            setTimeout(() => setMode(''), onSavedDelay);
        } catch (err) {
            const error = err as Error;
            setError(error.message);
            setTimeout(() => {
                setMode('');
                setError('');
                setNewValue(displayedValue);
            }, 3000);
        }
    }, [displayedValue, newValue, onSavedDelay]);

    const getContainerStyle = (): string => {
        if (error) {
            return styles.editableField__error;
        }

        switch (mode) {
            case 'edit': {
                return styles.editableField__edit;
            }
            case 'saving': {
                return styles.editableField__saving;
            }
            case 'saved': {
                return styles.editableField__saved;
            }
            default: {
                return styles.editableField__default;
            }
        }
    };

    const getTextareaStyle = (): string => {
        if (error) {
            return styles.errorTextareaBorder;
        }

        switch (mode) {
            case 'saved': {
                return styles.savedTextareaBorder;
            }
            default: {
                return '';
            }
        }
    };

    useEffect(() => {
        setNewValue(value);
    }, [value]);

    return (
        <>
            <div className={[styles.editableField, getContainerStyle()].join(' ')} onClick={onClick}>
                {!mode && (
                    <div className={styles.editableField__text}>{newValue === undefined ? defaultValue : newValue}</div>
                )}
                {!!mode && (
                    <>
                        <div className={styles.overlay} onClick={(event) => onBlur(event)} />
                        <textarea
                            className={getTextareaStyle()}
                            rows={rows}
                            autoFocus={true}
                            value={newValue}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onInput={valueValidator}
                            disabled={isDisabled || mode === 'saving' || mode === 'saved'}
                            onKeyDown={onKeyDown}
                            onChange={onChange}
                        />
                    </>
                )}
            </div>
            <div id={`editableFieldHidden-${name}`} className={styles.editableFieldHidden} />
        </>
    );
};
