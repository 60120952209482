import React from 'react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { Loader } from '~app/components/loader/loader';
import { createSlot } from '~app/utils/create-slot';

export const ModalContentSlot = createSlot();
export const ModalFooterSlot = createSlot();

interface IModalContainerProps {
    children: JSX.Element | JSX.Element[];
    onClickClose: () => void;
    title: string | JSX.Element;
    isShowLoader?: boolean;
}

export const ModalContainer = (props: IModalContainerProps): JSX.Element => {
    return (
        <div className="popup active anime">
            <div className="popups-bg" />
            <div className="popup-wrap active">
                <div className="popup-main">
                    {props.isShowLoader && <Loader size="md" />}
                    <div className="popup-head">
                        <div className="popup-close">
                            <a className="link-back" onClick={props.onClickClose}>
                                <svg className="icon">
                                    <use href={`${MainSymbols}#image-chevron`}></use>
                                </svg>
                                close
                            </a>
                        </div>
                        <div className="popup-title h3">{props.title}</div>
                    </div>
                    <div className="popup-content">
                        <ModalContentSlot.Renderer childrenList={props.children} />
                    </div>
                    <div className="a-footer a-footer--start">
                        <ModalFooterSlot.Renderer childrenList={props.children} />
                    </div>
                </div>
            </div>
        </div>
    );
};
