import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import LockIcon from '~app/assets/sprites/lock.svg';
import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import TrashIcon from '~app/assets/sprites/trash.svg';
import { Control } from '~app/components/control';
import { useTournamentsContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { Bracket, BracketType } from '~app/types/bracket.type';
import { BracketColumn } from '~app/types/bracket-column.type';
import { arrayMoveItem } from '~app/utils';
import API from '~app/utils/apis';

const DEFAULT_BRACKET_COLUMNS: BracketColumn[] = [
    { title: '#', active: 1, ordinal: 0, locked: true },
    { title: 'player', active: 1, ordinal: 1, locked: true },
    { title: 'class', active: 1, ordinal: 2, locked: true },
    { title: 'faction', active: 1, ordinal: 3, locked: true },
    { title: 'game world', active: 1, ordinal: 4, locked: true },
];

const DEFAULT_TEAM_BRACKET_COLUMNS: BracketColumn[] = [
    { title: '#', active: 1, ordinal: 0, locked: true },
    { title: 'team', active: 1, ordinal: 1, locked: true },
];

export interface ITournamentBracketTableModalRef {
    open: (bracket?: Bracket) => Promise<void>;
}

export const TournamentBracketTableModal = forwardRef(
    (props: any, ref: React.Ref<ITournamentBracketTableModalRef>): JSX.Element | null => {
        const { tournament, refreshTournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [isSaving, setSaving] = useState<boolean>(false);
        const [bracketId, setBracketId] = useState<number | null>(null);
        const [title, setTitle] = useState<string>('');
        const [bracketColumns, setBracketColumns] = useState<BracketColumn[]>([]);

        const lockedColumns = useMemo<BracketColumn[]>(() => {
            if (!tournament) {
                return [];
            }

            return tournament?.type.isTeamMode ? DEFAULT_TEAM_BRACKET_COLUMNS : DEFAULT_BRACKET_COLUMNS;
        }, [tournament]);

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
        }, []);

        const titleOnChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(event.target.value);
        }, []);

        const updateRow = useCallback(
            (index: number, field: string, newValue?: any) => (event?: React.ChangeEvent<HTMLInputElement>) => {
                setBracketColumns((prev) => {
                    const result = [...prev];
                    const column = prev[index] ? { ...prev[index] } : null;

                    if (column) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        column[field] = newValue ?? event?.target.value;
                        result[index] = column;
                    }

                    return result;
                });
            },
            [],
        );

        const deleteRow = useCallback(
            (index: number) => () => {
                setBracketColumns((prev) => {
                    const result = [...prev];
                    result.splice(index, 1);

                    return result;
                });
            },
            [],
        );

        const addColumnOnClickHandler = useCallback(() => {
            setBracketColumns((prev) => {
                return [...prev, { title: '', active: 1, ordinal: prev.length }];
            });
        }, []);

        const changeColumnPosition = useCallback(
            (oldIndex: number, newIndex: number) => () => {
                if (newIndex < 0 || newIndex > bracketColumns.length) {
                    return;
                }

                setBracketColumns((prev) => {
                    return arrayMoveItem([...prev], oldIndex, newIndex);
                });
            },
            [bracketColumns],
        );

        const saveAndContinueOnClickHandler = useCallback(async () => {
            if (!tournament) {
                return;
            }

            if (!title) {
                toast.warn('Please fill title');

                return;
            }

            const columns = bracketColumns
                .filter((_) => !!_.title && !_.locked)
                .map((item, index) => ({
                    ...item,
                    ordinal: index + 1,
                }));

            const data = {
                tournamentId: tournament.id,
                title,
                columns,
                type: BracketType.TABLE,
                ordinal: 0,
            };

            try {
                setSaving(true);
                if (!bracketId) {
                    const res = await API.Brackets.create(data);
                    await refreshTournament(res);
                } else {
                    await API.Brackets.update(bracketId, data);
                    await refreshTournament();
                }

                setOpen(false);
            } finally {
                setSaving(false);
            }
        }, [tournament, title, bracketColumns, bracketId]);

        useImperativeHandle(ref, () => ({
            async open(bracket?: Bracket) {
                setOpen(true);

                if (bracket) {
                    setBracketId(bracket?.id);
                    setTitle(bracket.title);
                    setBracketColumns(bracket.table_columns ?? []);
                } else {
                    setBracketId(null);
                    setTitle('');
                    setBracketColumns((prev) => {
                        return [...prev, { title: '', active: 0, ordinal: prev.length }];
                    });
                }
            },
        }));

        if (!isOpen || !tournament) {
            return null;
        }

        return (
            <ModalContainer
                isShowLoader={isSaving}
                onClickClose={onClickCloseHandler}
                title={
                    <>
                        {!bracketId ? 'Add' : 'Edit'} bracket <span>/ table</span>
                    </>
                }>
                <ModalContentSlot>
                    <>
                        <div className="content-block">
                            <Control
                                title={'Name of table'}
                                isRequired
                                fields={[
                                    <div className="input" key="title">
                                        <input
                                            type="text"
                                            className="field"
                                            name="title"
                                            placeholder="title"
                                            value={title}
                                            onChange={titleOnChangeHandler}
                                        />
                                    </div>,
                                ]}
                            />
                        </div>
                        <div className="content-block">
                            <h5 className="content-block__title">enabled columns</h5>
                            <div className="controls sort-rows">
                                {[...lockedColumns, ...bracketColumns].map((column, index) => {
                                    return (
                                        <div
                                            key={`column-${index}`}
                                            className={`control sort-row ${column.active ? '' : 'disabled'}`}>
                                            <div className="control-side">
                                                <div
                                                    className={`checkbox checkbox--toggler ${column.locked ? 'disabled' : ''}`}>
                                                    <label className="checkbox-label" htmlFor={`tableColumn-${index}`}>
                                                        <input
                                                            className="checkbox-input"
                                                            type="checkbox"
                                                            id={`tableColumn-${index}`}
                                                            checked={column.active === 1}
                                                            onChange={updateRow(
                                                                index - lockedColumns.length,
                                                                'active',
                                                                column.active === 1 ? 0 : 1,
                                                            )}
                                                        />

                                                        <div className="checkbox-content">
                                                            <div className="checkbox-style"></div>
                                                            <div className="checkbox-text h6">
                                                                {column.active ? 'enabled' : 'disabled'}
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="control-content">
                                                <div className="control-fields">
                                                    <div className="control-field">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            style={{
                                                                opacity: column.locked || !column.active ? '0.5' : '1',
                                                                paddingRight: '38px',
                                                            }}
                                                            placeholder="new row"
                                                            value={column.title}
                                                            onChange={updateRow(index - lockedColumns.length, 'title')}
                                                            disabled={column.locked || !column.active}
                                                        />
                                                        {!column.locked && (
                                                            <img
                                                                className="icon icon-button"
                                                                src={TrashIcon}
                                                                alt=""
                                                                onClick={deleteRow(index - lockedColumns.length)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                {column.locked && (
                                                    <div className="control-sort">
                                                        <div className="sort disabled">
                                                            <img
                                                                className="icon"
                                                                src={LockIcon}
                                                                alt=""
                                                                style={{
                                                                    width: '22px',
                                                                    filter: 'contrast(0.01)',
                                                                    marginTop: '4px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                {!column.locked && (
                                                    <div className="control-sort">
                                                        <div className={`sort ${column.active ? '' : 'disabled'}`}>
                                                            {index > lockedColumns.length && (
                                                                <div
                                                                    className="sort-arrow up"
                                                                    onClick={changeColumnPosition(
                                                                        index - lockedColumns.length,
                                                                        index - lockedColumns.length - 1,
                                                                    )}>
                                                                    <svg className="icon">
                                                                        <use
                                                                            href={`${MainSymbols}#image-chevron`}></use>
                                                                    </svg>
                                                                </div>
                                                            )}
                                                            {index <
                                                                bracketColumns.length + lockedColumns.length - 1 && (
                                                                <div
                                                                    className="sort-arrow down"
                                                                    onClick={changeColumnPosition(
                                                                        index - lockedColumns.length,
                                                                        index - lockedColumns.length + 1,
                                                                    )}>
                                                                    <svg className="icon">
                                                                        <use
                                                                            href={`${MainSymbols}#image-chevron`}></use>
                                                                    </svg>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}

                                <button className="btn" type="button" onClick={addColumnOnClickHandler}>
                                    add column
                                </button>
                            </div>
                        </div>
                    </>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button
                            className="btn"
                            type="button"
                            onClick={saveAndContinueOnClickHandler}
                            disabled={isSaving}>
                            Save and continue
                        </button>
                        <button className="btn" type="button" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
