import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Loader } from '~app/components/loader/loader';
import { useTournamentsContext } from '~app/contexts';
import { Bracket } from '~app/types/bracket.type';
import { IBracketGroup } from '~app/types/bracket-group.interface';
import { IBracketGroupDuel } from '~app/types/bracket-group-duel.interface';
import API from '~app/utils/apis';

import styles from './bracket-group-duel.module.scss';
import { BracketGroupDuelItem } from './bracket-group-duel-item';

interface BracketGroupTableCellDuelProps {
    duel: IBracketGroupDuel;
    participantOneId: number;
    bracket: Bracket;
    group: IBracketGroup;
    canEdit: boolean;
}

export const BracketGroupTableCellDuel = (props: BracketGroupTableCellDuelProps): JSX.Element | null => {
    const { refreshTournament } = useTournamentsContext();

    const [duel, setDuel] = useState<IBracketGroupDuel>({ ...props.duel });
    const [isEditing, setEditing] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const duelRef = useRef({ ...duel });

    const canEdit = useMemo(() => {
        return props.canEdit && !!duel.participant_one_id && !!duel.participant_two_id;
    }, [props.canEdit, duel]);

    const blurAllTextarea = () => {
        const textareaList = document.querySelectorAll('textarea');
        for (let i = 0; i < textareaList.length; i++) {
            textareaList[i].blur();
        }

        return textareaList.length > 0;
    };

    const onClickDuelHandler = useCallback(() => {
        if (canEdit) {
            setEditing(true);
        }
    }, [canEdit]);

    const onChangeScore = useCallback(
        (ordinal: number) => async (value?: string) => {
            setDuel((prev) => {
                const result = { ...prev };

                switch (ordinal) {
                    case 1: {
                        result.score_one = value ? +value : 0;
                        if (!result.score_two) {
                            result.score_two = 0;
                        }
                        break;
                    }
                    case 2: {
                        result.score_two = value ? +value : 0;
                        if (!result.score_one) {
                            result.score_one = 0;
                        }
                        break;
                    }
                }

                return result;
            });
        },
        [],
    );

    const onClickCancelHandler = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            setDuel({ ...props.duel });
            setEditing(false);
        },
        [props.duel],
    );

    function delay(time: number) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const onClickSaveHandler = useCallback(
        async (event?: React.MouseEvent) => {
            event?.preventDefault();
            event?.stopPropagation();

            const t = blurAllTextarea();
            if (t) {
                await delay(300);
                onClickSaveHandler(event).then();

                return;
            }

            try {
                setSaving(true);

                const data = {
                    score_one: duelRef.current.score_one,
                    score_two: duelRef.current.score_two,
                };

                await API.Brackets.updateGroupDuel(props.bracket.id, props.group.id, duel.id, data);
                await refreshTournament();
                setEditing(false);
            } catch (e) {
                console.error(e);
            } finally {
                setSaving(false);
            }
        },
        [props.bracket, props.group, duel],
    );

    const onClickResetHandler = useCallback(() => {
        onChangeScore(1)();
        onChangeScore(2)();
        if (duelRef.current) {
            duelRef.current.score_one = 0;
            duelRef.current.score_two = 0;
        }
        onClickSaveHandler().then();
    }, []);

    const onClickTieHandler = useCallback(
        async (event?: React.MouseEvent) => {
            event?.preventDefault();
            event?.stopPropagation();

            const t = blurAllTextarea();
            if (t) {
                await delay(300);
                onClickSaveHandler(event).then();

                return;
            }

            try {
                setSaving(true);

                const data = {
                    score_one: Math.ceil((props.bracket.best_of ?? 1) / 2),
                    score_two: Math.ceil((props.bracket.best_of ?? 1) / 2),
                    draw: 1,
                };

                await API.Brackets.updateGroupDuel(props.bracket.id, props.group.id, duel.id, data);
                await refreshTournament();
                setEditing(false);
            } catch (e) {
                console.error(e);
            } finally {
                setSaving(false);
            }
        },
        [props.bracket, props.group, duel],
    );

    useEffect(() => {
        setDuel({ ...props.duel });
    }, [props.duel]);

    useEffect(() => {
        duelRef.current = { ...duel };
    }, [duel]);

    const participantOne = (
        <div
            className={[
                styles.score,
                duel.participant_one_id === duel.winner_id && styles.winner,
                duel.draw === 1 && styles.draw,
            ].join(' ')}>
            {duel.score_one}
        </div>
    );

    const participantTwo = (
        <div
            className={[
                styles.score,
                duel.participant_two_id === duel.winner_id && styles.winner,
                duel.draw === 1 && styles.draw,
            ].join(' ')}>
            {duel.score_two}
        </div>
    );

    const participantOneEdit = (
        <BracketGroupDuelItem
            tournamentParticipantId={
                props.group.participants.find((_) => _.id === duel.participant_one_id)?.participant_id ?? -1
            }
            duelParticipantId={duel.participant_one_id}
            duel={duel}
            canEdit={canEdit}
            bestOf={props.bracket.best_of}
            opponentScore={duel.score_two ?? 0}
            onChangeScore={onChangeScore(1)}
        />
    );

    const participantTwoEdit = (
        <BracketGroupDuelItem
            tournamentParticipantId={
                props.group.participants.find((_) => _.id === duel.participant_two_id)?.participant_id ?? -1
            }
            duelParticipantId={duel.participant_two_id}
            duel={duel}
            canEdit={canEdit}
            bestOf={props.bracket.best_of}
            opponentScore={duel.score_one ?? 0}
            onChangeScore={onChangeScore(2)}
        />
    );

    return (
        <div className={`${styles.duel} ${canEdit && styles.duelEditable}`}>
            <div className={styles.scoreContainer} onClick={onClickDuelHandler}>
                {props.participantOneId === duel.participant_one_id && (
                    <>
                        {participantOne}-{participantTwo}
                    </>
                )}

                {props.participantOneId !== duel.participant_one_id && (
                    <>
                        {participantTwo}-{participantOne}
                    </>
                )}
            </div>

            {isEditing && <div className={styles.editOverlay} />}
            <div
                className={`${styles.edit} ${isEditing ? styles.editVisible : styles.editHidden}`}
                style={{ position: 'fixed', top: 'calc(50% - 80px)', left: 'calc(50% - 120px)', width: '240px' }}>
                {saving && <Loader className={styles.editLoader} size="sm" />}
                {props.participantOneId === duel.participant_one_id && (
                    <>
                        {participantOneEdit}
                        {participantTwoEdit}
                    </>
                )}

                {props.participantOneId !== duel.participant_one_id && (
                    <>
                        {participantTwoEdit}
                        {participantOneEdit}
                    </>
                )}
                <div className={styles.actions}>
                    <button onClick={onClickCancelHandler}>Cancel</button>
                    <button onClick={onClickResetHandler}>Reset</button>
                    {props.bracket.with_draw === 1 && <button onClick={onClickTieHandler}>TIE</button>}
                    <button onClick={onClickSaveHandler}>Save</button>
                </div>
            </div>
        </div>
    );
};
