import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';

import { useTournamentsContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { validateParticipant } from '~app/modals/tournament-participants/tournament-participant.utils';
import { TournamentParticipantPlayer } from '~app/modals/tournament-participants/tournament-participant-player';
import { TournamentParticipantTeam } from '~app/modals/tournament-participants/tournament-participant-team';
import { createEmptyParticipant, Participant } from '~app/types/participant.type';
import { Player } from '~app/types/player.type';
import { Team } from '~app/types/team.type';
import API from '~app/utils/apis';

export interface ITournamentParticipantModalRef {
    open: (_participant?: Participant) => Promise<void>;
    close: () => void;
}

export const TournamentParticipantModal = forwardRef(
    (props: any, ref: React.Ref<ITournamentParticipantModalRef>): JSX.Element | null => {
        const { tournament, refreshTournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [isSaving, setSaving] = useState<boolean>(false);
        const [participant, setParticipant] = useState<Participant>(createEmptyParticipant());

        const isParticipantValid = useMemo(() => {
            const pP = validateParticipant(participant, tournament?.type?.playersInTeam ?? -1);
            if (tournament?.type?.isTeamMode) {
                return (
                    !!pP.team &&
                    !pP.team?.teamIdError &&
                    !pP.team.playersCountError &&
                    !!pP.team.players &&
                    pP.team.players.every(
                        (player) => !player.playerIdError && !player.classIdError && !player.raceIdError,
                    )
                );
            }

            return !!pP.player && !pP.player.playerIdError && !pP.player.classIdError && !pP.player.raceIdError;
        }, [participant, tournament]);

        const onUpdatePlayerHandler = useCallback((player: Player) => {
            setParticipant((prev) => ({ ...prev, player }));
        }, []);

        const onUpdateTeamHandler = useCallback((team: Team) => {
            setParticipant((prev) => ({ ...prev, team }));
        }, []);

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
        }, []);

        const saveAndContinueOnClickHandler = useCallback(
            (isAddAnother: boolean = false) =>
                async () => {
                    if (tournament?.slug && isParticipantValid) {
                        setSaving(true);
                        if (tournament.type.isTeamMode) {
                            participant.player = undefined;
                        } else {
                            participant.team = undefined;
                        }
                        try {
                            await API.Tournaments.updateTournamentParticipant(tournament.slug, participant);
                            await refreshTournament();
                            if (!isAddAnother) {
                                setOpen(false);
                            } else {
                                setParticipant(createEmptyParticipant);
                            }
                        } catch (err) {
                            console.error(err);
                        } finally {
                            setSaving(false);
                        }
                    }
                },
            [tournament, participant, isParticipantValid],
        );

        useImperativeHandle(ref, () => ({
            async open(_participant?: Participant) {
                setOpen(true);
                setParticipant(_participant ?? createEmptyParticipant());
            },
            close() {
                setOpen(false);
            },
        }));

        if (!isOpen || tournament === null) {
            return null;
        }

        return (
            <ModalContainer
                isShowLoader={isSaving}
                onClickClose={onClickCloseHandler}
                title={
                    <>
                        {participant.id === -1 ? 'Add' : 'Edit'} {tournament.type?.isTeamMode ? 'team' : 'player'}
                    </>
                }>
                <ModalContentSlot>
                    <div className="content-block">
                        {tournament.type?.isTeamMode && participant.team && (
                            <TournamentParticipantTeam team={participant.team} onUpdateTeam={onUpdateTeamHandler} />
                        )}

                        {!tournament.type?.isTeamMode && participant.player && (
                            <TournamentParticipantPlayer
                                player={participant.player}
                                onUpdatePlayer={onUpdatePlayerHandler}
                            />
                        )}
                    </div>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button
                            className="btn"
                            type="button"
                            onClick={saveAndContinueOnClickHandler()}
                            disabled={isSaving || !isParticipantValid}>
                            {participant.id === -1 ? 'Add' : 'Save'}
                        </button>
                        {participant.id === -1 && (
                            <button
                                className="btn"
                                type="button"
                                onClick={saveAndContinueOnClickHandler(true)}
                                disabled={isSaving || !isParticipantValid}>
                                Add and add another
                            </button>
                        )}
                        <button className="btn" type="button" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
