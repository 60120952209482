import moment from 'moment/moment';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PropsValue } from 'react-select';

import { useRootContext } from '~app/contexts/RootContext';
import { Bracket, BracketType } from '~app/types/bracket.type';
import { SELECT_ORDER_OPTIONS, Tournament, TournamentOrder, TournamentStatus } from '~app/types/tournament.type';
import { UserRole } from '~app/types/user.interface';
import { requestSecure } from '~app/utils/request';

import { request } from '../utils';

interface ITournamentFilter {
    search: string;
    status: { value: TournamentStatus | -1; label: string };
    type: { value: number; label: string };
    order: { value: TournamentOrder; label: string };
    dateBegin: Date | null;
}

interface TournamentsContextProps {
    tournaments: Tournament[] | null;
    tournament: Tournament | null;
    isAllowedEditMode: boolean;
    currentBracket: Bracket | null;
    setCurrentBracket: React.Dispatch<React.SetStateAction<Bracket | null>>;
    currentRoundId: string | null;
    setCurrentRoundId: React.Dispatch<React.SetStateAction<string | null>>;
    fetchTournaments: (myTournaments?: boolean) => Promise<void>;
    fetchTournament: (slug: string) => Promise<void>;
    refreshTournament: (data?: { bracketId: number }) => Promise<void>;
    filter: ITournamentFilter;
    updateFilter: (
        field: keyof ITournamentFilter,
        value:
            | string
            | Date
            | PropsValue<{
                  value: number;
                  label: string;
              }>
            | null,
    ) => void;
    clearTournamentsData: () => void;
}

const DEFAULT_FILTER_STATE = {
    search: '',
    status: { value: -1, label: 'All' },
    type: { value: -1, label: 'All' },
    order: SELECT_ORDER_OPTIONS[0],
    dateBegin: null,
};

export const TournamentsContext = createContext<TournamentsContextProps>({} as TournamentsContextProps);

export const TournamentsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { showLoader, profile } = useRootContext();

    const [originalTournaments, setOriginalTournaments] = useState<Tournament[] | null>(null);
    const [tournaments, setTournaments] = useState<Tournament[] | null>(null);
    const [tournament, setTournament] = useState<Tournament | null>(null);
    const [filter, setFilter] = useState<ITournamentFilter>(DEFAULT_FILTER_STATE);
    const [currentBracket, setCurrentBracket] = useState<Bracket | null>(null);
    const [currentRoundId, setCurrentRoundId] = useState<string | null>(null);

    const isAllowedEditMode = useMemo(() => {
        return (
            profile !== null &&
            tournament !== null &&
            ((profile.role === UserRole.ADMIN && tournament.created_by === profile.id) ||
                (profile.role === UserRole.ADMIN && tournament.organizer_id === profile.id) ||
                (profile.role === UserRole.ORGANIZER && tournament.organizer_id === profile.id) ||
                profile.role === UserRole.ROOT)
        );
    }, [profile, tournament]);

    const doFilterTournaments = (f: ITournamentFilter, oTournaments: Tournament[] | null) => {
        if (!oTournaments) {
            setTournaments(null);

            return;
        }

        const filteredTournaments = oTournaments
            .filter((_) => {
                if (f.search) {
                    return _.title.toLowerCase().includes(f.search.toLowerCase());
                }

                return true;
            })
            .filter((_) => {
                if (f.status.value !== -1) {
                    return _.status === f.status.value;
                }

                return true;
            })
            .filter((_) => {
                if (f.type.value !== -1) {
                    return _.type.id === f.type.value;
                }

                return true;
            })
            .filter((_) => {
                if (filter.dateBegin) {
                    return moment(_.date).isSame(moment(filter.dateBegin).utc(true));
                }

                return true;
            })
            .sort((a, b) => {
                switch (filter.order.value) {
                    case TournamentOrder.DATE: {
                        if (moment(a.date).isSame(moment(b.date))) {
                            return 0;
                        }

                        return moment(a.date).isAfter(moment(b.date)) ? -1 : 1;
                    }
                    case TournamentOrder.POOL: {
                        return a.pool - b.pool;
                    }
                    case TournamentOrder.TYPE: {
                        if (!a.type.id || !b.type.id) {
                            return 0;
                        }

                        return a.type.id - b.type.id;
                    }
                    case TournamentOrder.STATUS: {
                        return a.status - b.status;
                    }
                    case TournamentOrder.TITLE: {
                        if (a.title < b.title) {
                            return -1;
                        }
                        if (a.title > b.title) {
                            return 1;
                        }

                        return 0;
                    }
                    default: {
                        return 0;
                    }
                }
            });

        setTournaments(filteredTournaments);
    };

    const fetchTournaments = async (myTournaments: boolean = false) => {
        showLoader();
        setOriginalTournaments(null);

        const res = myTournaments
            ? await requestSecure<Tournament[]>('/profile/tournaments?myTournaments=true')
            : await request<Tournament[]>('/tournaments');

        setOriginalTournaments(res);
    };

    const fetchTournament = async (slug: string) => {
        showLoader();
        setTournament(null);
        const res = await request<Tournament>(`/tournaments/${slug}`);
        setTournament(res);
        setCurrentBracket(res.brackets?.length ? res.brackets[0] : null);
    };

    const refreshTournament = useCallback(
        async (data?: { bracketId: number }) => {
            if (tournament === null) {
                return;
            }

            const res = await request<Tournament>(`/tournaments/${tournament.slug}`);
            setTournament(res);

            if (data?.bracketId && !!res.brackets) {
                const index = res.brackets.findIndex((_) => _.id === data.bracketId);

                setCurrentBracket(index === -1 ? res.brackets[0] : res.brackets[index]);
                setCurrentRoundId(null);
            } else if (currentBracket !== null) {
                if (!res.brackets || res.brackets.length === 0) {
                    setCurrentBracket(null);

                    return;
                }

                const index = res.brackets.findIndex((_) => _.id === currentBracket.id);

                setCurrentBracket(index === -1 ? res.brackets[0] : res.brackets[index]);
            }
        },
        [tournament, currentBracket],
    );

    const updateFilter = (
        field: keyof ITournamentFilter,
        value:
            | string
            | Date
            | PropsValue<{
                  value: number;
                  label: string;
              }>
            | null,
    ) => {
        setFilter((prev) => {
            const newVal = { ...prev };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newVal[field] = value;

            return newVal;
        });
    };

    const clearTournamentsData = () => {
        setOriginalTournaments(null);
        setTournaments(null);
        setTournament(null);
        setFilter(DEFAULT_FILTER_STATE);
        setCurrentBracket(null);
        setCurrentRoundId(null);
    };

    useEffect(() => {
        doFilterTournaments(filter, originalTournaments);
    }, [filter, originalTournaments]);

    useEffect(() => {
        if (!currentBracket) {
            setCurrentRoundId(null);

            return;
        }

        if (currentBracket.type === BracketType.RELEGATION) {
            setCurrentRoundId((prev) => {
                if (prev !== null && ['main-bracket', 'defeat-bracket', 'grand-bracket'].includes(prev)) {
                    return prev;
                }

                return 'main-bracket';
            });

            return;
        }

        if (currentBracket.type === BracketType.GROUP) {
            setCurrentRoundId((prev) => {
                if (prev !== null && !!currentBracket.groups.find((_) => _.id.toString() === prev)) {
                    return prev;
                }

                return currentBracket.groups?.length ? currentBracket.groups[0].id.toString() : null;
            });

            return;
        }

        if (currentBracket.type === BracketType.SWISS) {
            setCurrentRoundId((prev) => {
                if (prev !== null && !!currentBracket.rounds.find((_) => _.ordinal.toString() === prev)) {
                    return prev;
                }

                return currentBracket.rounds?.length ? currentBracket.rounds[0].ordinal.toString() : null;
            });

            return;
        }

        if (currentBracket.type === BracketType.OLD_GROUP) {
            setCurrentRoundId(currentBracket.rounds?.length ? currentBracket.rounds[0].ordinal.toString() : null);

            return;
        }
    }, [currentBracket]);

    return (
        <TournamentsContext.Provider
            value={{
                tournaments,
                tournament,
                isAllowedEditMode,
                currentBracket,
                setCurrentBracket,
                currentRoundId,
                setCurrentRoundId,
                fetchTournaments,
                fetchTournament,
                refreshTournament,
                filter,
                updateFilter,
                clearTournamentsData,
            }}>
            {children}
        </TournamentsContext.Provider>
    );
};

export const useTournamentsContext = () => useContext(TournamentsContext);
