import * as BracketSwiss from './bracket-swiss';
import * as Brackets from './brackets';
import * as Languages from './languages';
import * as Organizers from './organizers';
import * as PlayerClasses from './player-classes';
import * as PlayerFactions from './player-factions';
import * as PlayerRaces from './player-races';
import * as PlayerWorlds from './player-worlds';
import * as Players from './players';
import * as Teams from './teams';
import * as Tournaments from './tournaments';

export default {
    BracketSwiss,
    Brackets,
    Languages,
    Organizers,
    PlayerClasses,
    PlayerFactions,
    PlayerRaces,
    PlayerWorlds,
    Players,
    Teams,
    Tournaments,
};
