import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import TrashIcon from '~app/assets/sprites/trash.svg';
import { useTournamentsContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { IRule } from '~app/types/rule.interface';
import { Tournament } from '~app/types/tournament.type';
import { arrayMoveItem } from '~app/utils';
import API from '~app/utils/apis';

const EMPTY_RULE: Partial<IRule> = {
    description: '',
    order: 0,
    title: '',
};

export interface ITournamentRulesModalRef {
    open: (_tournament?: Tournament) => Promise<void>;
    close: () => void;
}

export const TournamentRulesModal = forwardRef(
    (_props: any, ref: React.Ref<ITournamentRulesModalRef>): JSX.Element | null => {
        const { refreshTournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [isSaving, setSaving] = useState<boolean>(false);
        const [tournamentSlug, setTournamentSlug] = useState<string | null>(null);
        const [rules, setRules] = useState<Partial<IRule>[]>([]);

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
        }, []);

        const addEmptyRule = useCallback(() => {
            setRules((prev) => {
                return [...prev, { ...EMPTY_RULE }];
            });
        }, []);

        const deleteRule = useCallback(
            (index: number) => () => {
                setRules((prev) => {
                    const result = [...prev];
                    result.splice(index, 1);

                    return result;
                });
            },
            [],
        );

        const changeColumnPosition = useCallback(
            (oldIndex: number, newIndex: number) => () => {
                if (newIndex < 0 || newIndex > rules.length) {
                    return;
                }

                setRules((prev) => {
                    return arrayMoveItem([...prev], oldIndex, newIndex);
                });
            },
            [rules],
        );

        const updateRow = useCallback(
            (index: number, field: string, newValue?: any) => (event?: React.ChangeEvent<HTMLElement>) => {
                setRules((prev) => {
                    const result = [...prev];
                    const item = prev[index] ? { ...prev[index] } : null;

                    if (item) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        item[field] = newValue ?? event?.target.value;

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        result[index] = item;
                    }

                    return result;
                });
            },
            [],
        );

        const saveAndContinueOnClickHandler = useCallback(async () => {
            if (tournamentSlug) {
                setSaving(true);
                try {
                    const data = rules
                        .filter((_) => !!_.title && !!_.description)
                        .map((item, index) => ({
                            ...item,
                            order: index + 1,
                        }));

                    await API.Tournaments.updateTournamentRules(tournamentSlug, data);
                    await refreshTournament();
                    setOpen(false);
                } catch (err) {
                    console.error(err);
                } finally {
                    setSaving(false);
                }
            }
        }, [tournamentSlug, rules]);

        useImperativeHandle(ref, () => ({
            async open(_tournament?: Tournament) {
                if (_tournament) {
                    setOpen(true);
                    setTournamentSlug(_tournament.slug);
                    setRules(_tournament.rules ? [..._tournament.rules] : [{ ...EMPTY_RULE }]);
                }
            },
            close() {
                setOpen(false);
            },
        }));

        if (!isOpen || tournamentSlug === null) {
            return null;
        }

        return (
            <ModalContainer isShowLoader={isSaving} onClickClose={onClickCloseHandler} title={<>Edit rules</>}>
                <ModalContentSlot>
                    <div className="content-block">
                        <div className="add append">
                            <div className="add-table">
                                <div className="table table--static table--controls">
                                    <div className="table-content">
                                        <div className="table-inner">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            title <span>*</span>
                                                        </th>
                                                        <th>
                                                            description <span>*</span>
                                                        </th>
                                                        <th className="add-table__controls add-table__controls--top"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="sort-rows append-wrap" data-append="rule">
                                                    {rules.map((rule, index) => {
                                                        return (
                                                            <tr className="sort-row append-item" key={`rule-${index}`}>
                                                                <td>
                                                                    <div className="add-cell">
                                                                        <input
                                                                            type="text"
                                                                            className="field"
                                                                            style={{
                                                                                paddingRight: '38px',
                                                                                textTransform: 'unset',
                                                                            }}
                                                                            placeholder="new row"
                                                                            value={rule.title}
                                                                            onChange={updateRow(index, 'title')}
                                                                        />
                                                                    </div>
                                                                </td>

                                                                <td className="add-table__lg">
                                                                    <div className="add-cell">
                                                                        <textarea
                                                                            className="textarea textarea--md"
                                                                            style={{
                                                                                textTransform: 'unset',
                                                                            }}
                                                                            rows={4}
                                                                            value={rule.description}
                                                                            onChange={updateRow(index, 'description')}
                                                                        />
                                                                    </div>
                                                                </td>

                                                                <td className="add-table__controls add-table__controls--top">
                                                                    <div className="table-controls">
                                                                        <div className="sort">
                                                                            {index > 0 && (
                                                                                <div
                                                                                    className="sort-arrow up"
                                                                                    onClick={changeColumnPosition(
                                                                                        index,
                                                                                        index - 1,
                                                                                    )}>
                                                                                    <svg className="icon">
                                                                                        <use
                                                                                            href={`${MainSymbols}#image-chevron`}></use>
                                                                                    </svg>
                                                                                </div>
                                                                            )}
                                                                            {index < rules.length - 1 && (
                                                                                <div
                                                                                    className="sort-arrow down"
                                                                                    onClick={changeColumnPosition(
                                                                                        index,
                                                                                        index + 1,
                                                                                    )}>
                                                                                    <svg className="icon">
                                                                                        <use
                                                                                            href={`${MainSymbols}#image-chevron`}></use>
                                                                                    </svg>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="clear">
                                                                            <a
                                                                                className="clear-btn"
                                                                                onClick={deleteRule(index)}>
                                                                                <img
                                                                                    className="icon icon-button"
                                                                                    src={TrashIcon}
                                                                                    alt=""
                                                                                    style={{
                                                                                        filter: 'invert(100%)',
                                                                                        width: '24px',
                                                                                        position: 'unset',
                                                                                    }}
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-controls">
                                <div className="add-controls__btn">
                                    <button className="btn btn--md" onClick={addEmptyRule}>
                                        add one
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button
                            className="btn"
                            type="button"
                            onClick={saveAndContinueOnClickHandler}
                            disabled={isSaving}>
                            Save and continue
                        </button>
                        <button className="btn" type="button" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
