import React, { useEffect, useRef } from 'react';

import { TournamentParticipantPlayerCard } from '~app/components/tournament-participant-player-card';
import { Team } from '~app/types/team.type';

interface ITeamProps {
    team?: Team;
    justifyContent?: 'center' | 'flex-start' | 'flex-end';
}

export const TeamName = ({ team, justifyContent }: ITeamProps): JSX.Element => {
    const popupRef = useRef<HTMLDivElement>(null);
    const selfRef = useRef<HTMLDivElement>(null);

    const positionElement = () => {
        const targetRect = selfRef.current?.getBoundingClientRect();
        if (popupRef.current) {
            popupRef.current.style.top = `${(targetRect?.top ?? 0) - popupRef.current.getBoundingClientRect().height + 4}px`;
            popupRef.current.style.left = `${(targetRect?.right ?? 0) - 4}px`;
        }
    };

    useEffect(() => {
        positionElement();
        window.addEventListener('scroll', positionElement, { passive: true });

        return () => {
            window.removeEventListener('scroll', positionElement);
        };
    }, []);

    return (
        <div
            className="brackets-item__title"
            onMouseEnter={positionElement}
            style={{ display: 'flex', justifyContent: justifyContent ?? 'flex-start' }}>
            <div ref={selfRef} className="brackets-item__text">
                {team?.name}
            </div>
            <div ref={popupRef} className="brackets-item__title_popup" style={{ gap: '4px' }}>
                <div
                    style={{
                        color: '#fff',
                        width: '100%',
                        textAlign: 'center',
                        fontSize: '24px',
                        fontWeight: 900,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>
                    {team?.name}
                </div>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: 8,
                        justifyContent: 'center',
                    }}>
                    {team?.players?.map((player) => {
                        return <TournamentParticipantPlayerCard player={player} key={player.id} />;
                    })}
                </div>
            </div>
        </div>
    );
};
