import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRootContext } from '~app/contexts';

export const TwitchOauthCallbackPage = (): JSX.Element => {
    const navigate = useNavigate();
    const { twitchLogin } = useRootContext();

    useEffect(() => {
        const authResult = new URLSearchParams(window.location.search);
        const code = authResult.get('code');

        if (!code) {
            navigate('/');

            return;
        }

        twitchLogin(code)
            .then(() => navigate('/'))
            .catch(() => navigate('/'));
    }, []);

    return <></>;
};
