import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import TwitchLogoIcon from '~app/assets/sprites/twitch-logo.svg';
import { useRootContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { IApiError } from '~app/types/api-error.interface';

export interface ILoginModalRef {
    open: () => void;
    close: () => void;
}

export const LoginModal = forwardRef((props: any, ref: React.Ref<ILoginModalRef>): JSX.Element | null => {
    const { login } = useRootContext();

    const [isOpen, setOpen] = useState<boolean>(false);
    const [isShowPassword, setShowPassword] = useState<boolean>(false);
    const [loginInProgress, setLoginInProgress] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [twitchURL, setTwitchURL] = useState<string>('');

    const isValidForm = useMemo(() => {
        return !!email && !!password;
    }, [email, password]);

    useImperativeHandle(ref, () => ({
        async open() {
            setOpen(true);

            const qs = new URLSearchParams({
                response_type: 'code',
                client_id: process.env.REACT_APP_TWITCH_CLIENT_ID,
                redirect_uri: `${window.location.protocol}//${window.location.host}/oauth/twitch/callback`,
                scope: ['user:edit', 'user:read:email'].join(' '),
            } as unknown as URLSearchParams);

            setTwitchURL(`https://id.twitch.tv/oauth2/authorize?${qs.toString()}`);
        },
        close() {
            setOpen(false);
        },
    }));

    const onClickCloseHandler = useCallback(() => {
        setOpen(false);
    }, []);

    const onClickPasswordEye = useCallback(() => {
        setShowPassword((prev) => !prev);
    }, []);

    const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setError(null);
        setEmail(event.target.value);
    }, []);

    const handlePasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setError(null);
        setPassword(event.target.value);
    }, []);

    const onClickLoginHandler = useCallback(async () => {
        if (!isValidForm) {
            setError('Email and password should not empty');

            return;
        }

        setLoginInProgress(true);
        setError(null);

        try {
            await login(email, password);
            setOpen(false);
        } catch (err) {
            const error = err as unknown as IApiError;
            setError(error.error);
        } finally {
            setLoginInProgress(false);
        }
    }, [email, password, isValidForm]);

    if (!isOpen) {
        return null;
    }

    return (
        <ModalContainer title="Login" onClickClose={onClickCloseHandler} isShowLoader={loginInProgress}>
            <ModalContentSlot>
                <div className="content-block">
                    <div className="controls">
                        <div className="control">
                            <div className="control-side">
                                <div className="prop">
                                    email <span>*</span>
                                </div>
                            </div>
                            <div className="control-content">
                                <div className="control-fields">
                                    <div className={`control-field ${error ? 'error' : ''}`}>
                                        <input
                                            type="text"
                                            className="field"
                                            placeholder="enter email"
                                            value={email}
                                            onChange={handleEmailChange}
                                        />
                                        {error !== null && <div className="field-error">{error}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="control">
                            <div className="control-side">
                                <div className="prop">
                                    password <span>*</span>
                                </div>
                            </div>
                            <div className="control-content">
                                <div className="control-fields">
                                    <div className="control-field">
                                        <div className="password">
                                            <input
                                                type={isShowPassword ? 'text' : 'password'}
                                                className="field"
                                                placeholder="enter password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                            />
                                            <div className="password-btn">
                                                <div className="password-btn__icon" onClick={onClickPasswordEye}>
                                                    <svg className="icon">
                                                        {isShowPassword && (
                                                            <use href={`${MainSymbols}#image-pass-visible`}></use>
                                                        )}
                                                        {!isShowPassword && (
                                                            <use href={`${MainSymbols}#image-pass-hidden`}></use>
                                                        )}
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="control">*/}
                        {/*    <div className="control-side">*/}
                        {/*        <div className="prop"></div>*/}
                        {/*    </div>*/}
                        {/*    <div className="control-content">*/}
                        {/*        <a*/}
                        {/*            data-popup-show="forgotPassword"*/}
                        {/*            data-popup-hide="adminLogin"*/}
                        {/*        >*/}
                        {/*            Forgot your password?*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </ModalContentSlot>
            <ModalFooterSlot>
                <>
                    <button
                        className="btn account-login"
                        onClick={onClickLoginHandler}
                        disabled={loginInProgress || !isValidForm}>
                        login
                    </button>
                    <button className="btn" onClick={() => setOpen(false)} disabled={loginInProgress}>
                        cancel
                    </button>
                    <div style={{ flex: 1 }} />
                    <Link to={twitchURL}>
                        <img className="icon" src={TwitchLogoIcon} alt="" style={{ width: '80px' }} />
                    </Link>
                </>
            </ModalFooterSlot>
        </ModalContainer>
    );
});
