import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import TrashIcon from '~app/assets/sprites/trash.svg';
import { useTournamentsContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { Tournament } from '~app/types/tournament.type';
import { ITournamentMedia } from '~app/types/tournament-media.interface';
import API from '~app/utils/apis';

const EMPTY_MEDIA: Partial<ITournamentMedia> = {
    content: '',
};

export interface ITournamentMediaModalRef {
    open: (_tournament?: Tournament) => Promise<void>;
    close: () => void;
}

export const TournamentMedialModal = forwardRef(
    (_props: any, ref: React.Ref<ITournamentMediaModalRef>): JSX.Element | null => {
        const { refreshTournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [isSaving, setSaving] = useState<boolean>(false);
        const [tournamentSlug, setTournamentSlug] = useState<string | null>(null);
        const [media, setMedia] = useState<Partial<ITournamentMedia>[]>([]);

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
        }, []);

        const addEmptyMedia = useCallback(() => {
            setMedia((prev) => {
                return [...prev, { ...EMPTY_MEDIA }];
            });
        }, []);

        const deleteMedia = useCallback(
            (index: number) => () => {
                setMedia((prev) => {
                    const result = [...prev];
                    result.splice(index, 1);

                    return result;
                });
            },
            [],
        );

        const updateRow = useCallback(
            (index: number, field: string, newValue?: any) => (event?: React.ChangeEvent<HTMLElement>) => {
                setMedia((prev) => {
                    const result = [...prev];
                    const item = prev[index] ? { ...prev[index] } : null;

                    if (item) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        item[field] = newValue ?? event?.target.value;

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        result[index] = item;
                    }

                    return result;
                });
            },
            [],
        );

        const saveAndContinueOnClickHandler = useCallback(async () => {
            if (tournamentSlug) {
                setSaving(true);
                try {
                    const data = media.filter((_) => !!_.content);

                    await API.Tournaments.updateTournamentMedia(tournamentSlug, data);
                    await refreshTournament();
                    setOpen(false);
                } catch (err) {
                    console.error(err);
                } finally {
                    setSaving(false);
                }
            }
        }, [tournamentSlug, media]);

        useImperativeHandle(ref, () => ({
            async open(_tournament?: Tournament) {
                if (_tournament) {
                    setOpen(true);
                    setTournamentSlug(_tournament.slug);
                    setMedia(_tournament.media?.length ? [..._tournament.media] : [{ ...EMPTY_MEDIA }]);
                }
            },
            close() {
                setOpen(false);
            },
        }));

        if (!isOpen || tournamentSlug === null) {
            return null;
        }

        return (
            <ModalContainer isShowLoader={isSaving} onClickClose={onClickCloseHandler} title={<>Add media</>}>
                <ModalContentSlot>
                    <div className="content-block">
                        <div className="add append">
                            <div className="add-table">
                                <div className="table table--static table--controls">
                                    <div className="table-content">
                                        <div className="table-inner">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Link <span>*</span>
                                                        </th>
                                                        <th className="add-table__controls add-table__controls--top"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="sort-rows append-wrap" data-append="rule">
                                                    {media.map((m, index) => {
                                                        return (
                                                            <tr className="sort-row append-item" key={`rule-${index}`}>
                                                                <td>
                                                                    <div className="add-cell">
                                                                        <input
                                                                            type="text"
                                                                            className="field field--sm"
                                                                            style={{
                                                                                paddingRight: '38px',
                                                                            }}
                                                                            placeholder="enter"
                                                                            value={m.content}
                                                                            onChange={updateRow(index, 'content')}
                                                                        />
                                                                    </div>
                                                                </td>

                                                                <td className="add-table__controls add-table__controls--top">
                                                                    <div className="table-controls">
                                                                        <div className="clear">
                                                                            <a
                                                                                className="clear-btn"
                                                                                onClick={deleteMedia(index)}>
                                                                                <img
                                                                                    className="icon icon-button"
                                                                                    src={TrashIcon}
                                                                                    alt=""
                                                                                    style={{
                                                                                        filter: 'invert(100%)',
                                                                                        width: '24px',
                                                                                        position: 'unset',
                                                                                    }}
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-controls">
                                <div className="add-controls__btn">
                                    <button className="btn btn--md" onClick={addEmptyMedia}>
                                        add one
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button
                            className="btn"
                            type="button"
                            onClick={saveAndContinueOnClickHandler}
                            disabled={isSaving}>
                            Save and continue
                        </button>
                        <button className="btn" type="button" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
