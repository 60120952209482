import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';

import logo from '~app/assets/img/logo.png';
import MainSymbol from '~app/assets/sprites/main.symbol.svg';
import { useRootContext } from '~app/contexts';

import { HeaderPerson } from './header-person';
import { LanguageSwitch } from './language-switch';

export const Header = (): JSX.Element => {
    const { showLoader } = useRootContext();
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const [isHeaderFixed, setHeaderFixed] = useState<boolean>(false);
    const headerDropRef = useRef<HTMLDivElement>(null);

    const headerScroll = useCallback(() => {
        if (window.scrollY > 0) {
            setHeaderFixed(true);
        } else {
            setHeaderFixed(false);
        }
    }, []);

    const openMenu = useCallback(() => {
        if (headerDropRef.current) {
            headerDropRef.current.className = 'header-drop active anime';
        }
    }, [headerDropRef]);

    const handleMenuItemClick = useCallback(
        (newPathname: string | null) => {
            if (newPathname !== null && pathname !== newPathname) {
                showLoader();
            }

            if (headerDropRef.current) {
                headerDropRef.current.className = 'header-drop';
            }
        },
        [headerDropRef, pathname],
    );

    useEffect(() => {
        window.addEventListener('scroll', headerScroll);

        return () => {
            window.removeEventListener('scroll', headerScroll);
        };
    }, []);

    return (
        <header className={`header ${isHeaderFixed ? 'fixed' : ''}`}>
            <div className="container">
                <div className="header-container">
                    <div className="header-btn" onClick={openMenu}>
                        <div className="header-btn__inner">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="header-logo">
                        <Link className="logo" to="/">
                            <img className="logo-img" src={logo} alt="" />
                        </Link>
                    </div>
                    <div className="header-drop" ref={headerDropRef}>
                        <div className="close" onClick={() => handleMenuItemClick(null)}>
                            <div className="close-inner">
                                <div className="close-icon">
                                    <svg className="icon">
                                        <use href={`${MainSymbol}#image-chevron`}></use>
                                    </svg>
                                </div>
                                <div className="close-text">close</div>
                            </div>
                        </div>
                        <div className="header-drop__inner">
                            <div className="header-menu">
                                <ul className="header-menu__list">
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link"
                                            to="/tournaments"
                                            onClick={() => handleMenuItemClick('/tournaments')}>
                                            {t('common.tournaments')}
                                        </NavLink>
                                    </li>
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link"
                                            to="/champions"
                                            onClick={() => handleMenuItemClick('/champions')}>
                                            {t('common.champs')}
                                        </NavLink>
                                    </li>
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link"
                                            to="/media"
                                            onClick={() => handleMenuItemClick('/media')}>
                                            {t('common.media')}
                                        </NavLink>
                                    </li>
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link"
                                            to="/contacts"
                                            onClick={() => handleMenuItemClick('/contacts')}>
                                            {t('common.contacts')}
                                        </NavLink>
                                    </li>
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link"
                                            to="/donate"
                                            onClick={() => handleMenuItemClick('/donate')}>
                                            {t('common.donate')}
                                        </NavLink>
                                    </li>
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link "
                                            to="/thanks"
                                            onClick={() => handleMenuItemClick('/thanks')}>
                                            {t('common.thanks')}
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="header-controls">
                        <LanguageSwitch />
                        <HeaderPerson />
                    </div>
                </div>
            </div>
        </header>
    );
};
