import React, { useCallback, useMemo, useState } from 'react';

import { ReactComponent as CloseIcon } from '~app/assets/sprites/close.svg';
import { ReactComponent as EditIcon } from '~app/assets/sprites/edit.svg';
import { ReactComponent as TrashIcon } from '~app/assets/sprites/trash.svg';
import { TeamName } from '~app/components/common/team-name';
import { Image } from '~app/components/image';
import { Select } from '~app/components/select';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { Bracket } from '~app/types/bracket.type';
import { IBracketGroup } from '~app/types/bracket-group.interface';
import { Participant } from '~app/types/participant.type';
import { ISelectOption } from '~app/types/select-option.interface';
import { Tournament } from '~app/types/tournament.type';
import API from '~app/utils/apis';

import styles from './bracket-group-participant.module.scss';

interface IBracketGroupParticipant {
    tournament: Tournament;
    bracket: Bracket;
    group: IBracketGroup;
    tournamentParticipantId: number;
    groupParticipantId: number;
    ordinal: number;
}
export const BracketGroupParticipant = ({
    tournament,
    bracket,
    group,
    tournamentParticipantId,
    groupParticipantId,
    ordinal,
}: IBracketGroupParticipant): JSX.Element => {
    const { getAvatar } = useRootContext();
    const { refreshTournament } = useTournamentsContext();

    const [isEditing, setEditing] = useState<boolean>(false);
    const [isSaving, setSaving] = useState<boolean>(false);

    const [participant, avatar, canEditParticipant] = useMemo(() => {
        const participant = !tournament?.participants
            ? null
            : Object.values(tournament.participants).find((_) => _.id === tournamentParticipantId);

        const avatar = getAvatar(participant?.player ?? {});

        const activeParticipantDuels = group.duels.filter(
            (_) =>
                (_.participant_one_id === groupParticipantId || _.participant_two_id === groupParticipantId) &&
                (!!_.score_one || !!_.score_two),
        );

        return [participant, avatar, activeParticipantDuels.length === 0];
    }, [tournamentParticipantId, groupParticipantId, tournament, group]);

    const participantsOptions = useMemo<ISelectOption<Participant>[]>(() => {
        if (!tournament?.participants) {
            return [];
        }

        const distributedParticipants = bracket.groups
            .map((g) => g.participants.map((p) => p.participant_id ?? -1))
            .flat(1)
            .filter((_) => _ !== tournamentParticipantId);

        return Object.values(tournament.participants)
            .filter((tParticipant) => !distributedParticipants.includes(tParticipant.id))
            .map((tParticipant) => ({
                label: tParticipant.player?.nick ?? tParticipant.team?.name ?? '',
                value: tParticipant.id,
                data: tParticipant,
            }));
    }, [tournament, bracket, tournamentParticipantId]);

    const onClickEditIconHandler = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setEditing(true);
    }, []);

    const onClickCancelIconHandler = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            if (isSaving) {
                return;
            }

            setEditing(false);
        },
        [isSaving],
    );

    const onClickTrashIconHandler = useCallback(
        async (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            if (isSaving || !participant) {
                return;
            }

            try {
                setSaving(true);
                await API.Brackets.replaceParticipants(bracket.id, group.id, participant.id, null);
                await refreshTournament();
            } catch (error) {
                console.error(error);
            } finally {
                setSaving(false);
            }

            setEditing(false);
        },
        [isSaving, participant],
    );

    const onChangeParticipantHandler = useCallback(
        async (item: ISelectOption<Participant> | null) => {
            if (item?.value) {
                try {
                    setSaving(true);
                    if (!participant) {
                        const groupParticipants: number[] = group.participants
                            .map((_) => _.participant_id ?? -1)
                            .filter((_) => _ !== -1);
                        groupParticipants.push(item.value);

                        await API.Brackets.updateGroupParticipants(bracket.id, group.id, groupParticipants);
                    } else {
                        await API.Brackets.replaceParticipants(bracket.id, group.id, participant.id, item.value);
                    }
                    await refreshTournament();
                } catch (error) {
                    console.error(error);
                } finally {
                    setSaving(false);
                }
            }

            setEditing(false);
        },
        [bracket, group, participant],
    );

    return (
        <div className={styles.container}>
            <div className={styles.ordinal}>{ordinal}</div>

            <div className={styles.participant + ' table-player'}>
                {participant?.player && !isEditing && (
                    <>
                        <div className="table-player__avatar">
                            <Image img={avatar.img} webp={avatar.webp} defaultImg={avatar.defaultImg} />
                        </div>
                        <div
                            className={`table-player__name ${styles.participant_participantName}`}
                            style={{
                                color: participant.player?.class?.avatar,
                            }}>
                            {participant.player?.external_link && (
                                <a
                                    href={participant.player.external_link}
                                    className="no-decor"
                                    target="_blank"
                                    rel="noreferrer">
                                    {participant.player.nick}
                                </a>
                            )}
                            {!participant.player?.external_link && participant.player?.nick}
                        </div>
                    </>
                )}
                {participant?.team && !isEditing && (
                    <>
                        <div className={`table-player__name ${styles.participant_participantName}`}>
                            <TeamName team={participant.team} />
                        </div>
                    </>
                )}

                {canEditParticipant && isEditing && (
                    <Select
                        value={tournamentParticipantId}
                        onChange={onChangeParticipantHandler}
                        options={participantsOptions}
                        isFullWidth
                        isDisabled={isSaving}
                        showLoader={isSaving}
                        menuPlacement={ordinal > group.participants.length - 2 ? 'top' : 'bottom'}
                    />
                )}
            </div>

            {canEditParticipant && !isEditing && (
                <EditIcon className={styles.editIcon__stroke} onClick={onClickEditIconHandler} />
            )}
            {group.active === 0 && canEditParticipant && isEditing && (
                <TrashIcon className={styles.editIcon__fill} onClick={onClickTrashIconHandler} />
            )}
            {canEditParticipant && isEditing && (
                <CloseIcon className={styles.editIcon__fill} onClick={onClickCancelIconHandler} />
            )}
        </div>
    );
};
