import React from 'react';
import { Link } from 'react-router-dom';

import { TOURNAMENT_PRIZE_IMAGES } from '~app/assets';
import bg5 from '~app/assets/img/bg5.jpg';
import { Image } from '~app/components/image';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { Player } from '~app/types/player.type';

export const TournamentWinners = (): JSX.Element | null => {
    const { getAvatar } = useRootContext();
    const { tournament } = useTournamentsContext();

    if (
        !tournament?.winners?.standard.length &&
        !tournament?.winners?.special.length &&
        !tournament?.winners?.secondary.length
    ) {
        return null;
    }

    return (
        <section className="section section--main section--sm">
            <div className="section-bg">
                <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }} />
            </div>
            <div className="section-inner">
                <div className="container--sm">
                    <div className="content">
                        {!!tournament?.winners.standard.length && (
                            <div className="content-block">
                                <h6 className="content-block__title">Standard Prizes</h6>
                                <div className="prizes">
                                    {tournament.winners.standard.map((winner, index) => {
                                        const participant = tournament.participants[winner.participant_id];
                                        const playerAvatar = getAvatar((participant?.player || {}) as Player);

                                        return (
                                            <div className="prize" key={`standard-${index}`}>
                                                <picture>
                                                    <source
                                                        srcSet={TOURNAMENT_PRIZE_IMAGES[index].webp}
                                                        type="image/webp"
                                                    />
                                                    <source srcSet={TOURNAMENT_PRIZE_IMAGES[index].img} />
                                                    <img
                                                        className="prize-img"
                                                        src={TOURNAMENT_PRIZE_IMAGES[index].img}
                                                        alt=""
                                                    />
                                                </picture>
                                                <div className="prize-inner">
                                                    <div className="prize-participant" style={{ flex: 6 }}>
                                                        {!tournament.type.isTeamMode && (
                                                            <div className="participant">
                                                                <div className="participant-avatar">
                                                                    <Image
                                                                        img={playerAvatar.img}
                                                                        webp={playerAvatar.webp}
                                                                        defaultImg={playerAvatar.defaultImg}
                                                                    />
                                                                </div>
                                                                <div className="participant-content">
                                                                    <div
                                                                        className="participant-title h5"
                                                                        style={{
                                                                            color:
                                                                                participant.player?.class?.avatar ||
                                                                                '#fff',
                                                                        }}>
                                                                        {!!participant.player?.external_link && (
                                                                            <Link
                                                                                to={participant.player.external_link}
                                                                                className="no-decor"
                                                                                target="_blank">
                                                                                {participant.player.nick}
                                                                            </Link>
                                                                        )}
                                                                        {!participant.player?.external_link &&
                                                                            participant.player?.nick}
                                                                    </div>
                                                                    <div className="participant-prop">
                                                                        {participant.player?.class?.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {tournament.type.isTeamMode && (
                                                            <div className="participant" style={{ display: 'flex' }}>
                                                                <div
                                                                    className="participant-content"
                                                                    style={{ marginRight: '16px', flex: 2 }}>
                                                                    <div className="participant-title h5">
                                                                        {participant.team?.name}
                                                                    </div>
                                                                    <div className="participant-prop prop">Team</div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        flex: 6,
                                                                        display: 'flex',
                                                                        gap: '16px',
                                                                        justifyContent: 'center',
                                                                    }}>
                                                                    {participant.team?.players.map((player) => {
                                                                        const teamPlayerAvatar = getAvatar(
                                                                            player as Player,
                                                                        );

                                                                        return (
                                                                            <div
                                                                                key={player.id}
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    alignItems: 'center',
                                                                                    width: '120px',
                                                                                }}>
                                                                                <div
                                                                                    className="participant-avatar"
                                                                                    style={{ margin: 0 }}>
                                                                                    <Image
                                                                                        img={teamPlayerAvatar.img}
                                                                                        webp={teamPlayerAvatar.webp}
                                                                                        defaultImg={
                                                                                            teamPlayerAvatar.defaultImg
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="participant-content"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        textAlign: 'center',
                                                                                    }}>
                                                                                    <div
                                                                                        className="participant-title h6"
                                                                                        style={{
                                                                                            color:
                                                                                                player.class?.avatar ||
                                                                                                '#fff',
                                                                                        }}>
                                                                                        {!!player.external_link && (
                                                                                            <Link
                                                                                                to={
                                                                                                    player.external_link
                                                                                                }
                                                                                                className="no-decor"
                                                                                                target="_blank">
                                                                                                {player.nick}
                                                                                            </Link>
                                                                                        )}
                                                                                        {!player.external_link &&
                                                                                            player.nick}
                                                                                    </div>
                                                                                    <div className="participant-prop">
                                                                                        {player.class?.name}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="prize-content" style={{ flex: 2 }}>
                                                        <div className="h3 prize-value">{winner.money}</div>
                                                        <div className="h6 prize-place">{winner.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {!!tournament?.winners.special.length && (
                            <div className="content-block">
                                <h6 className="content-block__title">special prizes</h6>
                                <div className="prizes">
                                    {tournament.winners.special.map((winner, index) => {
                                        const participant = tournament.participants[winner.participant_id];
                                        const playerAvatar = getAvatar((participant?.player || {}) as Player);

                                        return (
                                            <div className="prize" key={`special-${index}`}>
                                                <picture>
                                                    <source
                                                        srcSet={TOURNAMENT_PRIZE_IMAGES[(index % 14) + 4].webp}
                                                        type="image/webp"
                                                    />
                                                    <source srcSet={TOURNAMENT_PRIZE_IMAGES[(index % 14) + 4].img} />
                                                    <img
                                                        className="prize-img"
                                                        src={TOURNAMENT_PRIZE_IMAGES[(index % 14) + 4].img}
                                                        alt=""
                                                    />
                                                </picture>
                                                <div className="prize-inner">
                                                    <div className="prize-participant" style={{ flex: 6 }}>
                                                        {!tournament.type.isTeamMode && (
                                                            <div className="participant">
                                                                <div className="participant-avatar">
                                                                    <Image
                                                                        img={playerAvatar.img}
                                                                        webp={playerAvatar.webp}
                                                                        defaultImg={playerAvatar.defaultImg}
                                                                    />
                                                                </div>
                                                                <div className="participant-content">
                                                                    <div
                                                                        className="participant-title h5"
                                                                        style={{
                                                                            color:
                                                                                participant.player?.class?.avatar ||
                                                                                '#fff',
                                                                        }}>
                                                                        {!!participant.player?.external_link && (
                                                                            <Link
                                                                                to={winner.player?.external_link ?? ''}
                                                                                className="no-decor"
                                                                                target="_blank">
                                                                                {participant.player.nick}
                                                                            </Link>
                                                                        )}
                                                                        {!participant.player?.external_link &&
                                                                            participant.player?.nick}
                                                                    </div>
                                                                    <div className="participant-prop">
                                                                        {participant.player?.class?.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {tournament.type.isTeamMode && (
                                                            <div className="participant">
                                                                <div
                                                                    className="participant-content"
                                                                    style={{ marginRight: '16px', flex: 2 }}>
                                                                    <div className="participant-title h5">
                                                                        {participant.team?.name}
                                                                    </div>
                                                                    <div className="participant-prop prop">Team</div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        flex: 6,
                                                                        display: 'flex',
                                                                        gap: '16px',
                                                                        justifyContent: 'center',
                                                                    }}>
                                                                    {participant.team?.players.map((player) => {
                                                                        const teamPlayerAvatar = getAvatar(
                                                                            player as Player,
                                                                        );

                                                                        return (
                                                                            <div
                                                                                key={player.id}
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    alignItems: 'center',
                                                                                    width: '120px',
                                                                                }}>
                                                                                <div
                                                                                    className="participant-avatar"
                                                                                    style={{ margin: 0 }}>
                                                                                    <Image
                                                                                        img={teamPlayerAvatar.img}
                                                                                        webp={teamPlayerAvatar.webp}
                                                                                        defaultImg={
                                                                                            teamPlayerAvatar.defaultImg
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="participant-content"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        textAlign: 'center',
                                                                                    }}>
                                                                                    <div
                                                                                        className="participant-title h6"
                                                                                        style={{
                                                                                            color:
                                                                                                player.class?.avatar ||
                                                                                                '#fff',
                                                                                        }}>
                                                                                        {!!player.external_link && (
                                                                                            <Link
                                                                                                to={
                                                                                                    player.external_link
                                                                                                }
                                                                                                className="no-decor"
                                                                                                target="_blank">
                                                                                                {player.nick}
                                                                                            </Link>
                                                                                        )}
                                                                                        {!player.external_link &&
                                                                                            player.nick}
                                                                                    </div>
                                                                                    <div className="participant-prop">
                                                                                        {player.class?.name}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="prize-content" style={{ flex: 2 }}>
                                                        <div className="h3 prize-value">{winner.money}</div>
                                                        <div className="h6 prize-place">{winner.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {!!tournament?.winners.secondary.length && (
                            <div className="content-block">
                                <h6 className="content-block__title">secondary prizes</h6>
                                <div className="prizes">
                                    {tournament.winners.secondary.map((winner, index) => {
                                        const participant = tournament.participants[winner.participant_id];
                                        const playerAvatar = getAvatar((participant?.player || {}) as Player);

                                        return (
                                            <div className="prize" key={`secondary-${index}`}>
                                                <div className="prize-inner">
                                                    <div className="prize-participant" style={{ flex: 6 }}>
                                                        {!tournament.type.isTeamMode && (
                                                            <div className="participant">
                                                                <div className="participant-avatar">
                                                                    <Image
                                                                        img={playerAvatar.img}
                                                                        webp={playerAvatar.webp}
                                                                        defaultImg={playerAvatar.defaultImg}
                                                                    />
                                                                </div>
                                                                <div className="participant-content">
                                                                    <div
                                                                        className="participant-title h5"
                                                                        style={{
                                                                            color:
                                                                                participant.player?.class?.avatar ||
                                                                                '#fff',
                                                                        }}>
                                                                        {!!participant.player?.external_link && (
                                                                            <Link
                                                                                to={participant.player.external_link}
                                                                                className="no-decor"
                                                                                target="_blank">
                                                                                {participant.player.nick}
                                                                            </Link>
                                                                        )}
                                                                        {!participant.player?.external_link &&
                                                                            participant.player?.nick}
                                                                    </div>
                                                                    <div className="participant-prop">
                                                                        {participant.player?.class?.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {tournament.type.isTeamMode && (
                                                            <div className="participant">
                                                                <div
                                                                    className="participant-content"
                                                                    style={{ marginRight: '16px', flex: 2 }}>
                                                                    <div className="participant-title h5">
                                                                        {participant.team?.name}
                                                                    </div>
                                                                    <div className="participant-prop prop">Team</div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        flex: 6,
                                                                        display: 'flex',
                                                                        gap: '16px',
                                                                        justifyContent: 'center',
                                                                    }}>
                                                                    {participant.team?.players.map((player) => {
                                                                        const teamPlayerAvatar = getAvatar(
                                                                            player as Player,
                                                                        );

                                                                        return (
                                                                            <div
                                                                                key={player.id}
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    alignItems: 'center',
                                                                                    width: '120px',
                                                                                }}>
                                                                                <div
                                                                                    className="participant-avatar"
                                                                                    style={{ margin: 0 }}>
                                                                                    <Image
                                                                                        img={teamPlayerAvatar.img}
                                                                                        webp={teamPlayerAvatar.webp}
                                                                                        defaultImg={
                                                                                            teamPlayerAvatar.defaultImg
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="participant-content"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        textAlign: 'center',
                                                                                    }}>
                                                                                    <div
                                                                                        className="participant-title h6"
                                                                                        style={{
                                                                                            color:
                                                                                                player.class?.avatar ||
                                                                                                '#fff',
                                                                                        }}>
                                                                                        {!!player.external_link && (
                                                                                            <Link
                                                                                                to={
                                                                                                    player.external_link
                                                                                                }
                                                                                                className="no-decor"
                                                                                                target="_blank">
                                                                                                {player.nick}
                                                                                            </Link>
                                                                                        )}
                                                                                        {!player.external_link &&
                                                                                            player.nick}
                                                                                    </div>
                                                                                    <div className="participant-prop">
                                                                                        {player.class?.name}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="prize-content" style={{ flex: 2 }}>
                                                        <div className="h3 prize-value">{winner.money}</div>
                                                        <div className="h6 prize-place">{winner.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
