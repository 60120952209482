import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Loader } from '~app/components/loader/loader';
import { useTournamentsContext } from '~app/contexts';
import { Bracket } from '~app/types/bracket.type';
import { IBracketRound } from '~app/types/bracket-round.interface';
import { copyDuel, IDuel } from '~app/types/duel.interface';
import { Participant } from '~app/types/participant.type';
import { ISelectOption } from '~app/types/select-option.interface';
import API from '~app/utils/apis';

import styles from './bracket-swiss-duel.module.scss';
import { BracketSwissDuelItem } from './bracket-swiss-duel-item';

interface IBracketSwissDuelProps {
    duel: IDuel;
    round: IBracketRound;
    bracket: Bracket;
    participantsOptions: ISelectOption<Participant>[];
    bracketParticipants: number[];
    canEdit: boolean;
    canEditParticipant: boolean;
}

export const BracketSwissDuel = (props: IBracketSwissDuelProps): JSX.Element | null => {
    const { refreshTournament } = useTournamentsContext();

    const [duel, setDuel] = useState<IDuel>(copyDuel(props.duel));
    const [oDuel, setODuel] = useState<IDuel>(copyDuel(props.duel));
    const [isEditing, setEditing] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const duelRef = useRef({ ...duel });

    const canEdit = useMemo(() => {
        return props.canEdit;
    }, [props.canEdit]);

    const blurAllTextarea = () => {
        const textareaList = document.querySelectorAll('textarea');
        for (let i = 0; i < textareaList.length; i++) {
            textareaList[i].blur();
        }

        return textareaList.length > 0;
    };

    const onClickDuelHandler = useCallback(() => {
        if (canEdit) {
            setEditing(true);
        }
    }, [canEdit]);

    const onChangeScore = useCallback(
        (ordinal: number) => async (value?: string) => {
            setDuel((prev) => {
                const result = { ...prev };

                switch (ordinal) {
                    case 1: {
                        result.participant1.score = value ? +value : 0;
                        if (!result.participant2.score) {
                            result.participant2.score = 0;
                        }
                        break;
                    }
                    case 2: {
                        result.participant2.score = value ? +value : 0;
                        if (!result.participant1.score) {
                            result.participant1.score = 0;
                        }
                        break;
                    }
                }

                return result;
            });
        },
        [],
    );

    const onChangeParticipant = useCallback(
        (ordinal: number) => async (item: ISelectOption<Participant> | null) => {
            setDuel((prev) => {
                const result = { ...prev };

                switch (ordinal) {
                    case 1: {
                        result.participant1.id = item ? item.value : 0;
                        if (result.participant1.player) {
                            result.participant1.player.nick = item?.data?.player?.nick ?? item?.data?.team?.name ?? '';
                            result.participant1.player.color = item?.data?.player?.class?.avatar ?? '#ffffff';
                            result.participant1.player.external_link = item?.data?.player?.external_link ?? '';
                        }
                        break;
                    }
                    case 2: {
                        result.participant2.id = item ? item.value : 0;
                        if (result.participant2.player) {
                            result.participant2.player.nick = item?.data?.player?.nick ?? item?.data?.team?.name ?? '';
                            result.participant2.player.color = item?.data?.player?.class?.avatar ?? '#ffffff';
                            result.participant2.player.external_link = item?.data?.player?.external_link ?? '';
                        }
                        break;
                    }
                }

                return result;
            });
        },
        [],
    );

    const onClickCancelHandler = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            setDuel(copyDuel(oDuel));
            duelRef.current = copyDuel(oDuel);
            setEditing(false);
        },
        [props.duel],
    );

    function delay(time: number) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const onClickSaveHandler = useCallback(
        async (event?: React.MouseEvent) => {
            event?.preventDefault();
            event?.stopPropagation();

            const t = blurAllTextarea();
            if (t) {
                await delay(300);
                onClickSaveHandler(event).then();

                return;
            }

            try {
                setSaving(true);

                const data = {
                    score_one: duelRef.current.participant1.score,
                    score_two: duelRef.current.participant2.score,
                    draw: duelRef.current.participant1.score === duelRef.current.participant2.score ? 1 : 0,
                    participant_one_id: duelRef.current.participant1.id || null,
                    participant_two_id: duelRef.current.participant2.id || null,
                };

                await API.BracketSwiss.updateGroupDuel(props.bracket.id, props.round.id, duel.id, data);
                await refreshTournament();
                setEditing(false);
            } catch (e) {
                console.error(e);
            } finally {
                setSaving(false);
            }
        },
        [props.bracket, props.round, duel],
    );

    const onClickTieHandler = useCallback(
        async (event?: React.MouseEvent) => {
            event?.preventDefault();
            event?.stopPropagation();

            const t = blurAllTextarea();
            if (t) {
                await delay(300);
                onClickSaveHandler(event).then();

                return;
            }

            try {
                setSaving(true);

                const data = {
                    score_one: Math.ceil((props.bracket.best_of ?? 1) / 2),
                    score_two: Math.ceil((props.bracket.best_of ?? 1) / 2),
                    participant_one_id: duelRef.current.participant1.id || null,
                    participant_two_id: duelRef.current.participant2.id || null,
                    draw: 1,
                };

                await API.BracketSwiss.updateGroupDuel(props.bracket.id, props.round.id, duel.id, data);
                await refreshTournament();
                setEditing(false);
            } catch (e) {
                console.error(e);
            } finally {
                setSaving(false);
            }
        },
        [props.bracket, props.round, duel],
    );

    const onClickResetHandler = useCallback(() => {
        onChangeScore(1)();
        onChangeScore(2)();
        duelRef.current.participant1.score = 0;
        duelRef.current.participant2.score = 0;
        onClickSaveHandler().then();
    }, []);

    useEffect(() => {
        setDuel(copyDuel(props.duel));
        setODuel(copyDuel(props.duel));
    }, [props.duel]);

    useEffect(() => {
        duelRef.current = { ...duel };
    }, [duel]);

    return (
        <div className={`${styles.duel} ${canEdit && styles.duelEditable}`}>
            <div className={styles.duelTitle}>{duel.order}</div>
            <div className={styles.duelItems} onClick={onClickDuelHandler}>
                <div className={styles.duelItemsContainer}>
                    <BracketSwissDuelItem participant={duel.participant1} ordinal={0} />
                    <div className={styles.duelItemDevider}>:</div>
                    <BracketSwissDuelItem participant={duel.participant2} ordinal={1} />
                </div>
            </div>
            {isEditing && <div className={styles.editOverlay} />}
            <div
                className={`${styles.edit} ${isEditing ? styles.editVisible : styles.editHidden}`}
                style={{ position: 'fixed', top: 'calc(50% - 80px)', left: 'calc(50% - 120px)', width: '320px' }}>
                {saving && <Loader className={styles.editLoader} size="sm" />}
                <BracketSwissDuelItem
                    participant={duel.participant1}
                    originalParticipant={{ ...oDuel.participant1 }}
                    canEdit={canEdit}
                    bestOf={props.bracket.best_of}
                    opponentScore={duel.participant2.score ?? 0}
                    canEditParticipant={
                        props.canEditParticipant && !oDuel.participant1.score && !oDuel.participant2.score
                    }
                    bracketParticipants={props.bracketParticipants}
                    participantsOptions={props.participantsOptions}
                    onChangeParticipant={onChangeParticipant(1)}
                    onChangeScore={onChangeScore(1)}
                    ordinal={0}
                />
                <BracketSwissDuelItem
                    participant={duel.participant2}
                    originalParticipant={{ ...oDuel.participant2 }}
                    canEdit={canEdit}
                    bestOf={props.bracket.best_of}
                    opponentScore={duel.participant2.score ?? 0}
                    canEditParticipant={
                        props.canEditParticipant && !oDuel.participant1.score && !oDuel.participant2.score
                    }
                    bracketParticipants={props.bracketParticipants}
                    participantsOptions={props.participantsOptions}
                    onChangeParticipant={onChangeParticipant(2)}
                    onChangeScore={onChangeScore(2)}
                    ordinal={1}
                />
                <div className={styles.actions}>
                    <button onClick={onClickCancelHandler}>Cancel</button>
                    <button onClick={onClickResetHandler}>Reset</button>
                    {props.bracket.with_draw === 1 && <button onClick={onClickTieHandler}>TIE</button>}
                    <button onClick={onClickSaveHandler}>Save</button>
                </div>
            </div>
        </div>
    );
};
