import { Bracket } from '~app/types/bracket.type';
import { Participant } from '~app/types/participant.type';
import { IPrize } from '~app/types/prize.interface';
import { IRule } from '~app/types/rule.interface';
import { ISchedule } from '~app/types/schedule.interface';
import { ITournamentMedia } from '~app/types/tournament-media.interface';
import { TournamentType } from '~app/types/tournament-type.type';
import { Winner } from '~app/types/winner.type';

export enum TournamentStatus {
    STATUS_PENDING,
    STATUS_IN_PROGRESS,
    STATUS_COMPLETED,
}
export enum TournamentOrder {
    DATE,
    POOL,
    TYPE,
    STATUS,
    TITLE,
}

export const TOURNAMENT_STATUS_DICT = new Map([
    [TournamentStatus.STATUS_PENDING, 'Pending'],
    [TournamentStatus.STATUS_IN_PROGRESS, 'In progress'],
    [TournamentStatus.STATUS_COMPLETED, 'Completed'],
]);

export const SELECT_STATUS_OPTIONS = [
    { value: -1, label: 'All' },
    { value: TournamentStatus.STATUS_PENDING, label: 'Pending' },
    { value: TournamentStatus.STATUS_IN_PROGRESS, label: 'In progress' },
    { value: TournamentStatus.STATUS_COMPLETED, label: 'Completed' },
];

export const SELECT_ORDER_OPTIONS = [
    { value: TournamentOrder.DATE, label: 'Date' },
    { value: TournamentOrder.POOL, label: 'Prize pool' },
    { value: TournamentOrder.TYPE, label: 'Type' },
    { value: TournamentOrder.STATUS, label: 'Status' },
    { value: TournamentOrder.TITLE, label: 'Title' },
];

export enum ScheduleType {
    NON_PRIMARY,
    PRIMARY,
}

export type Tournament = {
    id: number;
    title: string;
    slug: string;
    status: TournamentStatus;
    pool: number;
    date: string;
    date_final: string;
    time: string;
    time_final: string;
    type: TournamentType;
    bg_image: string;
    organizer_id: number | null;
    organizer_name: string;
    language_id: number;
    language_name: string;
    created_by: number;
    updated_by: number;
    created_at: string;
    updated_at: string;
    time_zone: number;
    prize_one: string | null;
    prize_two: string | null;
    prize_three: string | null;
    prize_four: string | null;
    schedule_type: ScheduleType;
    show_on_main_page: number;
    is_primary: number;
    pool_custom: string;
    participants: { [key: number]: Participant };
    brackets?: Bracket[];
    schedules?: ISchedule[];
    media?: ITournamentMedia[];
    prizes?: IPrize[];
    rules?: IRule[];
    winners?: {
        secondary: Winner[];
        special: Winner[];
        standard: Winner[];
    };
};

export type TournamentFormikProps = {
    slug?: string;
    startDate: Date | null;
    startTime: Date | null;
    finalDate: Date | null;
    finalTime: Date | null;
    bgImage?: string;
    organizer_id: number | null;
    type_id: number | null;
    language_id: number | null;
    title?: string;
    time_zone: number | null;
    pool_custom?: string;
};
