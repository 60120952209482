import React, { useCallback, useEffect, useRef, useState } from 'react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { cn } from '~app/utils';
import { useOutsideAlerter } from '~app/utils/hooks/use-outside.hook';

import S from './dropdown.module.scss';

interface IDropdownProps {
    title: string;
    isActive?: boolean;
    onClick?: () => void;
    items?: { label: string | JSX.Element; action: () => void; isDisabled?: boolean }[];
    size?: 'sm' | 'xs';
}

export const Dropdown = ({ title, isActive, items = [], onClick, size = 'sm' }: IDropdownProps): JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const menuRef = useRef<HTMLDivElement>(null);
    const selfRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(selfRef, () => setOpen(false));

    const toggleOpen = useCallback(() => {
        positionElement();
        setOpen((prev) => !prev);
    }, []);

    const positionElement = () => {
        const targetRect = selfRef.current?.getBoundingClientRect();

        if (menuRef.current) {
            menuRef.current.style.top = `${targetRect?.bottom ?? 0}px`;
            menuRef.current.style.left = `${targetRect?.left ?? 0}px`;
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', positionElement, { passive: true });

        return () => {
            window.removeEventListener('scroll', positionElement);
        };
    }, []);

    return (
        <div ref={selfRef} className={cn(S.dropdown, S[`dropdown__${size}`], isOpen ? S.dropdown__is_open : null)}>
            <div className={cn(S.label_container, isActive ? S.label_container__active : null)}>
                <div className={S.label} onClick={onClick}>
                    {title}
                </div>
                <div className={S.icon} onClick={toggleOpen}>
                    <svg>
                        <use href={`${MainSymbols}#image-chevron`}></use>
                    </svg>
                </div>
            </div>
            <div ref={menuRef} className={cn(S.menu_container)}>
                <div className="close" onClick={toggleOpen}>
                    <div className="close-inner">
                        <div className="close-icon">
                            <svg className="icon">
                                <use href={`${MainSymbols}#image-chevron`}></use>
                            </svg>
                        </div>
                        <div className="close-text">close</div>
                    </div>
                </div>
                <ul className={S.menu_container_items}>
                    {items.map((_, i) => (
                        <li
                            className={cn(S.item, _.isDisabled ? S.item__disabled : null)}
                            onClick={_.action}
                            key={`dropdown-item-${i}`}>
                            {_.label}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
