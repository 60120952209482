import { IExternalPlayer } from '~app/types/external-player';
import { Player } from '~app/types/player.type';
import { requestSecure } from '~app/utils/request';

export const fetchList = async (): Promise<Player[]> => {
    return requestSecure<Player[]>('/players', { method: 'GET' });
};

export const create = async (data: Partial<Player>): Promise<Player> => {
    return requestSecure<Player>('/players', { method: 'POST', data });
};

export const update = async (playerId: number, data: Partial<Player>): Promise<Player> => {
    return requestSecure<Player>(`/players/${playerId}`, { method: 'PUT', data });
};

export const external = async (link: string): Promise<IExternalPlayer> => {
    return requestSecure<IExternalPlayer>(`/players/external?link=${link}`, { method: 'GET' });
};
