import { ISelectOption } from '~app/types/select-option.interface';

export enum PlayerRegion {
    US = 'US',
    EU = 'EU',
    KR = 'KR',
    TW = 'TW',
    CN = 'CN',
}

export const REGION_SELECT_OPTIONS: ISelectOption<PlayerRegion>[] = [
    { value: 0, data: PlayerRegion.US, label: 'US' },
    { value: 1, data: PlayerRegion.EU, label: 'EU' },
    { value: 2, data: PlayerRegion.KR, label: 'KR' },
    { value: 3, data: PlayerRegion.TW, label: 'TW' },
    { value: 4, data: PlayerRegion.CN, label: 'CN' },
];
