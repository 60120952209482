import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import BracketType1 from '~app/assets/img/bracket-type1.svg';
import BracketType2 from '~app/assets/img/bracket-type2.svg';
import BracketType3 from '~app/assets/img/bracket-type3.svg';
import BracketType4 from '~app/assets/img/bracket-type4.svg';
import { useTournamentsContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { BracketType } from '~app/types/bracket.type';

export interface ITournamentBracketModalRef {
    open: () => void;
}

export interface ITournamentBracketProps {
    onNext: (bracketType: BracketType) => void;
}

export const TournamentBracketModal = forwardRef(
    ({ onNext }: ITournamentBracketProps, ref: React.Ref<ITournamentBracketModalRef>): JSX.Element | null => {
        const { tournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [bracketType, setBracketType] = useState<BracketType | null>(null);

        useImperativeHandle(ref, () => ({
            open() {
                setOpen(true);
            },
        }));

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
        }, []);

        const updateBracketType = useCallback(
            (_bracketType: BracketType) => () => {
                setBracketType(_bracketType);
            },
            [],
        );

        const handleNextBtnClick = useCallback(() => {
            if (bracketType !== null) {
                onNext(bracketType);
                setOpen(false);
            }
        }, [bracketType]);

        if (!isOpen) {
            return null;
        }

        return (
            <ModalContainer
                onClickClose={onClickCloseHandler}
                title={
                    <>
                        Add bracket <span>/ choose type</span>
                    </>
                }>
                <ModalContentSlot>
                    <div className="content-block">
                        <div className="type-controls">
                            {tournament?.type.id !== 2 && (
                                <>
                                    <div className="type-control">
                                        <div className="type-control__radio">
                                            <div className="radio">
                                                <label className="radio-label" htmlFor="bracketType1">
                                                    <input
                                                        className="radio-input"
                                                        type="radio"
                                                        id="bracketType1"
                                                        checked={bracketType === BracketType.RELEGATION}
                                                        onChange={updateBracketType(BracketType.RELEGATION)}
                                                    />
                                                    <div className="radio-content">
                                                        <div className="radio-style"></div>
                                                        <div className="radio-text h6">Elimination</div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="type-control__content">
                                            <img className="type-control__img" src={BracketType1} alt="" />
                                            <div className="type-control__text">
                                                In most multi-table tournaments, you are considered eliminated when you
                                                run out of chips. Some tournaments, however, will allow you to buy in
                                                more than once.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="type-control">
                                        <div className="type-control__radio">
                                            <div className="radio">
                                                <label className="radio-label" htmlFor="bracketType2">
                                                    <input
                                                        className="radio-input"
                                                        type="radio"
                                                        id="bracketType2"
                                                        checked={bracketType === BracketType.GROUP}
                                                        onChange={updateBracketType(BracketType.GROUP)}
                                                    />
                                                    <div className="radio-content">
                                                        <div className="radio-style"></div>
                                                        <div className="radio-text h6">Group</div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="type-control__content">
                                            <img className="type-control__img" src={BracketType2} alt="" />
                                            <div className="type-control__text">
                                                The winner of a multi-table tournament is the person who has all of the
                                                chips in the end. The other places are determined based on order of
                                                elimination.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="type-control">
                                        <div className="type-control__radio">
                                            <div className="radio">
                                                <label className="radio-label" htmlFor="bracketType3">
                                                    <input
                                                        className="radio-input"
                                                        type="radio"
                                                        id="bracketType3"
                                                        checked={bracketType === BracketType.SWISS}
                                                        onChange={updateBracketType(BracketType.SWISS)}
                                                    />
                                                    <div className="radio-content">
                                                        <div className="radio-style"></div>
                                                        <div className="radio-text h6">swiss</div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="type-control__content">
                                            <img className="type-control__img" src={BracketType3} alt="" />
                                            <div className="type-control__text">
                                                One of the largest and most recognized multi-table tournaments in the
                                                world is the World Series of Poker main event. Thousands of people from
                                                all over the world put down $10,000 each year to try and win millions of
                                                dollars. Names such as Phil Hellmuth, Johnny Chan and Doyle Brunson have
                                                all won the World Series of Poker main event.
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="type-control">
                                <div className="type-control__radio">
                                    <div className="radio">
                                        <label className="radio-label" htmlFor="bracketType4">
                                            <input
                                                className="radio-input"
                                                type="radio"
                                                id="bracketType4"
                                                checked={bracketType === BracketType.TABLE}
                                                onChange={updateBracketType(BracketType.TABLE)}
                                            />
                                            <div className="radio-content">
                                                <div className="radio-style"></div>
                                                <div className="radio-text h6">table</div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="type-control__content">
                                    <img className="type-control__img" src={BracketType4} alt="" />
                                    <div className="type-control__text">
                                        In most multi-table tournaments, blinds will regularly increase and antes will
                                        eventually be added.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button
                            type="button"
                            className="btn"
                            disabled={bracketType === null}
                            onClick={handleNextBtnClick}>
                            next
                        </button>
                        <button className="btn" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
