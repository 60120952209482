import React, { useState } from 'react';

import { BracketTable } from '~app/components/tournament-bracket-table/bracket-table';
import { BracketTableFilter } from '~app/components/tournament-bracket-table/bracket-table-filter';
import { Bracket } from '~app/types/bracket.type';
import { Tournament } from '~app/types/tournament.type';

interface ITournamentBracketTableProps {
    bracket: Bracket;
    tournament: Tournament;
    canEdit: boolean;
    onClickInsertParticipants: () => void;
}

export const TournamentTableBracket = ({
    bracket,
    tournament,
    onClickInsertParticipants,
}: ITournamentBracketTableProps): JSX.Element => {
    const [search, setSearch] = useState<string>('');

    return (
        <div className="container--sm">
            <BracketTableFilter
                tournament={tournament}
                search={search}
                onChangeSearch={setSearch}
                onClickInsertParticipants={onClickInsertParticipants}
            />
            <div className="filter-content filter-content--sm">
                <div className="table table--center">
                    <div className="table-content">
                        <div className="table-inner">
                            <BracketTable tournament={tournament} bracket={bracket} search={search} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
