import { Participant } from '~app/types/participant.type';
import { IRule } from '~app/types/rule.interface';
import { IScheduleUpload } from '~app/types/schedule.interface';
import { Tournament, TournamentStatus } from '~app/types/tournament.type';
import { requestSecure } from '~app/utils/request';

export const createTournament = async (data: Partial<Tournament>): Promise<Tournament> => {
    return requestSecure<Tournament>('/tournaments', { method: 'POST', data });
};

export const updateTournament = async (
    slug: string,
    data: Partial<Tournament>,
): Promise<{ success: boolean; slug: string }> => {
    return requestSecure<{ success: boolean; slug: string }>(`/tournaments/${slug}`, { method: 'PUT', data });
};

export const deleteTournament = async (slug: string): Promise<{ success: boolean; slug: string }> => {
    return requestSecure<{ success: boolean; slug: string }>(`/tournaments/${slug}`, { method: 'DELETE' });
};

export const updateTournamentParticipant = async (slug: string, data: Participant): Promise<void> => {
    return requestSecure<void>(`/tournaments/${slug}/participants`, { method: 'POST', data });
};

export const deleteTournamentParticipant = async (slug: string, participantId: number): Promise<void> => {
    return requestSecure<void>(`/tournaments/${slug}/participants/${participantId}`, { method: 'DELETE' });
};

export const updateTournamentRules = async (slug: string, data: Partial<IRule>[]): Promise<void> => {
    return requestSecure<void>(`/tournaments/${slug}/rules`, { method: 'POST', data });
};

export const updateTournamentPrizes = async (slug: string, data: any): Promise<void> => {
    return requestSecure<void>(`/tournaments/${slug}/prizes`, { method: 'POST', data });
};

export const updateTournamentMedia = async (slug: string, data: any): Promise<void> => {
    return requestSecure<void>(`/tournaments/${slug}/media`, { method: 'POST', data });
};

export const updateTournamentSchedule = async (slug: string, data: IScheduleUpload): Promise<void> => {
    return requestSecure<void>(`/tournaments/${slug}/schedule`, { method: 'POST', data });
};

export const setStatus = async (slug: string, status: TournamentStatus): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`/tournaments/${slug}/status`, { method: 'POST', data: { status } });
};
