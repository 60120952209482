export const timeZones = [
    { value: -12, label: 'GMT -12:00' },
    { value: -11, label: 'GMT -11:00' },
    { value: -10, label: 'GMT -10:00' },
    { value: -9, label: 'GMT -09:00' },
    { value: -8, label: 'GMT -08:00' },
    { value: -7, label: 'GMT -07:00' },
    { value: -6, label: 'GMT -06:00' },
    { value: -5, label: 'GMT -05:00' },
    { value: -4, label: 'GMT -04:00' },
    { value: -3, label: 'GMT -03:00' },
    { value: -2, label: 'GMT -02:00' },
    { value: -1, label: 'GMT -01:00' },
    { value: -0, label: 'GMT 00:00' },
    { value: 1, label: 'GMT +01:00' },
    { value: 2, label: 'GMT +02:00' },
    { value: 3, label: 'GMT +03:00' },
    { value: 4, label: 'GMT +04:00' },
    { value: 5, label: 'GMT +05:00' },
    { value: 6, label: 'GMT +06:00' },
    { value: 7, label: 'GMT +07:00' },
    { value: 8, label: 'GMT +08:00' },
    { value: 9, label: 'GMT +09:00' },
    { value: 10, label: 'GMT +10:00' },
    { value: 11, label: 'GMT +11:00' },
    { value: 12, label: 'GMT +12:00' },
    { value: 13, label: 'GMT +13:00' },
    { value: 14, label: 'GMT +14:00' },
];

export const BEST_OF_OPTIONS = [
    { label: 'BO1', value: 1 },
    { label: 'BO2', value: 2 },
    { label: 'BO3', value: 3 },
    { label: 'BO4', value: 4 },
    { label: 'BO5', value: 5 },
    { label: 'BO6', value: 6 },
    { label: 'BO7', value: 7 },
];

export const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';
