import React, { useCallback, useMemo, useRef } from 'react';

import { Empty } from '~app/components/empty';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { ITournamentMediaModalRef, TournamentMedialModal } from '~app/modals/tournament-media.modal';
import { getTournamentMediaData, TournamentMediaType } from '~app/types/tournament-media.interface';

export const TournamentMedia = (): JSX.Element | null => {
    const { settingsDict } = useRootContext();
    const { tournament } = useTournamentsContext();
    const tournamentMediaModalRef = useRef<ITournamentMediaModalRef>(null);

    const media = useMemo(() => {
        if (!tournament?.media?.length) {
            return [];
        }

        return tournament.media.map(getTournamentMediaData);
    }, [tournament]);

    const onClickAddMediaHandler = useCallback(() => {
        if (tournament !== null) {
            tournamentMediaModalRef.current?.open(tournament);
        }
    }, [tournament]);

    return (
        <section className="section section--main section--sm">
            <div className="section-bg">
                <div className="section-bg__img" style={{ backgroundImage: `url(bg5)` }}></div>
            </div>
            <div className="section-inner">
                <div className="container--sm">
                    <div className="filter filter--admin">
                        <div className="filter-main">
                            <div className="filter-wrap">
                                <div className="filter-inner">
                                    <div className="filter-btn">
                                        <button className="btn btn--sm" onClick={onClickAddMediaHandler}>
                                            add media
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!!settingsDict && !!media.length && (
                        <div className="filter-content">
                            {media.map((m, index) => {
                                return (
                                    <div className="iframe" key={index}>
                                        {m.type === TournamentMediaType.YOUTUBE && (
                                            <iframe
                                                src={`https://www.youtube-nocookie.com/embed/${m.videoId}`}
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                        )}
                                        {m.type === TournamentMediaType.TWITCH && (
                                            <iframe
                                                src={`https://player.twitch.tv/?autoplay=false&video=${m.videoId}&parent=${settingsDict.twitch_domain}`}
                                                title="Twitch video player"
                                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                width="100%"
                                                height="100%"
                                                allowFullScreen></iframe>
                                        )}

                                        {m.type === TournamentMediaType.TWITCH_STREAM && (
                                            <iframe
                                                src={`https://player.twitch.tv/?autoplay=false&channel=${m.videoId}&parent=${window.location.host.split(':')[0]}`}
                                                title="Twitch video player"
                                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                width="100%"
                                                height="100%"
                                                allowFullScreen></iframe>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {(!settingsDict || !media.length) && (
                        <div className="filter-content">
                            <Empty />
                        </div>
                    )}

                    <div className="filter filter--admin">
                        <div className="filter-main">
                            <div className="filter-wrap">
                                <div className="filter-inner">
                                    <div className="filter-btn">
                                        <button className="btn btn--sm" onClick={onClickAddMediaHandler}>
                                            add media
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TournamentMedialModal ref={tournamentMediaModalRef} />
        </section>
    );
};
