import { TournamentBracketGroup } from 'app/components/tournament-bracket-group';
import { TournamentBracketOldGroup } from 'app/components/tournament-bracket-old-group';
import { TournamentBracketRelegation } from 'app/components/tournament-bracket-relegation';
import { TournamentBracketSwiss } from 'app/components/tournament-bracket-swiss';
import dragscroll from 'dragscroll';
import React, { useCallback, useRef } from 'react';
import SimpleBar from 'simplebar-react';

import bg5 from '~app/assets/img/bg5.jpg';
import { Dropdown } from '~app/components/common/dropdown/dropdown';
import { Empty } from '~app/components/empty';
import { TournamentBracketGroupStandings } from '~app/components/tournament-bracket-group/tournament-bracket-group-standings';
import { TournamentBracketMenu } from '~app/components/tournament-bracket-menu';
import { TournamentBracketStandings } from '~app/components/tournament-bracket-standings';
import { TournamentTableBracket } from '~app/components/tournament-bracket-table';
import { useTournamentsContext } from '~app/contexts';
import { ConfirmationModal, IConfirmationModalRef } from '~app/modals/confirmation.modal';
import { ITournamentBracketModalRef, TournamentBracketModal } from '~app/modals/tournament-bracket.modal';
import {
    ITournamentBracketGroupModalRef,
    TournamentBracketGroupModal,
} from '~app/modals/tournament-bracket-group.modal';
import {
    ITournamentBracketGroupParticipantsModalRef,
    TournamentBracketGroupParticipants,
} from '~app/modals/tournament-bracket-group-participants.modal';
import {
    ITournamentBracketRelegationModalRef,
    TournamentBracketRelegationModal,
} from '~app/modals/tournament-bracket-relegation.modal';
import {
    ITournamentBracketRelegationParticipantsModalRef,
    TournamentBracketRelegationParticipants,
} from '~app/modals/tournament-bracket-relegation-participants.modal';
import {
    ITournamentBracketSwissModalRef,
    TournamentBracketSwissModal,
} from '~app/modals/tournament-bracket-swiss.modal';
import {
    ITournamentBracketSwissParticipantsModalRef,
    TournamentBracketSwissParticipantsModal,
} from '~app/modals/tournament-bracket-swiss-participants.modal';
import {
    ITournamentBracketTableModalRef,
    TournamentBracketTableModal,
} from '~app/modals/tournament-bracket-table.modal';
import {
    ITournamentBracketTableParticipantsModalRef,
    TournamentBracketTableParticipants,
} from '~app/modals/tournament-bracket-table-participants.modal';
import { Bracket, BracketType } from '~app/types/bracket.type';
import API from '~app/utils/apis';

interface ITournamentBracketsProps {
    canEdit: boolean;
}

export const TournamentBrackets = ({ canEdit }: ITournamentBracketsProps): JSX.Element | null => {
    const { tournament, currentBracket, setCurrentBracket, currentRoundId, setCurrentRoundId, refreshTournament } =
        useTournamentsContext();

    const bracketModalRef = useRef<ITournamentBracketModalRef>(null);
    const confirmationModalRef = useRef<IConfirmationModalRef>(null);

    const bracketGroupModalRef = useRef<ITournamentBracketGroupModalRef>(null);
    const bracketRelegationModalRef = useRef<ITournamentBracketRelegationModalRef>(null);
    const bracketSwissModalRef = useRef<ITournamentBracketSwissModalRef>(null);
    const bracketTableModalRef = useRef<ITournamentBracketTableModalRef>(null);

    const bracketGroupParticipantsModalRef = useRef<ITournamentBracketGroupParticipantsModalRef>(null);
    const bracketRelegationParticipantsRef = useRef<ITournamentBracketRelegationParticipantsModalRef>(null);
    const bracketSwissParticipantsModalRef = useRef<ITournamentBracketSwissParticipantsModalRef>(null);
    const bracketTableParticipantsModalRef = useRef<ITournamentBracketTableParticipantsModalRef>(null);

    const editBracketAction = useCallback(
        (bracket: Bracket) => () => {
            switch (bracket.type) {
                case BracketType.GROUP: {
                    bracketGroupModalRef.current?.open(bracket);

                    return;
                }
                case BracketType.RELEGATION: {
                    bracketRelegationModalRef.current?.open(bracket);

                    return;
                }
                case BracketType.SWISS: {
                    bracketSwissModalRef.current?.open(bracket);

                    return;
                }
                case BracketType.TABLE: {
                    bracketTableModalRef.current?.open(bracket);

                    return;
                }
            }
        },
        [],
    );

    const insertParticipantsAction = useCallback(
        (bracket: Bracket) => () => {
            if (tournament === null) {
                return;
            }

            switch (bracket.type) {
                case BracketType.RELEGATION: {
                    bracketRelegationParticipantsRef.current?.open(bracket);

                    return;
                }
                case BracketType.GROUP: {
                    bracketGroupParticipantsModalRef.current?.open(bracket);

                    return;
                }
                case BracketType.SWISS: {
                    bracketSwissParticipantsModalRef.current?.open(bracket);

                    return;
                }
                case BracketType.TABLE: {
                    bracketTableParticipantsModalRef.current?.open(bracket);

                    return;
                }
            }
        },
        [tournament],
    );

    const deleteBracketAction = useCallback(
        (bracket: Bracket) => () => {
            const title = (
                <>
                    Delete bracket: <span>{bracket.title}</span>
                </>
            );
            const description = (
                <>
                    Please confirm the deletion of the <span>{bracket.title}</span> bracket
                </>
            );
            const deleteBracket = async () => {
                await API.Brackets.remove(bracket.id);
                await refreshTournament();
            };

            confirmationModalRef.current?.open(title, description, deleteBracket);
        },
        [refreshTournament],
    );

    const tournamentBracketModalOnNextHandler = useCallback(
        (bracketType: BracketType) => {
            editBracketAction({ type: bracketType } as Bracket)();
        },
        [editBracketAction],
    );

    if (tournament === null) {
        return null;
    }

    return (
        <>
            <div className="container--sm">
                <div className="nav-container brackets">
                    <SimpleBar
                        className="brackets-inner"
                        style={{ maxWidth: 'calc(100% - 140px)', flex: 1 }}
                        draggable
                        classNames={{ contentWrapper: 'dragscroll' }}
                        ref={() => {
                            dragscroll.reset();
                        }}>
                        <ul className="nav-list" style={{ alignItems: canEdit ? 'flex-end' : 'center' }}>
                            {tournament.brackets?.map((bracket) => {
                                if (canEdit) {
                                    return (
                                        <li className="nav-item" key={bracket.id}>
                                            <Dropdown
                                                title={bracket.title}
                                                onClick={() => {
                                                    if (bracket.id !== currentBracket?.id) {
                                                        setCurrentRoundId(null);
                                                        setCurrentBracket(bracket);
                                                    }
                                                }}
                                                size="xs"
                                                isActive={bracket.id === currentBracket?.id}
                                                items={[
                                                    {
                                                        label: 'Edit bracket',
                                                        action: editBracketAction(bracket),
                                                    },
                                                    {
                                                        label: 'Delete bracket',
                                                        action: deleteBracketAction(bracket),
                                                    },
                                                    {
                                                        label: 'Insert participants',
                                                        action: insertParticipantsAction(bracket),
                                                    },
                                                    // {
                                                    //     label: 'Clone bracket',
                                                    //     action: () => console.log('Clone bracket'),
                                                    //     isDisabled: true
                                                    // }
                                                ]}
                                            />
                                        </li>
                                    );
                                }

                                return (
                                    <li className="nav-item" key={bracket.id}>
                                        <a
                                            className={`nav-link ${bracket.id === currentBracket?.id ? 'active' : ''}`}
                                            onClick={() => {
                                                setCurrentRoundId(null);
                                                setCurrentBracket(bracket);
                                            }}>
                                            {bracket.title}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </SimpleBar>
                    {canEdit && (
                        <div className="nav-btns">
                            <button
                                className="btn btn--sm"
                                onClick={() => bracketModalRef.current?.open()}
                                style={{ width: '120px' }}>
                                add bracket
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <TournamentBracketMenu />
            {!tournament.brackets?.length && (
                <section className="section section--main section--sm">
                    <div className="section-bg">
                        <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }} />
                    </div>
                    <div className="section-inner">
                        <Empty />
                    </div>
                </section>
            )}
            {!!currentBracket && currentRoundId !== 'standings' && (
                <section className="section section--main section--sm">
                    <div className="section-bg">
                        <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }} />
                    </div>
                    <div className="section-inner">
                        {currentBracket.type === BracketType.TABLE && (
                            <TournamentTableBracket
                                tournament={tournament}
                                bracket={currentBracket}
                                canEdit={canEdit}
                                onClickInsertParticipants={insertParticipantsAction(currentBracket)}
                            />
                        )}
                        {currentBracket.type === BracketType.RELEGATION && (
                            <TournamentBracketRelegation
                                canEdit={canEdit}
                                onClickInsertParticipants={insertParticipantsAction(currentBracket)}
                            />
                        )}
                        {currentBracket.type === BracketType.OLD_GROUP && <TournamentBracketOldGroup />}
                        {currentBracket.type === BracketType.GROUP && (
                            <TournamentBracketGroup
                                canEdit={canEdit}
                                onClickInsertParticipants={insertParticipantsAction(currentBracket)}
                            />
                        )}
                        {currentBracket.type === BracketType.SWISS && (
                            <TournamentBracketSwiss
                                canEdit={canEdit}
                                onClickInsertParticipants={insertParticipantsAction(currentBracket)}
                            />
                        )}
                    </div>
                </section>
            )}
            {currentRoundId === 'standings' && currentBracket?.type !== BracketType.GROUP && (
                <TournamentBracketStandings />
            )}
            {currentRoundId === 'standings' && currentBracket?.type === BracketType.GROUP && (
                <TournamentBracketGroupStandings />
            )}
            <TournamentBracketModal ref={bracketModalRef} onNext={tournamentBracketModalOnNextHandler} />
            <ConfirmationModal ref={confirmationModalRef} />
            <TournamentBracketGroupModal ref={bracketGroupModalRef} />
            <TournamentBracketRelegationModal ref={bracketRelegationModalRef} />
            <TournamentBracketSwissModal ref={bracketSwissModalRef} />
            <TournamentBracketTableModal ref={bracketTableModalRef} />
            <TournamentBracketGroupParticipants ref={bracketGroupParticipantsModalRef} />
            <TournamentBracketRelegationParticipants ref={bracketRelegationParticipantsRef} />
            <TournamentBracketSwissParticipantsModal ref={bracketSwissParticipantsModalRef} />
            <TournamentBracketTableParticipants ref={bracketTableParticipantsModalRef} />
        </>
    );
};
