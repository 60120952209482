/* eslint-disable no-template-curly-in-string */

export default {
    translations: {
        common: {
            back: 'Назад',
            tournaments: 'турниры',
            champs: 'чемпионы',
            media: 'СМИ',
            contacts: 'контакты',
            donate: 'пожертвовать',
            thanks: 'Благодарность',
        },
        alerts: {
            success: {},
            error: {},
            warnings: {},
        },
        errors: {
            anyErr: 'Произошла ошибка. {{logCode}}',
            something: 'Сервер недоступен, попробуйте позже. {{logCode}}',
        },
    },
};
