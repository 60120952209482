import moment from 'moment';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { DatePicker } from '~app/components/datepicker';
import { useTournamentsContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { ISchedule } from '~app/types/schedule.interface';
import { ScheduleType, Tournament } from '~app/types/tournament.type';
import { arrayMoveItem } from '~app/utils';
import API from '~app/utils/apis';

const emptySchedule: ISchedule = {
    title: '',
    date: '',
    time: '',
} as ISchedule;

enum ScheduleTimeType {
    SINGLE,
    MULTIPLE,
}

export interface ITournamentScheduleModalRef {
    open: (tournament: Tournament) => void;
}

export const TournamentScheduleModal = forwardRef(
    (props: any, ref: React.Ref<ITournamentScheduleModalRef>): JSX.Element | null => {
        const { refreshTournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [isSaving, setSaving] = useState<boolean>(false);
        const [scheduleTimeType, setScheduleTimeType] = useState<ScheduleTimeType>(ScheduleTimeType.SINGLE);
        const [tournamentSlug, setTournamentSlug] = useState<string | null>(null);
        const [schedules, setSchedules] = useState<ISchedule[]>([]);
        const [scheduleType, setScheduleType] = useState<number | null>(null);
        const [showOnMainPage, setShowOnMainPage] = useState<boolean>(false);

        useImperativeHandle(ref, () => ({
            open(tournament: Tournament) {
                setTournamentSlug(tournament.slug);
                setSchedules(
                    tournament.schedules && tournament.schedules.length > 0
                        ? [...tournament.schedules]
                        : [{ ...emptySchedule }],
                );
                setScheduleType(tournament.schedule_type);
                setShowOnMainPage(tournament.show_on_main_page === 1);
                setScheduleTimeType(
                    (tournament.schedules ?? []).length > 1 ? ScheduleTimeType.MULTIPLE : ScheduleTimeType.SINGLE,
                );
                setOpen(true);
            },
        }));

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
        }, []);

        const onClickAddHandler = useCallback(() => {
            setSchedules((prev) => {
                return [...prev, { ...emptySchedule }];
            });
        }, []);

        const updateRow = useCallback(
            (index: number, field: string, newValue?: any) => (event?: React.ChangeEvent<HTMLInputElement>) => {
                setSchedules((prev) => {
                    const result = [...prev];
                    const schedule = prev[index] ? { ...prev[index] } : null;

                    if (schedule) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        schedule[field] = newValue ?? event?.target.value;
                        result[index] = schedule;
                    }

                    return result;
                });
            },
            [],
        );

        const changePosition = useCallback(
            (oldIndex: number, newIndex: number) => () => {
                if (newIndex < 0 || newIndex > schedules.length) {
                    return;
                }

                setSchedules((prev) => {
                    return arrayMoveItem([...prev], oldIndex, newIndex);
                });
            },
            [schedules],
        );

        const deleteRow = useCallback(
            (index: number) => () => {
                setSchedules((prev) => {
                    const result = [...prev];
                    result.splice(index, 1);

                    return result;
                });
            },
            [],
        );

        const onChangeShowOnMainPageHandler = useCallback(() => {
            setShowOnMainPage((prev) => !prev);
        }, []);

        const onClickSaveHandler = useCallback(async () => {
            if (!tournamentSlug) {
                return;
            }

            setSaving(true);

            try {
                const data = {
                    scheduleType: scheduleType ?? 0,
                    showOnMainPage: showOnMainPage ? 1 : 0,
                    schedules: scheduleTimeType === ScheduleTimeType.SINGLE ? schedules.slice(0, 1) : schedules,
                };

                await API.Tournaments.updateTournamentSchedule(tournamentSlug, data);
                await refreshTournament();
                setOpen(false);
            } catch (err) {
                console.error(err);
            } finally {
                setSaving(false);
            }
        }, [tournamentSlug, scheduleType, scheduleTimeType, showOnMainPage, schedules]);

        if (!isOpen || tournamentSlug === null) {
            return null;
        }

        return (
            <ModalContainer onClickClose={onClickCloseHandler} title="Edit schedule">
                <ModalContentSlot>
                    <>
                        <div className="content-block">
                            <div className="radios">
                                <div className="radio radio--sm">
                                    <label className="radio-label" htmlFor="typeAll">
                                        <input
                                            className="radio-input"
                                            type="radio"
                                            id="typeAll"
                                            checked={scheduleTimeType === ScheduleTimeType.SINGLE}
                                            onChange={() => setScheduleTimeType(ScheduleTimeType.SINGLE)}
                                        />
                                        <div className="radio-content">
                                            <div className="radio-style"></div>
                                            <div className="radio-text h6">ALL MATCHES AT SAME TIMES</div>
                                        </div>
                                    </label>
                                </div>
                                <div className="radio radio-sm">
                                    <label className="radio-label" htmlFor="typeDivided">
                                        <input
                                            className="radio-input"
                                            type="radio"
                                            id="typeDivided"
                                            checked={scheduleTimeType === ScheduleTimeType.MULTIPLE}
                                            onChange={() => setScheduleTimeType(ScheduleTimeType.MULTIPLE)}
                                        />
                                        <div className="radio-content">
                                            <div className="radio-style"></div>
                                            <div className="radio-text h6">
                                                THE TOURNAMENT IS SPLIT INTO ROUNDS AT DIFFERENT TIMES
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="content-block">
                            <div className="add append">
                                <div className="add-table">
                                    <div className="table table--static table--controls">
                                        <div className="table-content">
                                            <div className="table-inner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="add-table__lg">
                                                                title <span>*</span>
                                                            </th>
                                                            <th>
                                                                date <span>*</span>
                                                            </th>
                                                            <th>
                                                                time <span>*</span>
                                                            </th>
                                                            <th className="add-table__controls add-table__controls--top"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sort-rows append-wrap">
                                                        {schedules.map((schedule, index) => {
                                                            if (
                                                                scheduleTimeType === ScheduleTimeType.SINGLE &&
                                                                index > 0
                                                            ) {
                                                                return null;
                                                            }

                                                            return (
                                                                <tr
                                                                    key={`schedule-${index}}`}
                                                                    className="sort-row append-item">
                                                                    <td className="add-table__lg">
                                                                        <div className="add-cell">
                                                                            <input
                                                                                type="text"
                                                                                className="field field--md"
                                                                                style={{
                                                                                    paddingRight: '38px',
                                                                                }}
                                                                                placeholder="add title"
                                                                                value={schedule.title}
                                                                                onChange={updateRow(index, 'title')}
                                                                            />
                                                                            <div className="field-error"></div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="add-cell ">
                                                                            <DatePicker
                                                                                className="datepicker-el--md"
                                                                                date={
                                                                                    schedule.date
                                                                                        ? new Date(schedule.date)
                                                                                        : null
                                                                                }
                                                                                onChange={(date) => {
                                                                                    updateRow(
                                                                                        index,
                                                                                        'date',
                                                                                        moment(date).format(
                                                                                            'YYYY-MM-DD',
                                                                                        ),
                                                                                    )();
                                                                                }}
                                                                                dateFormat="DD/MM/YYYY"
                                                                            />
                                                                            <div className="field-error"></div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="add-cell ">
                                                                            <DatePicker
                                                                                className="datepicker-el--md"
                                                                                date={
                                                                                    schedule.time
                                                                                        ? new Date(
                                                                                              `${moment().format('YYYY-MM-DD')}T${schedule.time}`,
                                                                                          )
                                                                                        : null
                                                                                }
                                                                                onChange={(date) => {
                                                                                    updateRow(
                                                                                        index,
                                                                                        'time',
                                                                                        moment(date).format('hh:mm:ss'),
                                                                                    )();
                                                                                }}
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                timeIntervals={60}
                                                                                timeCaption="Time"
                                                                                dateFormat="hh:mm a"
                                                                            />
                                                                            <div className="field-error"></div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="add-table__controls add-table__controls--top">
                                                                        <div className="table-controls">
                                                                            <div className="sort">
                                                                                {index > 0 && (
                                                                                    <div
                                                                                        className="sort-arrow up"
                                                                                        onClick={changePosition(
                                                                                            index,
                                                                                            index - 1,
                                                                                        )}>
                                                                                        <svg className="icon">
                                                                                            <use
                                                                                                href={`${MainSymbols}#image-chevron`}></use>
                                                                                        </svg>
                                                                                    </div>
                                                                                )}
                                                                                {index < schedules.length - 1 && (
                                                                                    <div
                                                                                        className="sort-arrow down"
                                                                                        onClick={changePosition(
                                                                                            index,
                                                                                            index + 1,
                                                                                        )}>
                                                                                        <svg className="icon">
                                                                                            <use
                                                                                                href={`${MainSymbols}#image-chevron`}></use>
                                                                                        </svg>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div className="clear">
                                                                                <a
                                                                                    className="clear-btn"
                                                                                    onClick={deleteRow(index)}>
                                                                                    <svg className="icon">
                                                                                        <use
                                                                                            href={`${MainSymbols}#image-clear`}></use>
                                                                                    </svg>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {scheduleTimeType === ScheduleTimeType.MULTIPLE && (
                                    <div className="add-controls">
                                        <div className="add-controls__btn">
                                            <button className="btn btn--md" onClick={onClickAddHandler}>
                                                add one more
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="content-block">
                            <div className="toggler" data-checkbox="">
                                <div className="toggler-checkbox js-on-home">
                                    <div className="checkbox checkbox--toggler">
                                        <label className="checkbox-label" htmlFor="scheduleType">
                                            <input
                                                className="checkbox-input"
                                                name="scheduleType"
                                                type="checkbox"
                                                id="scheduleType"
                                                checked={showOnMainPage}
                                                onChange={onChangeShowOnMainPageHandler}
                                            />
                                            <div className="checkbox-content">
                                                <div className="checkbox-style"></div>
                                                <div className="checkbox-text h6">Place on main page</div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="toggler-content js-on-home-block">
                                    <div className="radios">
                                        <div className={`radio radio--sm ${!showOnMainPage ? 'disabled' : ''}`}>
                                            <label className="radio-label" htmlFor="scheduleTournaments1">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    name="ScheduleForm[mark_primary]"
                                                    id="scheduleTournaments1"
                                                    checked={scheduleType === ScheduleType.PRIMARY}
                                                    onChange={() => setScheduleType(ScheduleType.PRIMARY)}
                                                    disabled={!showOnMainPage}
                                                />
                                                <div className="radio-content">
                                                    <div className="radio-style"></div>
                                                    <div className="radio-text h6">Primary tournaments</div>
                                                </div>
                                            </label>
                                        </div>
                                        <div className={`radio radio--sm ${!showOnMainPage ? 'disabled' : ''}`}>
                                            <label className="radio-label" htmlFor="scheduleTournaments2">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    name="ScheduleForm[mark_primary]"
                                                    id="scheduleTournaments2"
                                                    checked={scheduleType === ScheduleType.NON_PRIMARY}
                                                    onChange={() => setScheduleType(ScheduleType.NON_PRIMARY)}
                                                    disabled={!showOnMainPage}
                                                />
                                                <div className="radio-content">
                                                    <div className="radio-style"></div>
                                                    <div className="radio-text h6">non-Primary tournaments</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button type="button" className="btn" disabled={isSaving} onClick={onClickSaveHandler}>
                            save
                        </button>
                        <button className="btn" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
