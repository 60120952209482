import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TeamName } from '~app/components/common/team-name';
import { EditableField } from '~app/components/editable-field/editable-field';
import { Image } from '~app/components/image';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { Bracket } from '~app/types/bracket.type';
import { Tournament } from '~app/types/tournament.type';
import { UserRole } from '~app/types/user.interface';
import { makeS3ResourceLink, primers, sort_by } from '~app/utils';
import API from '~app/utils/apis';

interface IBracketTableProps {
    bracket: Bracket;
    tournament: Tournament;
    search: string;
}

export const BracketTable = (props: IBracketTableProps): JSX.Element => {
    const { bracket, tournament, search } = props;

    const { getAvatar, profile } = useRootContext();
    const { refreshTournament } = useTournamentsContext();

    const [tableSortState, setTableSortState] = useState<{ field: string; order: string }>({
        field: tournament.type.isTeamMode ? 'team' : 'nick',
        order: 'asc',
    });
    const [isSetWightSort, setWightSort] = useState<boolean>(false);

    const canEdit = useMemo(() => {
        return profile?.role === UserRole.ROOT || profile?.role === UserRole.ADMIN;
    }, [profile]);

    const headers = useMemo(() => {
        if (!bracket || !tournament) {
            return [];
        }

        const playerHeaders = [
            ['player', 'player_nick', 'true'],
            ['class', 'class_name', 'true'],
            ['faction', 'faction', 'true'],
            ['game world', 'world', 'true'],
        ];
        const teamHeaders = [['team', 'team_name', 'true']];

        return [
            ...(tournament.type.isTeamMode ? teamHeaders : playerHeaders),
            ...(bracket.table_columns ?? [])
                .sort((a, b) => a.ordinal - b.ordinal)
                .map((_) => [_.title, _.title, false, _.id]),
        ];
    }, [tournament, bracket]);

    const rows = useMemo(() => {
        if (!bracket) {
            return [];
        }

        return bracket.rows
            .filter((_) => !!_.participant_id)
            .map((row) => {
                const newRow: { [key: string]: any } = { ...row };
                delete newRow.columns;
                row.columns.forEach((c) => {
                    newRow[c.column_id] = c.value;
                });

                const participant = tournament.participants[row.participant_id];

                return {
                    ...newRow,
                    player: participant.player,
                    player_nick: participant.player?.nick || '',
                    class_name: participant.player?.class?.name || '',
                    faction: participant.player?.faction?.name || '',
                    faction_avatar: participant.player?.faction?.avatar || '',
                    world: participant.player?.world?.name || '',
                    team_name: participant.team?.name || '',
                    wight: row.wight || null,
                    team: tournament.participants[row.participant_id]?.team,
                };
            })
            .sort(
                sort_by(
                    tableSortState.field,
                    tableSortState.order === 'asc',
                    primers[tableSortState.field.toLowerCase()],
                ),
            )
            .filter((row: any) => {
                if (!search || search === '') {
                    return true;
                }

                if (tournament.type.isTeamMode) {
                    return row.team_name.toLowerCase().includes(search.toLowerCase());
                }

                return row.player_nick.toLowerCase().includes(search.toLowerCase());
            });
    }, [tournament, bracket, search, tableSortState]);

    const handleSortBtnClick = useCallback((field: string) => {
        setTableSortState((prev) => {
            if (prev.field === field) {
                return {
                    field,
                    order: prev.order === 'asc' ? 'desc' : 'asc',
                };
            }

            return {
                field,
                order: 'asc',
            };
        });
    }, []);

    const onChangeCell = useCallback(
        (rowId: number, header: string) => async (value?: string) => {
            const column = headers.find((_) => _[3]?.toString() === header);
            const columnId = column ? parseInt(column[3]?.toString() || '') : undefined;

            if (header === 'wight') {
                await API.Brackets.updateTableWight(bracket.id, rowId, value ? +value : 0);
                await refreshTournament();

                return;
            }

            if (columnId) {
                await API.Brackets.updateTableSoloCell(bracket.id, rowId, columnId, value ?? '');
                refreshTournament().then();
            }
        },
        [headers, bracket],
    );

    useEffect(() => {
        if (!isSetWightSort && bracket.rows.some((_) => !!_.wight)) {
            setWightSort(true);
            setTableSortState({ field: 'wight', order: 'desc' });
        } else if (!isSetWightSort) {
            setWightSort(false);
        }
    }, [bracket, isSetWightSort]);

    return (
        <table>
            <thead>
                <tr>
                    <th>
                        <div
                            className={`table-sort ${
                                tableSortState.field === 'wight'
                                    ? tableSortState.order !== 'asc'
                                        ? 'up'
                                        : 'bottom'
                                    : ''
                            }`}>
                            <div className="table-sort__text">#</div>
                            <div className="table-sort__btn" onClick={() => handleSortBtnClick('wight')}></div>
                        </div>
                    </th>
                    {headers
                        .filter((_) => _[1]?.toString().toLowerCase() !== 'wight')
                        .map((header, index) => {
                            if (!header[0] || !header[1]) {
                                return null;
                            }

                            return (
                                <th
                                    className="table-col--left"
                                    style={{
                                        textAlign: index > 0 ? 'center' : 'left',
                                    }}
                                    key={header[0].toString()}>
                                    <div
                                        className={`table-sort ${
                                            tableSortState.field === (header[1] || header[0])
                                                ? tableSortState.order !== 'asc'
                                                    ? 'up'
                                                    : 'bottom'
                                                : ''
                                        }`}>
                                        <div className="table-sort__text">{header[0]}</div>
                                        <div
                                            className="table-sort__btn"
                                            onClick={() =>
                                                handleSortBtnClick((header[1] || header[0] || '').toString())
                                            }></div>
                                    </div>
                                </th>
                            );
                        })}
                </tr>
            </thead>
            <tbody id="table-body">
                {rows.map((row: any) => {
                    const avatar = getAvatar(row.player);

                    return (
                        <tr key={row.id}>
                            <td
                                width={60}
                                style={{
                                    position: 'relative',
                                }}>
                                {canEdit && (
                                    <EditableField
                                        name="wight"
                                        value={row.wight !== null ? row.wight : ''}
                                        onSave={onChangeCell(row.id, 'wight')}
                                    />
                                )}
                                {!canEdit && <>{row.wight > 0 ? row.wight : ''}</>}
                            </td>
                            {!tournament.type.isTeamMode && (
                                <>
                                    <td className="table-col--left">
                                        <div className="table-player">
                                            <div className="table-player__avatar">
                                                <Image
                                                    img={avatar.img}
                                                    webp={avatar.webp}
                                                    defaultImg={avatar.defaultImg}
                                                />
                                            </div>
                                            <div
                                                className="table-player__name"
                                                style={{
                                                    color: row.player.class.avatar,
                                                }}>
                                                {row.player.external_link && (
                                                    <a
                                                        href={row.player.external_link}
                                                        className="no-decor"
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        {row.player_nick}
                                                    </a>
                                                )}
                                                {!row.player.external_link && row.player_nick}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{row.class_name}</td>
                                    <td>
                                        <img
                                            className="table-logo"
                                            alt=""
                                            src={makeS3ResourceLink(row.faction_avatar)}
                                        />
                                        {row.faction}
                                    </td>
                                    <td>{row.world}</td>
                                </>
                            )}
                            {tournament.type.isTeamMode && (
                                <td className="table-col--left">
                                    <TeamName team={row.team} />
                                </td>
                            )}

                            {headers
                                .filter((_) => _[1]?.toString().toLowerCase() !== 'wight')
                                .filter((_) => !_[2])
                                .map((header) => {
                                    if (!header[0] || !header[3]) {
                                        return null;
                                    }

                                    return (
                                        <td
                                            key={header[0].toString()}
                                            className="table-col--left"
                                            style={{
                                                textAlign: 'center',
                                                position: 'relative',
                                            }}>
                                            <>
                                                {canEdit && (
                                                    <EditableField
                                                        name={header[0].toString()}
                                                        value={row[header[3].toString()] ?? ''}
                                                        onSave={onChangeCell(row.id, header[3].toString())}
                                                    />
                                                )}
                                                {!canEdit && <>{row[header[3].toString()] ?? ''}</>}
                                            </>
                                        </td>
                                    );
                                })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
