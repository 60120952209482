import moment from 'moment';
import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { ConfirmationModal, IConfirmationModalRef } from '~app/modals/confirmation.modal';
import { ITournamentMainInfoModalRef, TournamentMainInfoModal } from '~app/modals/tournament-main-info.modal';
import { Tournament, TOURNAMENT_STATUS_DICT } from '~app/types/tournament.type';
import { makeS3ResourceLink } from '~app/utils';
import API from '~app/utils/apis';

interface ITournamentCardProps {
    tournament: Tournament;
    canUpdateTournament?: boolean;
}

export const TournamentCard = ({ tournament, canUpdateTournament }: ITournamentCardProps): JSX.Element => {
    const { showLoader, isAdmin } = useRootContext();
    const { fetchTournaments } = useTournamentsContext();

    const tournamentMainInfoModalRef = useRef<ITournamentMainInfoModalRef>(null);
    const confirmationModalRef = useRef<IConfirmationModalRef>(null);

    const openTournamentMainInfoModal = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();

            if (tournament !== null) {
                tournamentMainInfoModalRef.current?.open(tournament);
            }
        },
        [tournamentMainInfoModalRef, tournament],
    );

    const openTournamentDeleteModal = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            event.stopPropagation();

            if (tournament !== null) {
                const title = (
                    <>
                        Delete tournament: <span>{tournament.title}</span>
                    </>
                );
                const description = (
                    <>
                        Please confirm the deletion of the <span>{tournament.title}</span> bracket
                    </>
                );
                const deleteBracket = async () => {
                    await API.Tournaments.deleteTournament(tournament.slug);
                    await fetchTournaments(true);
                };

                confirmationModalRef.current?.open(title, description, deleteBracket);
            }
        },
        [tournamentMainInfoModalRef, tournament],
    );

    return (
        <>
            <Link
                className={`tourney ${isAdmin && canUpdateTournament ? 'tourney--admin' : ''}`}
                to={`/tournaments/${tournament.slug}`}
                onClick={showLoader}>
                <div className="tourney-inner">
                    <div className="tourney-media">
                        <picture>
                            <source srcSet={makeS3ResourceLink(tournament.bg_image)} />
                            <img className="tourney-img" src={makeS3ResourceLink(tournament.bg_image)} alt="" />
                        </picture>
                    </div>
                    <div className="tourney-top">
                        <div className="tourney-top__main">
                            <div className="tourney-top__inner">
                                <div className="tourney-status">
                                    <div
                                        className={`tag tag--${TOURNAMENT_STATUS_DICT.get(tournament.status)?.toLowerCase().replace('in ', '')}`}>
                                        {TOURNAMENT_STATUS_DICT.get(tournament.status)}
                                    </div>
                                </div>
                                {isAdmin && canUpdateTournament && (
                                    <div className="tourney-controls">
                                        <div className="tourney-controls__group">
                                            <button
                                                className="btn btn--sm"
                                                type="button"
                                                onClick={openTournamentMainInfoModal}>
                                                edit
                                            </button>
                                            <button
                                                className="btn btn--sm"
                                                type="button"
                                                onClick={openTournamentDeleteModal}>
                                                delete
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="tourney-content">
                        <div className="tourney-main">
                            {tournament.pool_custom && (
                                <div className="tourney-prize">
                                    <div className="tag">
                                        prize pool <span>{tournament.pool_custom}</span>
                                    </div>
                                </div>
                            )}
                            <div className="tourney-title h5" dangerouslySetInnerHTML={{ __html: tournament.title }} />
                            <div className="tourney-info">
                                <div className="tourney-type">
                                    <div className="info">
                                        <div className="info-icon">
                                            <svg className="icon">
                                                <use href={`${MainSymbols}#image-shield`}></use>
                                            </svg>
                                        </div>
                                        <div className="icon-content">
                                            <div
                                                className="info-value h6"
                                                dangerouslySetInnerHTML={{
                                                    __html: tournament.type?.name ?? '',
                                                }}
                                            />
                                            <div className="info-prop prop">type</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tourney-date">
                                    <div className="date">
                                        <div className="date-title h6">
                                            {moment(tournament.date).format('D MMMM YYYY')}
                                        </div>
                                        <div className="date-prop prop">date</div>
                                        <div className="date-icon">
                                            <svg className="icon">
                                                <use href={`${MainSymbols}#image-chevron`}></use>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>

            <TournamentMainInfoModal ref={tournamentMainInfoModalRef} />
            <ConfirmationModal ref={confirmationModalRef} />
        </>
    );
};
