import React, { useCallback, useMemo, useState } from 'react';

import { TeamName } from '~app/components/common/team-name';
import { useTournamentsContext } from '~app/contexts';
import { BracketType } from '~app/types/bracket.type';
import { IGroupStanding } from '~app/types/group-standing.interface';
import { IStanding } from '~app/types/standing.interface';
import { Team } from '~app/types/team.type';
import { primers, sort_by } from '~app/utils';

const DEFAULT_SORT = {
    field: 'name',
    order: 'desk',
};
export const TournamentBracketStandings = (): JSX.Element | null => {
    const { currentBracket, tournament } = useTournamentsContext();

    const [groupsStandingsSort, setGroupsStandingsSort] = useState<{
        [key: string]: { field: string; order: string };
    }>({});
    const [standingsSort, setStandingsSort] = useState<{ field: string; order: string }>({
        ...DEFAULT_SORT,
    });

    const { groupStandings, standings } = useMemo(() => {
        if (!currentBracket?.standings || currentBracket.standings.length === 0) {
            return { groupStandings: null, standings: null };
        }

        if (currentBracket.type === BracketType.OLD_GROUP) {
            const _groupStandings: { [key: string]: IGroupStanding } = {};
            (currentBracket.standings as IGroupStanding[]).forEach((item) => {
                _groupStandings[item.order.toString()] = item;
                setGroupsStandingsSort((prev) => {
                    prev[item.order.toString()] = item.standings.some((_) => !!_.points)
                        ? { field: 'points', order: 'desk' }
                        : { ...DEFAULT_SORT };

                    return prev;
                });
            });

            return { groupStandings: _groupStandings, standings: null };
        }

        if (currentBracket.type === BracketType.SWISS) {
            const _standings = currentBracket.standings as IStanding[];
            setStandingsSort(
                _standings.some((_) => !!_.points) ? { field: 'points', order: 'asc' } : { ...DEFAULT_SORT },
            );

            return { groupStandings: null, standings: _standings };
        }

        return { groupStandings: null, standings: null };
    }, [currentBracket]);

    const standingsSorted = useMemo(() => {
        if (!standings) {
            return null;
        }
        const _standingsSorted = [...standings];
        _standingsSorted.sort(
            sort_by(standingsSort.field, standingsSort.order === 'asc', primers[standingsSort.field]),
        );

        return _standingsSorted;
    }, [standings, standingsSort]);

    const groupStandingsSorted = useMemo(() => {
        if (!groupStandings) {
            return null;
        }

        const _groupStandingsSorted: { [key: string]: IGroupStanding } = {};
        Object.keys(groupStandings).forEach((group_order) => {
            _groupStandingsSorted[group_order] = groupStandings[group_order];
            if (groupsStandingsSort[group_order]) {
                _groupStandingsSorted[group_order].standings.sort(
                    sort_by(
                        groupsStandingsSort[group_order].field,
                        groupsStandingsSort[group_order].order === 'asc',
                        primers[groupsStandingsSort[group_order].field],
                    ),
                );
            }
        });

        return _groupStandingsSorted;
    }, [groupStandings, groupsStandingsSort]);

    const handleClickSortStandings = useCallback((field: string) => {
        setStandingsSort((prev) => {
            if (prev.field === field) {
                return {
                    field,
                    order: prev.order === 'asc' ? 'desc' : 'asc',
                };
            }

            return {
                field,
                order: 'asc',
            };
        });
    }, []);

    const handleClickSortGroupsStandings = useCallback((group_order: string, field: string) => {
        setGroupsStandingsSort((prev) => {
            const newVal = { ...prev };
            let item = newVal[group_order];

            if (item?.field === field) {
                item = {
                    field,
                    order: item.order === 'asc' ? 'desc' : 'asc',
                };
            } else {
                item = {
                    field,
                    order: 'asc',
                };
            }

            newVal[group_order] = item;

            return newVal;
        });
    }, []);

    if (!currentBracket) {
        return null;
    }

    return (
        <div className="section-inner">
            <div className="container--sm">
                <div className="tabs active">
                    {currentBracket.type === BracketType.SWISS && !!standingsSorted?.length && (
                        <div className="tabs-item">
                            <div className="filter-content filter-content--sm">
                                <div className="groups">
                                    <div className="group-wrap">
                                        <div className="group group--admin">
                                            <div className="group-items">
                                                <div className="table table--center">
                                                    <div className="table-content">
                                                        <div className="table-inner">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th className="table-col--full table-col--left">
                                                                            <div
                                                                                className={`table-sort ${
                                                                                    standingsSort.field === 'name'
                                                                                        ? standingsSort.order === 'desk'
                                                                                            ? 'up'
                                                                                            : 'bottom'
                                                                                        : ''
                                                                                }`}>
                                                                                <div className="table-sort__text">
                                                                                    {tournament?.type.isTeamMode
                                                                                        ? 'Team'
                                                                                        : 'Player'}
                                                                                </div>
                                                                                <div
                                                                                    className="table-sort__btn"
                                                                                    onClick={() =>
                                                                                        handleClickSortStandings('name')
                                                                                    }></div>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div
                                                                                className={`table-sort ${
                                                                                    standingsSort.field === 'play'
                                                                                        ? standingsSort.order === 'desk'
                                                                                            ? 'up'
                                                                                            : 'bottom'
                                                                                        : ''
                                                                                }`}>
                                                                                <div className="table-sort__text">
                                                                                    Games
                                                                                </div>
                                                                                <div
                                                                                    className="table-sort__btn"
                                                                                    onClick={() =>
                                                                                        handleClickSortStandings('play')
                                                                                    }></div>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div
                                                                                className={`table-sort ${
                                                                                    standingsSort.field === 'win'
                                                                                        ? standingsSort.order === 'desk'
                                                                                            ? 'up'
                                                                                            : 'bottom'
                                                                                        : ''
                                                                                }`}>
                                                                                <div className="table-sort__text">
                                                                                    win
                                                                                </div>
                                                                                <div
                                                                                    className="table-sort__btn"
                                                                                    onClick={() =>
                                                                                        handleClickSortStandings('win')
                                                                                    }></div>
                                                                            </div>
                                                                        </th>
                                                                        {currentBracket.with_draw === 1 && (
                                                                            <th>
                                                                                <div
                                                                                    className={`table-sort ${
                                                                                        standingsSort.field === 'tie'
                                                                                            ? standingsSort.order ===
                                                                                              'desk'
                                                                                                ? 'up'
                                                                                                : 'bottom'
                                                                                            : ''
                                                                                    }`}>
                                                                                    <div className="table-sort__text">
                                                                                        tie
                                                                                    </div>
                                                                                    <div
                                                                                        className="table-sort__btn"
                                                                                        onClick={() =>
                                                                                            handleClickSortStandings(
                                                                                                'tie',
                                                                                            )
                                                                                        }></div>
                                                                                </div>
                                                                            </th>
                                                                        )}
                                                                        <th>
                                                                            <div
                                                                                className={`table-sort ${
                                                                                    standingsSort.field === 'lose'
                                                                                        ? standingsSort.order === 'desk'
                                                                                            ? 'up'
                                                                                            : 'bottom'
                                                                                        : ''
                                                                                }`}>
                                                                                <div className="table-sort__text">
                                                                                    lose
                                                                                </div>
                                                                                <div
                                                                                    className="table-sort__btn"
                                                                                    onClick={() =>
                                                                                        handleClickSortStandings('lose')
                                                                                    }></div>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div
                                                                                className={`table-sort ${
                                                                                    standingsSort.field === 'points'
                                                                                        ? standingsSort.order === 'desk'
                                                                                            ? 'up'
                                                                                            : 'bottom'
                                                                                        : ''
                                                                                }`}>
                                                                                <div className="table-sort__text">
                                                                                    points
                                                                                </div>
                                                                                <div
                                                                                    className="table-sort__btn"
                                                                                    onClick={() =>
                                                                                        handleClickSortStandings(
                                                                                            'points',
                                                                                        )
                                                                                    }></div>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {standingsSorted.map((standing, index) => {
                                                                        return (
                                                                            <tr key={standing.id}>
                                                                                <td>{index + 1}</td>
                                                                                {tournament?.type.isTeamMode && (
                                                                                    <td className="table-col--full table-col--left">
                                                                                        <TeamName
                                                                                            team={
                                                                                                (tournament
                                                                                                    ?.participants[
                                                                                                    standing.id ?? 0
                                                                                                ]?.team ?? {}) as Team
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                )}
                                                                                {!tournament?.type.isTeamMode && (
                                                                                    <td
                                                                                        className="table-col--full table-col--left"
                                                                                        style={{
                                                                                            color:
                                                                                                standing.player.color ||
                                                                                                '#ffffff',
                                                                                        }}>
                                                                                        {standing.player
                                                                                            .external_link && (
                                                                                            <a
                                                                                                href={
                                                                                                    standing.player
                                                                                                        .external_link
                                                                                                }
                                                                                                className="no-decor"
                                                                                                target="_blank"
                                                                                                rel="noreferrer">
                                                                                                {standing.player.name}
                                                                                            </a>
                                                                                        )}
                                                                                        {!standing.player
                                                                                            .external_link &&
                                                                                            standing.player.name}
                                                                                    </td>
                                                                                )}

                                                                                <td>{standing.play || '-'}</td>
                                                                                <td>{standing.win || '-'}</td>
                                                                                {currentBracket.with_draw === 1 && (
                                                                                    <td>{standing.tie || '-'}</td>
                                                                                )}
                                                                                <td>{standing.lose || '-'}</td>
                                                                                <td>{standing.points || '-'}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {currentBracket.type === BracketType.OLD_GROUP && groupStandingsSorted !== null && (
                        <div className="tabs-item" style={{ margin: '16px 0' }}>
                            {Object.keys(groupStandingsSorted).map((group_order) => {
                                const group = groupStandingsSorted[group_order];

                                if (!group) {
                                    return null;
                                }

                                return (
                                    <div className="table table--center" key={group_order}>
                                        <div className="simplebar-wrapper" style={{ margin: '0 -20px' }}>
                                            <div className="simplebar-height-auto-observer-wrapper">
                                                <div className="simplebar-height-auto-observer"></div>
                                            </div>
                                            <div className="simplebar-mask" style={{ position: 'unset' }}>
                                                <div
                                                    className="simplebar-offset"
                                                    style={{
                                                        right: 0,
                                                        bottom: 0,
                                                        position: 'unset',
                                                    }}>
                                                    <div
                                                        className="dragscroll"
                                                        style={{
                                                            height: 'auto',
                                                            overflow: 'hidden',
                                                        }}>
                                                        <div
                                                            className="simplebar-content"
                                                            style={{ padding: '0 20px' }}>
                                                            <div className="group-title prop">{group.title}</div>
                                                            <div className="table-content">
                                                                <div className="table-inner">
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th className="table-col--full table-col--left">
                                                                                    <div
                                                                                        className={`table-sort ${
                                                                                            !!groupsStandingsSort[
                                                                                                group_order
                                                                                            ] &&
                                                                                            groupsStandingsSort[
                                                                                                group_order
                                                                                            ].field === 'name'
                                                                                                ? groupsStandingsSort[
                                                                                                      group_order
                                                                                                  ].order === 'asc'
                                                                                                    ? 'up'
                                                                                                    : 'bottom'
                                                                                                : ''
                                                                                        }`}>
                                                                                        <div className="table-sort__text">
                                                                                            Player
                                                                                        </div>
                                                                                        <div
                                                                                            className="table-sort__btn"
                                                                                            onClick={() =>
                                                                                                handleClickSortGroupsStandings(
                                                                                                    group_order,
                                                                                                    'name',
                                                                                                )
                                                                                            }></div>
                                                                                    </div>
                                                                                </th>
                                                                                <th>
                                                                                    <div
                                                                                        className={`table-sort ${
                                                                                            !!groupsStandingsSort[
                                                                                                group_order
                                                                                            ] &&
                                                                                            groupsStandingsSort[
                                                                                                group_order
                                                                                            ].field === 'win'
                                                                                                ? groupsStandingsSort[
                                                                                                      group_order
                                                                                                  ].order === 'asc'
                                                                                                    ? 'up'
                                                                                                    : 'bottom'
                                                                                                : ''
                                                                                        }`}>
                                                                                        <div className="table-sort__text">
                                                                                            win
                                                                                        </div>
                                                                                        <div
                                                                                            className="table-sort__btn"
                                                                                            onClick={() =>
                                                                                                handleClickSortGroupsStandings(
                                                                                                    group_order,
                                                                                                    'win',
                                                                                                )
                                                                                            }></div>
                                                                                    </div>
                                                                                </th>
                                                                                <th>
                                                                                    <div
                                                                                        className={`table-sort ${
                                                                                            !!groupsStandingsSort[
                                                                                                group_order
                                                                                            ] &&
                                                                                            groupsStandingsSort[
                                                                                                group_order
                                                                                            ].field === 'tie'
                                                                                                ? groupsStandingsSort[
                                                                                                      group_order
                                                                                                  ].order === 'asc'
                                                                                                    ? 'up'
                                                                                                    : 'bottom'
                                                                                                : ''
                                                                                        }`}>
                                                                                        <div className="table-sort__text">
                                                                                            tie
                                                                                        </div>
                                                                                        <div
                                                                                            className="table-sort__btn"
                                                                                            onClick={() =>
                                                                                                handleClickSortGroupsStandings(
                                                                                                    group_order,
                                                                                                    'tie',
                                                                                                )
                                                                                            }></div>
                                                                                    </div>
                                                                                </th>
                                                                                <th>
                                                                                    <div
                                                                                        className={`table-sort ${
                                                                                            !!groupsStandingsSort[
                                                                                                group_order
                                                                                            ] &&
                                                                                            groupsStandingsSort[
                                                                                                group_order
                                                                                            ].field === 'lose'
                                                                                                ? groupsStandingsSort[
                                                                                                      group_order
                                                                                                  ].order === 'asc'
                                                                                                    ? 'up'
                                                                                                    : 'bottom'
                                                                                                : ''
                                                                                        }`}>
                                                                                        <div className="table-sort__text">
                                                                                            lose
                                                                                        </div>
                                                                                        <div
                                                                                            className="table-sort__btn"
                                                                                            onClick={() =>
                                                                                                handleClickSortGroupsStandings(
                                                                                                    group_order,
                                                                                                    'lose',
                                                                                                )
                                                                                            }></div>
                                                                                    </div>
                                                                                </th>
                                                                                <th>
                                                                                    <div
                                                                                        className={`table-sort ${
                                                                                            !!groupsStandingsSort[
                                                                                                group_order
                                                                                            ] &&
                                                                                            groupsStandingsSort[
                                                                                                group_order
                                                                                            ].field === 'points'
                                                                                                ? groupsStandingsSort[
                                                                                                      group_order
                                                                                                  ].order === 'asc'
                                                                                                    ? 'up'
                                                                                                    : 'bottom'
                                                                                                : ''
                                                                                        }`}>
                                                                                        <div className="table-sort__text">
                                                                                            points
                                                                                        </div>
                                                                                        <div
                                                                                            className="table-sort__btn"
                                                                                            onClick={() =>
                                                                                                handleClickSortGroupsStandings(
                                                                                                    group_order,
                                                                                                    'points',
                                                                                                )
                                                                                            }></div>
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {group.standings.map((standing, index) => {
                                                                                return (
                                                                                    <tr key={standing.id}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td
                                                                                            className="table-col--full table-col--left"
                                                                                            style={{
                                                                                                color:
                                                                                                    standing.player
                                                                                                        .color ||
                                                                                                    '#ffffff',
                                                                                            }}>
                                                                                            {standing.player
                                                                                                .external_link && (
                                                                                                <a
                                                                                                    href={
                                                                                                        standing.player
                                                                                                            .external_link
                                                                                                    }
                                                                                                    className="no-decor"
                                                                                                    target="_blank"
                                                                                                    rel="noreferrer">
                                                                                                    {
                                                                                                        standing.player
                                                                                                            .name
                                                                                                    }
                                                                                                </a>
                                                                                            )}
                                                                                            {!standing.player
                                                                                                .external_link &&
                                                                                                standing.player.name}
                                                                                        </td>
                                                                                        <td>{standing.win || '-'}</td>
                                                                                        <td>{standing.tie || '-'}</td>
                                                                                        <td>{standing.lose || '-'}</td>
                                                                                        <td>
                                                                                            {standing.points || '-'}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
