import { useFormik } from 'formik';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import * as yup from 'yup';

import { Control } from '~app/components/control';
import { Select } from '~app/components/select';
import { useTournamentsContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { Bracket, BracketType } from '~app/types/bracket.type';
import API from '~app/utils/apis';
import { BEST_OF_OPTIONS } from '~app/utils/constants';

interface ITournamentBracketRelegationFormikProps {
    bracketId?: number;
    title: string | null;
    participants: number;
    best_of: number;
    third_place: boolean;
    second_defeat: number;
}

const NUMBER_OF_PARTICIPANTS_OPTIONS = [
    { label: '4', value: 4 },
    { label: '8', value: 8 },
    { label: '16', value: 16 },
    { label: '32', value: 32 },
    { label: '64', value: 64 },
];

export interface ITournamentBracketRelegationModalRef {
    open: (bracket?: Bracket) => Promise<void>;
}

export const TournamentBracketRelegationModal = forwardRef(
    (props: any, ref: React.Ref<ITournamentBracketRelegationModalRef>): JSX.Element | null => {
        const { tournament, refreshTournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [isSaving, setSaving] = useState<boolean>(false);

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
        }, []);

        const formik = useFormik<ITournamentBracketRelegationFormikProps>({
            initialValues: {
                title: null,
                participants: 4,
                best_of: 1,
                third_place: false,
                second_defeat: 0,
            },
            validationSchema: yup.object({
                title: yup.string().required(),
            }),
            onSubmit: async () => {
                if (!formik.isValid || !tournament) {
                    return;
                }

                const data: any = {
                    tournamentId: tournament.id,
                    title: formik.values.title,
                    participants: formik.values.participants,
                    best_of: formik.values.best_of,
                    third_place: formik.values.third_place ? 1 : 0,
                    second_defeat: formik.values.second_defeat,
                    ordinal: 0,
                    type: BracketType.RELEGATION,
                };

                try {
                    setSaving(true);

                    if (!formik.values.bracketId) {
                        const res = await API.Brackets.create(data);
                        await refreshTournament(res);
                    } else {
                        await API.Brackets.update(formik.values.bracketId, data);
                        await refreshTournament();
                    }

                    await formik.setValues({
                        bracketId: undefined,
                        title: null,
                        participants: 4,
                        best_of: 1,
                        third_place: false,
                        second_defeat: 0,
                    });

                    setOpen(false);
                } catch (e) {
                    console.error(e);
                } finally {
                    setSaving(false);
                }
            },
        });

        useImperativeHandle(ref, () => ({
            async open(bracket?: Bracket) {
                setOpen(true);

                if (bracket) {
                    await formik.setValues({
                        bracketId: bracket.id,
                        title: bracket.title,
                        participants: bracket.participants ?? 4,
                        best_of: bracket.best_of ?? 1,
                        third_place: bracket.third_place === 1,
                        second_defeat: bracket.second_defeat ?? 0,
                    });
                } else {
                    await formik.setValues({
                        bracketId: undefined,
                        title: null,
                        participants: 4,
                        best_of: 1,
                        third_place: false,
                        second_defeat: 0,
                    });
                }
            },
        }));

        const handleNumberOfParticipantsChange = useCallback((e: { value: number; label: string } | null) => {
            formik.setFieldValue('participants', e?.value ?? 4);
        }, []);

        const handleBestOfChange = useCallback((e: { value: number; label: string } | null) => {
            formik.setFieldValue('best_of', e?.value ?? 1);
        }, []);

        const handleSecondDefeatChange = useCallback(
            (value: number) => () => {
                formik.setFieldValue('second_defeat', value);
            },
            [],
        );

        if (!isOpen) {
            return null;
        }

        return (
            <ModalContainer
                isShowLoader={isSaving}
                onClickClose={onClickCloseHandler}
                title={
                    <>
                        {!formik.values.bracketId ? 'Add' : 'Edit'} bracket <span>/ relegation</span>
                    </>
                }>
                <ModalContentSlot>
                    <>
                        <div className="content-block">
                            <Control
                                title={'Name of Bracket'}
                                fields={[
                                    <div className="input" key="title">
                                        <input
                                            type="text"
                                            className="field"
                                            name="title"
                                            placeholder="title"
                                            value={formik.values.title ?? ''}
                                            onChange={formik.handleChange}
                                        />
                                    </div>,
                                ]}
                            />
                        </div>

                        {!formik.values.bracketId && (
                            <>
                                <div className="content-block">
                                    <div className="controls">
                                        <Control
                                            title={'Number of participants'}
                                            fields={[
                                                <div className="select" key="organizer_id">
                                                    <div className="select-btn">
                                                        <Select
                                                            value={formik.values.participants}
                                                            onChange={handleNumberOfParticipantsChange}
                                                            options={NUMBER_OF_PARTICIPANTS_OPTIONS}
                                                            isFullWidth
                                                            width="100px"
                                                        />
                                                    </div>
                                                </div>,
                                            ]}
                                        />
                                        <Control
                                            title={'Best of'}
                                            fields={[
                                                <>
                                                    <div className="select" key="organizer_id">
                                                        <div className="select-btn">
                                                            <Select
                                                                value={formik.values.best_of}
                                                                onChange={handleBestOfChange}
                                                                options={BEST_OF_OPTIONS.filter((_) => _.value % 2)}
                                                                isFullWidth
                                                                width="100px"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="control-field__hint">
                                                        <div className="text--sm">
                                                            Choose the number of rounds 1, 3 or 5 according to the
                                                            results of which the winner passes on
                                                        </div>
                                                    </div>
                                                </>,
                                            ]}
                                        />
                                    </div>
                                </div>

                                <div className="content-block">
                                    <div className="controls">
                                        <Control
                                            title={''}
                                            fields={[
                                                <div className="checkbox" key="third_place">
                                                    <label className="checkbox-label" htmlFor="third_place">
                                                        <input
                                                            className="checkbox-input"
                                                            type="checkbox"
                                                            id="third_place"
                                                            name="third_place"
                                                            checked={formik.values.third_place}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <div className="checkbox-content">
                                                            <div className="checkbox-style"></div>
                                                            <div className="checkbox-text h6">
                                                                Battle for the 3rd place
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>,
                                            ]}
                                        />
                                    </div>
                                </div>

                                <div className="content-block">
                                    <div className="controls">
                                        <Control
                                            title={'elimination type'}
                                            fields={[
                                                <div className="radios" key="second_defeat">
                                                    <div className="radio radio--sm">
                                                        <label className="radio-label">
                                                            <input
                                                                className="radio-input"
                                                                type="radio"
                                                                name="second_defeat"
                                                                checked={formik.values.second_defeat === 0}
                                                                onChange={handleSecondDefeatChange(0)}
                                                            />
                                                            <div className="radio-content">
                                                                <div className="radio-style"></div>
                                                                <div className="radio-text h6">
                                                                    Elimination at the first defeat
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="radio radio--sm">
                                                        <label className="radio-label">
                                                            <input
                                                                className="radio-input"
                                                                type="radio"
                                                                name="second_defeat"
                                                                checked={formik.values.second_defeat === 1}
                                                                onChange={handleSecondDefeatChange(1)}
                                                            />
                                                            <div className="radio-content">
                                                                <div className="radio-style"></div>
                                                                <div className="radio-text h6">
                                                                    Elimination after the second defeat
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>,
                                            ]}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button
                            className="btn"
                            type="button"
                            onClick={formik.submitForm}
                            disabled={!formik.isValid || isSaving}>
                            Save and continue
                        </button>
                        <button className="btn" type="button" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
