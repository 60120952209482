import React, { useMemo, useState } from 'react';

import { BracketGroupHeaderPlayerCell } from '~app/components/tournament-bracket-group/bracket-group-header-player-cell';
import { BracketGroupParticipant } from '~app/components/tournament-bracket-group/bracket-group-participant';
import { BracketGroupTableCellDuel } from '~app/components/tournament-bracket-group/bracket-group-table-cell-duel';
import { Bracket } from '~app/types/bracket.type';
import { IBracketGroup } from '~app/types/bracket-group.interface';
import { IBracketGroupDuel } from '~app/types/bracket-group-duel.interface';
import { Participant } from '~app/types/participant.type';
import { Tournament } from '~app/types/tournament.type';
import { cn } from '~app/utils';
import { ALPHABET } from '~app/utils/constants';

import styles from './tournament-bracket-group.module.scss';

interface ITournamentBracketGroupTableProps {
    canEdit: boolean;
    group: IBracketGroup;
    tournament: Tournament;
    bracket: Bracket;
    isShowGroupName?: boolean;
}

export const TournamentBracketGroupTable = ({
    canEdit,
    group,
    bracket,
    tournament,
    isShowGroupName,
}: ITournamentBracketGroupTableProps): JSX.Element | null => {
    const [activeColumn, setActiveColumn] = useState<number | null>(null);

    const participants = useMemo(() => {
        return Array.from({ length: bracket.participants }, (_, i) => {
            const tournamentParticipantId = group.participants[i]?.participant_id ?? -1;
            const groupParticipantId = group.participants[i]?.id ?? -1;
            const participant: any = !tournament?.participants
                ? null
                : tournament.participants[tournamentParticipantId];

            if (participant) {
                const duels = group.duels.filter(
                    (_) => _.participant_one_id === groupParticipantId || _.participant_two_id === groupParticipantId,
                );

                participant.win = duels.filter((_) => _.winner_id === groupParticipantId).length;
                participant.tie = duels.filter((_) => _.draw === 1).length;
                participant.points = bracket.with_draw === 0 ? participant.win : participant.win * 3 + participant.tie;
                participant.tournamentParticipantId = tournamentParticipantId;
                participant.groupParticipantId = groupParticipantId;
                participant.duels = duels;
            }

            return participant;
        }).sort((a, b) => (a !== null && b !== null ? b.points - a.points : -1));
    }, [bracket, group]);

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th style={{ alignContent: 'center', paddingLeft: '16px', fontSize: '16px' }}>
                        {isShowGroupName ? ` Group ${ALPHABET[group.ordinal - 1]}` : ''}
                    </th>
                    {participants.map((participant, i) => {
                        return (
                            <th key={`head-${i}`} className={cn(styles.headPlayerCell, styles.interactiveCell)}>
                                <BracketGroupHeaderPlayerCell
                                    participant={participant as Participant}
                                    isActive={activeColumn === i}
                                />
                            </th>
                        );
                    })}
                    <th className={styles.cellDivider}>
                        <div>Games</div>
                    </th>
                    <th>
                        <div>Win</div>
                    </th>
                    {bracket.with_draw === 1 && (
                        <th>
                            <div>Tie</div>
                        </th>
                    )}
                    <th>
                        <div>Lose</div>
                    </th>
                    <th>
                        <div>Points</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {participants.map((participant, i) => {
                    return (
                        <tr key={`row-${i}`}>
                            <td className={styles.playerCell}>
                                <BracketGroupParticipant
                                    tournament={tournament}
                                    bracket={bracket}
                                    group={group}
                                    tournamentParticipantId={participant?.tournamentParticipantId}
                                    groupParticipantId={participant?.groupParticipantId}
                                    ordinal={i + 1}
                                />
                            </td>
                            {participants.map((_, j) => {
                                if (i === j) {
                                    return <td key={`cell-${i}-${j}`} className={styles.emptyDuelCell} />;
                                }

                                const oneParticipantId = participants[i]?.groupParticipantId;
                                const twoParticipantId = participants[j]?.groupParticipantId;

                                const duel = group.duels.find(
                                    (duel) =>
                                        (duel.participant_one_id === oneParticipantId &&
                                            duel.participant_two_id === twoParticipantId) ||
                                        (duel.participant_two_id === oneParticipantId &&
                                            duel.participant_one_id === twoParticipantId),
                                );

                                if (!duel) {
                                    return (
                                        <td
                                            key={`cell-${i}-${j}`}
                                            className={[styles.duelCell, styles.interactiveCell].join(' ')}>
                                            <div>-</div>
                                        </td>
                                    );
                                }

                                return (
                                    <td
                                        onMouseOver={() => setActiveColumn(j)}
                                        onMouseOut={() => setActiveColumn(null)}
                                        key={`cell-${i}-${j}`}
                                        className={[styles.duelCell, styles.interactiveCell].join(' ')}>
                                        <BracketGroupTableCellDuel
                                            bracket={bracket}
                                            participantOneId={participant.groupParticipantId}
                                            group={group}
                                            duel={duel}
                                            canEdit={canEdit}
                                        />
                                    </td>
                                );
                            })}
                            <td
                                className={cn(
                                    styles.cellDivider,
                                    i === 0 ? styles.cellDividerFirst : null,
                                    i === bracket.participants - 1 ? styles.cellDividerLast : null,
                                )}>
                                <div>
                                    {
                                        participant?.duels?.filter(
                                            (_: IBracketGroupDuel) => !!_.winner_id || _.draw === 1,
                                        ).length
                                    }
                                </div>
                            </td>
                            <td>
                                <div>{participant?.win}</div>
                            </td>
                            {bracket.with_draw === 1 && (
                                <td>
                                    <div>{participant?.tie}</div>
                                </td>
                            )}
                            <td>
                                <div>
                                    {
                                        participant?.duels?.filter(
                                            (_: IBracketGroupDuel) => _.loser_id === participant.groupParticipantId,
                                        ).length
                                    }
                                </div>
                            </td>
                            <td>
                                <div>{participant?.points}</div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
