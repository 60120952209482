import moment from 'moment/moment';
import React from 'react';
import { Link } from 'react-router-dom';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { useRootContext } from '~app/contexts';
import { IMedia } from '~app/types/media.interface';
import { makeS3ResourceLink } from '~app/utils';

interface IMediaCardProps {
    media: IMedia;
}

export const MediaCard = ({ media }: IMediaCardProps): JSX.Element => {
    const { showLoader } = useRootContext();

    return (
        <Link
            className={`news-item ${media.active ? '' : 'not-active'}`}
            to={`/media/${media.slug}`}
            onClick={showLoader}>
            <div className="news-item__inner">
                <div className="news-item__media">
                    <picture>
                        <source srcSet={makeS3ResourceLink(media.bg_image)} />
                        <img className="news-item__img" src={makeS3ResourceLink(media.bg_image)} alt="" />
                    </picture>
                </div>
                <div className="news-item__content">
                    <div className="news-item__tags">
                        <div className="tags">
                            {!!media.is_video && <div className="tag tag--sm">video</div>}
                            {!!media.is_text && <div className="tag tag--sm">text</div>}
                        </div>
                    </div>
                    <div className="news-item__main">
                        <div className="news-item__title h5">{media.title}</div>
                        <div className="news-item__date">
                            <div className="date">
                                <div className="date-title h6">{moment(media.date).format('D MMMM YYYY')}</div>
                                <div className="date-prop prop">date</div>
                                <div className="date-icon">
                                    <svg className="icon">
                                        <use href={`${MainSymbols}#image-chevron`}></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};
