import { Team } from '~app/types/team.type';

export interface IDuelParticipant {
    id: number | null;
    score: number;
    winner: boolean;
    loser: boolean;
    player?: {
        nick: string;
        color: string;
        external_link: string;
    };
    team?: Team;
}

export interface IDuel {
    active: number;
    draw: number;
    code: string;
    completed: number;
    id: number;
    order: number;
    round_id: number;
    tie: boolean;
    participant1: IDuelParticipant;
    participant2: IDuelParticipant;
}

const copyParticipant = (participant: IDuelParticipant): IDuelParticipant => {
    return {
        ...participant,
        player: participant.player ? { ...participant.player } : undefined,
        team: participant.team ? { ...participant.team } : undefined,
    };
};

export const copyDuel = (originalDuel: IDuel): IDuel => {
    return {
        ...originalDuel,
        participant1: copyParticipant(originalDuel.participant1),
        participant2: copyParticipant(originalDuel.participant2),
    };
};
