import moment from 'moment/moment';
import React, { useState } from 'react';
import RDatePicker from 'react-datepicker';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';

interface IDatepickerProps {
    date: Date | null;
    onChange: (date: Date | null) => void;
    showTimeSelect?: boolean;
    showTimeSelectOnly?: boolean;
    timeIntervals?: number;
    timeCaption?: string;
    dateFormat?: string;
    minDate?: Date | null;
    maxDate?: Date | null;
    className?: string;
}
export const DatePicker = ({
    date,
    onChange,
    showTimeSelect,
    showTimeSelectOnly,
    timeIntervals,
    timeCaption,
    dateFormat,
    minDate,
    maxDate,
    className,
}: IDatepickerProps) => {
    const [isOpen, setOpen] = useState<boolean>(false);

    return (
        <div className={`datepicker-el datepicker-el--style-2 ${date ? 'selected' : ''} ${className}`}>
            <div
                className="datepicker-el__btn field"
                onClick={() => {
                    setOpen(true);
                }}>
                <div className="datepicker-el__btn-text prop">{date ? moment(date).format(dateFormat) : 'choose'}</div>
                <div className="datepicker-el__btn-icon">
                    <svg className="icon">
                        <use href={`${MainSymbols}#image-chevron`}></use>
                    </svg>
                </div>
            </div>
            <div className="datepicker-el__drop">
                <div className="datepicker-el__inner">
                    {isOpen && (
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                backgroundColor: 'transparent',
                            }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        />
                    )}
                    <RDatePicker
                        customInput={<div style={{ minWidth: '40px' }} />}
                        selected={date}
                        onChange={(date) => {
                            setOpen(false);
                            onChange(date);
                        }}
                        open={isOpen}
                        showTimeSelect={showTimeSelect}
                        showTimeSelectOnly={showTimeSelectOnly}
                        timeIntervals={timeIntervals}
                        timeCaption={timeCaption}
                        dateFormat={dateFormat?.toLowerCase()}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </div>
            </div>
        </div>
    );
};
