/* eslint-disable no-template-curly-in-string */

export default {
    translations: {
        common: {
            back: 'Back',
            tournaments: 'tournaments',
            champs: 'champs',
            media: 'media',
            contacts: 'contacts',
            donate: 'donate',
            thanks: 'thanks',
        },
        alerts: {
            success: {},
            error: {},
            warnings: {},
        },
        errors: {
            anyErr: 'An error has occurred. {{logCode}}',
            something: 'The server is unavailable, please try again later. {{logCode}}',
        },
    },
};
