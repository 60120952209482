import { useFormik } from 'formik';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import * as yup from 'yup';

import { Control } from '~app/components/control';
import { Select } from '~app/components/select';
import { useTournamentsContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { Bracket, BracketType } from '~app/types/bracket.type';
import { generateNumberSelectOptions } from '~app/utils';
import API from '~app/utils/apis';

interface ITournamentBracketSwissFormikProps {
    bracketId?: number;
    title: string | null;
    round_count: number;
    participants: number | null;
    with_draw: boolean;
    best_of: number;
}

export interface ITournamentBracketSwissModalRef {
    open: (bracket?: Bracket) => Promise<void>;
}

export const TournamentBracketSwissModal = forwardRef(
    (props: any, ref: React.Ref<ITournamentBracketSwissModalRef>): JSX.Element | null => {
        const { tournament, refreshTournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [isSaving, setSaving] = useState<boolean>(false);

        const formik = useFormik<ITournamentBracketSwissFormikProps>({
            initialValues: {
                title: null,
                round_count: 1,
                participants: null,
                with_draw: false,
                best_of: 1,
            },
            validationSchema: yup.object({
                title: yup.string().required(),
                participants: yup.number().required(),
            }),
            onSubmit: async () => {
                if (!formik.isValid || !tournament) {
                    return;
                }

                const data: any = {
                    tournamentId: tournament.id,
                    title: formik.values.title,
                    round_count: formik.values.round_count,
                    participants: formik.values.participants,
                    with_draw: formik.values.with_draw ? 1 : 0,
                    best_of: formik.values.best_of,
                    type: BracketType.SWISS,
                    ordinal: 0,
                };

                try {
                    setSaving(true);

                    if (!formik.values.bracketId) {
                        const res = await API.Brackets.create(data);
                        await refreshTournament(res);
                    } else {
                        await API.Brackets.update(formik.values.bracketId, data);
                        await refreshTournament();
                    }

                    await formik.setValues({
                        bracketId: undefined,
                        title: null,
                        round_count: 1,
                        participants: null,
                        with_draw: false,
                        best_of: 1,
                    });

                    setOpen(false);
                } catch (e) {
                    console.error(e);
                } finally {
                    setSaving(false);
                }
            },
        });

        const roundCountMax = useMemo(() => {
            if (formik.values.participants === null || formik.values.participants <= 8) {
                return 5;
            }

            if (formik.values.participants >= 9 && formik.values.participants <= 16) {
                return 6;
            }

            if (formik.values.participants >= 17 && formik.values.participants <= 32) {
                return 7;
            }

            if (formik.values.participants >= 33 && formik.values.participants <= 64) {
                return 8;
            }

            if (formik.values.participants >= 65 && formik.values.participants <= 128) {
                return 9;
            }

            if (formik.values.participants >= 129 && formik.values.participants <= 256) {
                return 10;
            }

            if (formik.values.participants >= 257 && formik.values.participants <= 512) {
                return 11;
            }

            if (formik.values.participants >= 513 && formik.values.participants <= 1024) {
                return 12;
            }

            if (formik.values.participants >= 1025 && formik.values.participants <= 2048) {
                return 13;
            }

            if (formik.values.participants >= 2049 && formik.values.participants <= 4096) {
                return 14;
            }

            if (formik.values.participants >= 4097 && formik.values.participants <= 8192) {
                return 15;
            }

            return 15;
        }, [formik.values.participants]);

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
        }, []);

        const handleRoundCountChange = useCallback((e: { value: number; label: string } | null) => {
            formik.setFieldValue('round_count', e?.value ?? 1);
        }, []);

        const onChangeWithDrawHandler = useCallback(() => {
            formik.setFieldValue('with_draw', !formik.values.with_draw);
        }, [formik.values.with_draw]);

        const handleBestOfChange = useCallback((e: { value: number; label: string } | null) => {
            formik.setFieldValue('best_of', e?.value ?? 1);
        }, []);

        useImperativeHandle(ref, () => ({
            async open(bracket?: Bracket) {
                setOpen(true);

                if (bracket) {
                    await formik.setValues({
                        bracketId: bracket.id,
                        title: bracket.title,
                        round_count: bracket.round_count ?? 1,
                        participants: bracket.participants ?? null,
                        with_draw: bracket.with_draw === 1,
                        best_of: bracket.best_of ?? 1,
                    });
                } else {
                    await formik.setValues({
                        bracketId: undefined,
                        title: null,
                        round_count: 1,
                        participants: null,
                        with_draw: false,
                        best_of: 1,
                    });
                }
            },
        }));

        if (!isOpen) {
            return null;
        }

        return (
            <ModalContainer
                isShowLoader={isSaving}
                onClickClose={onClickCloseHandler}
                title={
                    <>
                        {!formik.values.bracketId ? 'Add' : 'Edit'} bracket <span>/ swiss</span>
                    </>
                }>
                <ModalContentSlot>
                    <div className="content-block">
                        <Control
                            title={'Name of Bracket'}
                            isRequired
                            fields={[
                                <div className="input" key="title">
                                    <input
                                        type="text"
                                        className="field"
                                        name="title"
                                        placeholder="title"
                                        value={formik.values.title ?? ''}
                                        onChange={formik.handleChange}
                                    />
                                </div>,
                            ]}
                        />

                        {!formik.values.bracketId && (
                            <>
                                <Control
                                    title={'Number of players in the table'}
                                    isRequired
                                    fields={[
                                        <div className="input" key="participants">
                                            <input
                                                type="number"
                                                className="field"
                                                name="participants"
                                                placeholder="Number of players in the table"
                                                value={formik.values.participants ?? ''}
                                                onChange={formik.handleChange}
                                            />
                                        </div>,
                                    ]}
                                />

                                <Control
                                    title={''}
                                    fields={[
                                        <div className="checkbox" key="third_place">
                                            <label className="checkbox-label" htmlFor="third_place">
                                                <input
                                                    className="checkbox-input"
                                                    type="checkbox"
                                                    id="third_place"
                                                    name="third_place"
                                                    checked={formik.values.with_draw}
                                                    onChange={onChangeWithDrawHandler}
                                                />
                                                <div className="checkbox-content">
                                                    <div className="checkbox-style"></div>
                                                    <div className="checkbox-text h6">With draw</div>
                                                </div>
                                            </label>
                                        </div>,
                                    ]}
                                />

                                <Control
                                    title={'Best of'}
                                    fields={[
                                        <>
                                            <div className="select" key="best_of">
                                                <div className="select-btn">
                                                    <Select
                                                        value={formik.values.best_of}
                                                        onChange={handleBestOfChange}
                                                        options={generateNumberSelectOptions(5).filter(
                                                            (_) => formik.values.with_draw || _.value % 2,
                                                        )}
                                                        isFullWidth
                                                        width="100px"
                                                    />
                                                </div>
                                            </div>
                                        </>,
                                    ]}
                                />

                                <Control
                                    title={'Number of rounds'}
                                    fields={[
                                        <div className="select" key="round_count">
                                            <div className="select-btn">
                                                <Select
                                                    value={formik.values.round_count}
                                                    onChange={handleRoundCountChange}
                                                    options={generateNumberSelectOptions(roundCountMax)}
                                                    isFullWidth
                                                    width="100px"
                                                />
                                            </div>
                                        </div>,
                                    ]}
                                />
                            </>
                        )}
                    </div>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button
                            className="btn"
                            type="button"
                            onClick={formik.submitForm}
                            disabled={!formik.isValid || isSaving}>
                            Save and continue
                        </button>
                        <button className="btn" type="button" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
