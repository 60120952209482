import React, { useEffect, useMemo, useRef } from 'react';

import { TeamName } from '~app/components/common/team-name';
import { Image } from '~app/components/image';
import { useRootContext } from '~app/contexts';
import { Participant } from '~app/types/participant.type';

import styles from './bracket-group-header-player-cell.module.scss';

interface IBracketGroupHeaderPlayerCellProps {
    participant?: Participant | null | undefined;
    isActive?: boolean;
}

export const BracketGroupHeaderPlayerCell = ({
    participant,
    isActive,
}: IBracketGroupHeaderPlayerCellProps): JSX.Element | null => {
    const { getAvatar } = useRootContext();

    const selfRef = useRef<HTMLDivElement>(null);
    const infoRef = useRef<HTMLDivElement>(null);

    const avatar = useMemo(() => {
        return getAvatar(participant?.player ?? {});
    }, [participant]);

    const positionElement = () => {
        const targetRect = selfRef.current?.getBoundingClientRect();

        if (infoRef.current && targetRect) {
            infoRef.current.style.top = `${targetRect.top - 106}px`;
            infoRef.current.style.left = `${targetRect.left + targetRect.width / 2 - (infoRef.current?.clientWidth ?? 0) / 2}px`;
            infoRef.current.style.minWidth = `${targetRect.width * 2}px`;
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', positionElement, { passive: true });

        return () => {
            window.removeEventListener('scroll', positionElement);
        };
    }, []);

    useEffect(() => {
        positionElement();
    }, [isActive]);

    if (!participant) {
        return null;
    }

    return (
        <div className={styles.container} ref={selfRef}>
            {participant.player && (
                <>
                    <div
                        className="table-player__avatar"
                        style={{
                            margin: 0,
                        }}>
                        {participant.player && !participant.player.external_link && (
                            <Image img={avatar.img} webp={avatar.webp} defaultImg={avatar.defaultImg} />
                        )}
                        {participant.player && participant.player.external_link && (
                            <a
                                href={participant.player?.external_link}
                                className="no-decor"
                                target="_blank"
                                rel="noreferrer">
                                <Image img={avatar.img} webp={avatar.webp} defaultImg={avatar.defaultImg} />
                            </a>
                        )}
                    </div>
                    <div
                        className={styles.infoContainer}
                        ref={infoRef}
                        style={{ visibility: isActive ? 'visible' : 'hidden' }}>
                        {participant.player && (
                            <div className={styles.info}>
                                <div className="table-player__avatar" style={{ margin: 0 }}>
                                    <Image img={avatar.img} webp={avatar.webp} defaultImg={avatar.defaultImg} />
                                </div>
                                <div
                                    style={{
                                        color: participant.player?.class?.avatar,
                                    }}>
                                    {participant.player?.external_link && (
                                        <a
                                            href={participant.player.external_link}
                                            className="no-decor"
                                            target="_blank"
                                            rel="noreferrer">
                                            {participant.player.nick}
                                        </a>
                                    )}
                                    {!participant.player?.external_link && participant.player?.nick}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}

            {participant.team && (
                <div style={{ width: 'inherit' }}>
                    <TeamName team={participant.team} justifyContent="center" />
                </div>
            )}
        </div>
    );
};
