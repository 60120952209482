import { createEmptyPlayer, Player } from '~app/types/player.type';
import { createEmptyTeam, Team } from '~app/types/team.type';

export type Participant = {
    id: number;
    player?: Player;
    team?: Team;
};

export const createEmptyParticipant = (): Participant => {
    return {
        id: -1,
        player: createEmptyPlayer(),
        team: createEmptyTeam(),
    };
};
