import React, { useCallback, useMemo } from 'react';
import SimpleBar from 'simplebar-react';

import { Tournament } from '~app/types/tournament.type';

interface IBracketTableFilterProps {
    tournament: Tournament;
    search: string;
    onChangeSearch: (_search: string) => void;
    onClickInsertParticipants: () => void;
}

export const BracketTableFilter = (props: IBracketTableFilterProps) => {
    const { tournament, search, onChangeSearch } = props;

    const searchInputPlaceholder = useMemo<string>(() => {
        return tournament.type.isTeamMode ? 'enter name of team' : 'enter name of player';
    }, [tournament]);

    const onChangeSearchHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeSearch(event.target.value);
    }, []);

    return (
        <SimpleBar className="filter mb">
            <div className="filter-main">
                <div className="filter-wrap">
                    <div className="filter-inner">
                        <div className="filter-search" style={{ display: 'flex', width: '100%' }}>
                            <div className="filter-search__text">find</div>
                            <div className="filter-search__field">
                                <input
                                    type="text"
                                    id="brackettablerowsearch-nick"
                                    className="field field--sm"
                                    name="nick"
                                    placeholder={searchInputPlaceholder}
                                    value={search}
                                    onChange={onChangeSearchHandler}
                                />
                            </div>
                            <div style={{ flex: 1 }} />
                            <button
                                className="btn btn--sm"
                                style={{ margin: '0 8px' }}
                                onClick={props.onClickInsertParticipants}>
                                Insert participants
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </SimpleBar>
    );
};
