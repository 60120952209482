import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Loader } from '~app/components/loader/loader';
import { BracketRelegationDuelItem } from '~app/components/tournament-bracket-relegation/bracket-relegation-duel-item';
import { useTournamentsContext } from '~app/contexts';
import { Bracket } from '~app/types/bracket.type';
import { IBracketRound, ROUND_TYPE } from '~app/types/bracket-round.interface';
import { copyDuel, IDuel } from '~app/types/duel.interface';
import { Participant } from '~app/types/participant.type';
import { ISelectOption } from '~app/types/select-option.interface';
import API from '~app/utils/apis';

import styles from './bracket-relegation-duel.module.scss';

interface IRelegationBracketDuelProps {
    duel: IDuel;
    round: IBracketRound;
    bracket: Bracket;
    bestOf: number;
    participantsOptions: ISelectOption<Participant>[];
    bracketParticipants: number[];
    canEdit: boolean;
    canEditParticipant: boolean;
}

export const BracketRelegationDuel = (props: IRelegationBracketDuelProps): JSX.Element | null => {
    const { refreshTournament } = useTournamentsContext();

    const [duel, setDuel] = useState<IDuel>(copyDuel(props.duel));
    const [isEditing, setEditing] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const duelRef = useRef(copyDuel(duel));

    const isActiveNextDuel = useMemo(() => {
        const winnerID = duel.participant1.winner
            ? duel.participant1.id
            : duel.participant2.winner
              ? duel.participant2.id
              : null;
        const loserID = duel.participant1.loser
            ? duel.participant1.id
            : duel.participant2.loser
              ? duel.participant2.id
              : null;

        if (winnerID === null) {
            return false;
        }

        const { bracket, round } = props;

        switch (round.type_id) {
            case ROUND_TYPE.MAIN: {
                if (!bracket.roundsMain || bracket.roundsMain.rounds.length === 0) {
                    return false;
                }

                let nextDuel: IDuel | undefined;

                const currentRoundIndex = bracket.roundsMain.rounds.findIndex((_) => _.id === round.id);
                const nextRound = bracket.roundsMain.rounds[currentRoundIndex + 1];
                const grandFinalRounds = bracket.roundsGrand?.rounds;

                if (nextRound) {
                    nextDuel = nextRound.duels.find(
                        (_) => _.participant1.id === winnerID || _.participant2.id === winnerID,
                    );
                }

                if (!nextRound && grandFinalRounds && grandFinalRounds.length > 0) {
                    grandFinalRounds.forEach((grandFinalRound) => {
                        nextDuel = grandFinalRound.duels.find(
                            (_) => _.participant1.id === winnerID || _.participant2.id === winnerID,
                        );
                    });
                }

                let _3rdDuel: IDuel | undefined;
                if (grandFinalRounds && grandFinalRounds.length > 0) {
                    grandFinalRounds.forEach((grandFinalRound) => {
                        _3rdDuel =
                            grandFinalRound.duels.find(
                                (_) => _.participant1.id === loserID || _.participant2.id === loserID,
                            ) ?? _3rdDuel;
                    });
                }

                if (!nextDuel) {
                    return false;
                }

                if (
                    props.bracket.second_defeat === 1 &&
                    !!bracket.roundsDefeat &&
                    bracket.roundsDefeat.rounds.length > 0
                ) {
                    const nextDefeatRound = bracket.roundsDefeat.rounds.find(
                        (_) => _.level === (round.level < 3 ? round.level + 1 : round.level * 2 - 1),
                    );

                    const nextDefeatDuel = nextDefeatRound?.duels.find(
                        (_) => _.participant1.id === loserID || _.participant2.id === loserID,
                    );

                    if (nextDefeatDuel) {
                        return (
                            nextDuel.participant1.score > 0 ||
                            nextDuel.participant2.score > 0 ||
                            (!!_3rdDuel && _3rdDuel.participant1.score > 0) ||
                            (!!_3rdDuel && _3rdDuel.participant2.score > 0) ||
                            nextDefeatDuel.participant1.score > 0 ||
                            nextDefeatDuel.participant2.score > 0
                        );
                    }
                }

                return (
                    nextDuel.participant1.score > 0 ||
                    nextDuel.participant2.score > 0 ||
                    (!!_3rdDuel && _3rdDuel.participant1.score > 0) ||
                    (!!_3rdDuel && _3rdDuel.participant2.score > 0)
                );
            }
            case ROUND_TYPE.DEFEAT: {
                if (!bracket.roundsDefeat) {
                    return false;
                }

                let nextDefeatDuel: IDuel | undefined;

                const currentDefeatRoundIndex = bracket.roundsDefeat.rounds.findIndex((_) => _.id === round.id);
                const nextDefeatRound = bracket.roundsDefeat.rounds[currentDefeatRoundIndex + 1];
                const grandFinalRounds = bracket.roundsGrand?.rounds;

                if (nextDefeatRound) {
                    nextDefeatDuel = nextDefeatRound.duels.find(
                        (_) => _.participant1.id === winnerID || _.participant2.id === winnerID,
                    );
                }

                if (!nextDefeatRound && grandFinalRounds && grandFinalRounds.length > 0) {
                    grandFinalRounds.forEach((grandFinalRound) => {
                        nextDefeatDuel =
                            grandFinalRound.duels.find(
                                (_) => _.participant1.id === winnerID || _.participant2.id === winnerID,
                            ) ?? nextDefeatDuel;
                    });
                }

                if (!nextDefeatDuel) {
                    return false;
                }

                return nextDefeatDuel.participant1.score > 0 || nextDefeatDuel.participant2.score > 0;
            }
            case ROUND_TYPE.GRAND: {
                if (!bracket.roundsGrand) {
                    return false;
                }

                let nextGrandDuel: IDuel | undefined;

                const currentGrandRoundIndex = bracket.roundsGrand.rounds.findIndex((_) => _.id === round.id);
                const nextGrandRound = bracket.roundsGrand.rounds[currentGrandRoundIndex + 1];

                if (nextGrandRound) {
                    nextGrandDuel = nextGrandRound.duels.find(
                        (_) => _.participant1.id === winnerID || _.participant2.id === winnerID,
                    );
                }

                if (!nextGrandDuel) {
                    return false;
                }

                return nextGrandDuel.participant1.score > 0 || nextGrandDuel.participant2.score > 0;
            }
            default: {
                return false;
            }
        }
    }, [props, duel]);

    const canEdit = useMemo(() => {
        return props.canEdit && !isActiveNextDuel;
    }, [props.canEdit, props.round, isActiveNextDuel, duel]);

    const blurAllTextarea = () => {
        const textareaList = document.querySelectorAll('textarea');
        for (let i = 0; i < textareaList.length; i++) {
            textareaList[i].blur();
        }

        return textareaList.length > 0;
    };

    const onClickDuelHandler = useCallback(() => {
        if (canEdit) {
            setEditing(true);
        }
    }, [canEdit]);

    const onChangeScore = useCallback(
        (ordinal: number) => async (value?: string) => {
            setDuel((prev) => {
                const result = {
                    ...prev,
                    participant1: { ...prev.participant1 },
                    participant2: { ...prev.participant2 },
                };

                switch (ordinal) {
                    case 1: {
                        result.participant1.score = value ? +value : 0;
                        if (!result.participant2.score) {
                            result.participant2.score = 0;
                        }
                        break;
                    }
                    case 2: {
                        result.participant2.score = value ? +value : 0;
                        if (!result.participant1.score) {
                            result.participant1.score = 0;
                        }
                        break;
                    }
                }

                return result;
            });
        },
        [],
    );

    const onChangeParticipant = useCallback(
        (ordinal: number) => async (item: ISelectOption<Participant> | null) => {
            setDuel((prev) => {
                const result = { ...prev };

                switch (ordinal) {
                    case 1: {
                        result.participant1.id = item ? item.value : null;
                        if (result.participant1.player) {
                            result.participant1.player.nick = item?.data?.player?.nick ?? item?.data?.team?.name ?? '';
                            result.participant1.player.color = item?.data?.player?.class?.avatar ?? '#ffffff';
                            result.participant1.player.external_link = item?.data?.player?.external_link ?? '';
                        }
                        break;
                    }
                    case 2: {
                        result.participant2.id = item ? item.value : null;
                        if (result.participant2.player) {
                            result.participant2.player.nick = item?.data?.player?.nick ?? item?.data?.team?.name ?? '';
                            result.participant2.player.color = item?.data?.player?.class?.avatar ?? '#ffffff';
                            result.participant2.player.external_link = item?.data?.player?.external_link ?? '';
                        }
                        break;
                    }
                }

                return result;
            });
        },
        [],
    );

    const onClickCancelHandler = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            setDuel(copyDuel(props.duel));
            setEditing(false);
        },
        [props.duel],
    );

    function delay(time: number) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const onClickResetHandler = useCallback(() => {
        onChangeScore(1)();
        onChangeScore(2)();
        duelRef.current.participant1.score = 0;
        duelRef.current.participant2.score = 0;
        onClickSaveHandler().then();
    }, []);

    const onClickSaveHandler = useCallback(
        async (event?: React.MouseEvent) => {
            event?.preventDefault();
            event?.stopPropagation();

            const t = blurAllTextarea();
            if (t) {
                await delay(300);
                onClickSaveHandler(event).then();

                return;
            }

            try {
                setSaving(true);

                const data = {
                    participant1: {
                        id: duelRef.current.participant1.id,
                        score: duelRef.current.participant1.score,
                    },
                    participant2: {
                        id: duelRef.current.participant2.id,
                        score: duelRef.current.participant2.score,
                    },
                };

                await API.Brackets.updateRelegationDuel(props.bracket.id, props.round.id, duel.id, data);
                await refreshTournament();
                setEditing(false);
            } catch (e) {
                console.error(e);
            } finally {
                setSaving(false);
            }
        },
        [props.bracket, props.round],
    );

    useEffect(() => {
        setDuel(copyDuel(props.duel));
    }, [props.duel]);

    useEffect(() => {
        duelRef.current = copyDuel(duel);
    }, [duel]);

    return (
        <div className={`${styles.duel} ${canEdit && styles.duelEditable}`}>
            <div className={styles.duelTitle}>{duel.code}</div>
            <div className={styles.duelItems} onClick={onClickDuelHandler}>
                <div className={styles.duelItemsContainer}>
                    <BracketRelegationDuelItem participant={duel.participant1} />
                    <BracketRelegationDuelItem participant={duel.participant2} />
                </div>
            </div>
            {isEditing && <div className={styles.editOverlay} onClick={blurAllTextarea} />}
            <div className={`${styles.edit} ${isEditing ? styles.editVisible : styles.editHidden}`}>
                {saving && <Loader className={styles.editLoader} size="sm" />}
                <BracketRelegationDuelItem
                    participant={duel.participant1}
                    canEdit={canEdit}
                    bestOf={props.bestOf}
                    opponentScore={duel.participant2.score ?? 0}
                    canEditParticipant={props.canEditParticipant}
                    bracketParticipants={props.bracketParticipants}
                    participantsOptions={props.participantsOptions}
                    onChangeParticipant={onChangeParticipant(1)}
                    onChangeScore={onChangeScore(1)}
                />
                <BracketRelegationDuelItem
                    participant={duel.participant2}
                    canEdit={canEdit}
                    bestOf={props.bestOf}
                    opponentScore={duel.participant1.score ?? 0}
                    canEditParticipant={props.canEditParticipant}
                    bracketParticipants={props.bracketParticipants}
                    participantsOptions={props.participantsOptions}
                    onChangeParticipant={onChangeParticipant(2)}
                    onChangeScore={onChangeScore(2)}
                />
                <div className={styles.actions}>
                    <button onClick={onClickCancelHandler}>Cancel</button>
                    <button onClick={onClickResetHandler}>Reset</button>
                    <button onClick={onClickSaveHandler}>Save</button>
                </div>
            </div>
        </div>
    );
};
