import React from 'react';

interface ISlotProps {
    children?: JSX.Element;
    showChildren?: boolean;
}

interface IRendererProps {
    children?: JSX.Element;
    childrenList: JSX.Element | JSX.Element[];
}

export const createSlot = () => {
    const Slot = ({ children, showChildren = true }: ISlotProps) => {
        return showChildren ? children ?? null : null;
    };

    Slot.Renderer = ({ childrenList, children }: IRendererProps): JSX.Element | null => {
        const slotted = React.Children.toArray(childrenList).find((child) => {
            return React.isValidElement(child) && child.type === Slot;
        });

        if (!slotted || !React.isValidElement(slotted)) {
            return children ?? null;
        }

        return React.cloneElement(slotted, { showChildren: true } as React.HTMLAttributes<HTMLElement>);
    };

    return Slot;
};
