import { Participant } from '~app/types/participant.type';
import { Player } from '~app/types/player.type';
import { Team } from '~app/types/team.type';

export const validatePlayer = (player: Player) => {
    return {
        ...player,
        playerIdError: !player.id || player.id === -1,
        classIdError: !player.class?.id || player.class?.id === -1,
        raceIdError: !player.race?.id || player.race?.id === -1,
    };
};

export const validatePlayers = (players: Player[]) => {
    return players.map(validatePlayer);
};

export const validateTeam = (team: Team, playersInTeam: number) => {
    return {
        ...team,
        players: team.players ? validatePlayers([...team.players]) : [],
        playersCountError: team.players?.length !== playersInTeam,
        teamIdError: !team.id,
    };
};

export const validateParticipant = (participant: Participant, playersInTeam?: number) => {
    return {
        id: participant.id ?? null,
        player: participant.player ? validatePlayer(participant.player) : null,
        team: participant.team ? validateTeam(participant.team, playersInTeam ?? -1) : null,
    };
};
