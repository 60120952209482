import React, { useCallback, useMemo } from 'react';

import { TeamName } from '~app/components/common/team-name';
import { EditableField } from '~app/components/editable-field/editable-field';
import { Select } from '~app/components/select';
import { useTournamentsContext } from '~app/contexts';
import { IDuelParticipant } from '~app/types/duel.interface';
import { Participant } from '~app/types/participant.type';
import { ISelectOption } from '~app/types/select-option.interface';
import { Team } from '~app/types/team.type';

import styles from './bracket-swiss-duel.module.scss';

interface IBracketSwissDuelItemProps {
    participant: IDuelParticipant;
    originalParticipant?: IDuelParticipant;
    bestOf?: number | null;
    opponentScore?: number;
    canEdit?: boolean;
    canEditParticipant?: boolean;
    participantsOptions?: ISelectOption<Participant>[];
    bracketParticipants?: number[];
    onChangeScore?: (value?: string) => Promise<void>;
    onChangeParticipant?: (item: ISelectOption<Participant> | null) => void;
    ordinal: number;
}

export const BracketSwissDuelItem = ({
    participant,
    originalParticipant,
    bestOf = null,
    opponentScore,
    canEdit,
    onChangeScore,
    canEditParticipant,
    participantsOptions,
    bracketParticipants,
    onChangeParticipant,
    ordinal,
}: IBracketSwissDuelItemProps): JSX.Element | null => {
    const { tournament, currentBracket } = useTournamentsContext();

    const isParticipantDisabled = useMemo(() => {
        return (
            !originalParticipant?.id && currentBracket?.participants === bracketParticipants?.filter((_) => !!_).length
        );
    }, [originalParticipant, currentBracket, bracketParticipants]);

    const scoreValidator = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const strValue = event.target.value.replace(/\D/, '');

            if (
                strValue.length > 0 &&
                bestOf !== null &&
                opponentScore !== undefined &&
                +strValue > bestOf - opponentScore
            ) {
                event.target.value = (bestOf - opponentScore).toString();
            } else {
                event.target.value = strValue;
            }
        },
        [bestOf, opponentScore],
    );

    return (
        <div
            className={`${styles.duelItem} ${participant.winner && participant.score !== null ? 'brackets-item--winner' : ''}`}>
            {canEditParticipant && !!participantsOptions && !!bracketParticipants && !!onChangeParticipant && (
                <div
                    style={{
                        flex: 1,
                    }}>
                    <Select
                        value={participant.id}
                        onChange={onChangeParticipant}
                        options={participantsOptions.filter(
                            (_) =>
                                participant.id === _.value ||
                                originalParticipant?.id === _.value ||
                                !bracketParticipants.includes(_.value),
                        )}
                        isFullWidth
                        isClearable
                        isDisabled={isParticipantDisabled}
                    />
                </div>
            )}
            {!canEditParticipant && participant.player?.nick && (
                <div
                    className="brackets-item__title"
                    style={{
                        color: participant.player?.color,
                        flex: canEdit === undefined ? 'unset' : 1,
                    }}>
                    {participant.player?.external_link && (
                        <a
                            href={participant.player?.external_link}
                            className="no-decor"
                            target="_blank"
                            rel="noreferrer">
                            {participant.player.nick}
                        </a>
                    )}
                    {!participant.player?.external_link && (participant.player?.nick ?? '')}
                </div>
            )}
            {!canEditParticipant && tournament && participant.id && participant.team?.name && (
                <TeamName
                    team={tournament.participants[participant.id].team as Team}
                    justifyContent={ordinal === 0 ? 'flex-end' : 'flex-start'}
                />
            )}
            {canEdit && !!onChangeScore && (
                <div
                    className="field"
                    style={{
                        width: '60px',
                        height: 'unset',
                        padding: 0,
                        position: 'relative',
                    }}>
                    <EditableField
                        name={participant.player?.nick ?? participant.team?.name ?? ''}
                        value={participant.score?.toString() ?? undefined}
                        defaultValue="-"
                        onSave={onChangeScore}
                        isDisabled={!participant}
                        onSavedDelay={0}
                        valueValidator={scoreValidator}
                    />
                </div>
            )}
            {!canEdit && <div className="group-item__score">{participant.score ?? '-'}</div>}
        </div>
    );
};
