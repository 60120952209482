import { ISelectOption } from '~app/types/select-option.interface';

export { request } from './request';

export const makeS3ResourceLink = (_filePath?: string): string | undefined => {
    if (!_filePath) {
        return undefined;
    }

    const filePath = _filePath.startsWith('/') ? _filePath.slice(1) : _filePath;

    return `https://idleague-storage-${process.env.REACT_APP_ENV}.s3.amazonaws.com/${filePath}`;
};

export const primers: { [key: string]: any } = {
    player_nick: (a: string) => a.toUpperCase(),
    team_name: (a: string) => a.toUpperCase(),
    class_name: (a: string) => a.toUpperCase(),
    world: (a: string) => a?.toUpperCase(),
    faction: (a: string) => a.toUpperCase(),
    status: (a: string) => a.toUpperCase(),
    wight: parseInt,
    win: parseInt,
    tie: parseInt,
    lose: parseInt,
    points: parseInt,
};

export const sort_by = function (field: string, reverse: boolean, primer: any) {
    const key = primer
        ? function (x: any) {
              return primer(x[field]);
          }
        : function (x: any) {
              return x[field];
          };

    return function (a: any, b: any) {
        const _a = key(a);
        const _b = key(b);

        // nulls sort after anything else
        if (_a === null || isNaN(_a)) {
            return 1;
        }
        if (_b === null || isNaN(_b)) {
            return -1;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (!reverse ? 1 : -1) * ((_a > _b) - (_b > _a));
    };
};

export const nl2br = (str: string, is_xhtml = false) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }

    const breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';

    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

export const arrayMoveItem = (arr: any[], oldIndex: number, newIndex: number) => {
    while (oldIndex < 0) {
        oldIndex += arr.length;
    }

    while (newIndex < 0) {
        newIndex += arr.length;
    }

    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

    return arr;
};

export const generateNumberSelectOptions = (length: number, min: number = 1): ISelectOption[] => {
    min = min < 1 ? 1 : min;

    return Array.from(Array(length)).map((_, i) => ({ label: (i + min).toString(), value: i + min }));
};

export const shuffle = (array: any) => {
    let currentIndex = array.length;
    let randomIndex;

    while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

export const cn = (...args: (string | null | undefined)[]) => args.filter((_) => !!_).join(' ');
