import moment from 'moment/moment';
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { PropsValue } from 'react-select';
import SimpleBar from 'simplebar-react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { Select } from '~app/components/select';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { SELECT_ORDER_OPTIONS, SELECT_STATUS_OPTIONS, TournamentStatus } from '~app/types/tournament.type';

interface ITournamentsFilterProps {
    style?: CSSProperties;
    myTournaments?: boolean;
}
export const TournamentsFilter = ({ style, myTournaments }: ITournamentsFilterProps = { style: {} }): JSX.Element => {
    const { search, pathname } = useLocation();
    const navigate = useNavigate();

    const { tournamentTypesSelectOptions } = useRootContext();
    const { filter, updateFilter } = useTournamentsContext();

    const [isOpenDatepicker, setOpenDatepicker] = useState<boolean>(false);

    const selectTypeOptions = useMemo<{ value: number; label: string; slug?: string }[]>(() => {
        return [{ value: -1, label: 'All' }, ...tournamentTypesSelectOptions];
    }, [tournamentTypesSelectOptions]);

    const handleDateBeginChange = useCallback((date: Date | null) => {
        updateFilter('dateBegin', date);
        setOpenDatepicker(false);
    }, []);

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilter('search', event.target.value);
    }, []);

    const handleStatusChange = useCallback((selectedOption: PropsValue<{ value: number; label: string }>) => {
        updateFilter('status', selectedOption);
    }, []);

    const handleTypeChange = useCallback(
        (selectedOption: PropsValue<{ value: number; label: string; slug?: string }>) => {
            updateFilter('type', selectedOption);
            const typeSlug = (selectedOption as unknown as { value: number; label: string; slug?: string } | undefined)
                ?.slug;
            const search = typeSlug ? `?type_slug=${typeSlug}` : '';
            navigate(`${pathname}${search}`, { replace: true });
        },
        [history, pathname],
    );

    const handleOrderChange = useCallback((selectedOption: PropsValue<{ value: number; label: string }>) => {
        updateFilter('order', selectedOption);
    }, []);

    useEffect(() => {
        const typeSlug = new URLSearchParams(search).get('type_slug');
        if (typeSlug) {
            const typeOption = selectTypeOptions.find((_) => _.slug === typeSlug);
            if (typeOption) {
                updateFilter('type', typeOption);
            }
        } else {
            updateFilter('type', { value: -1, label: 'All' });
        }
    }, [search, selectTypeOptions]);

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ height: '40px' }} />
            <div className="filter-bar-x-container" style={style}>
                <SimpleBar
                    className="filter"
                    ref={(ref) => {
                        if (ref?.contentWrapperEl) {
                            ref.contentWrapperEl.className = 'simplebar-content-wrapper filter-bar-x';
                        }
                    }}>
                    <div className="filter-main">
                        <div className="filter-wrap">
                            <div className="filter-inner">
                                <div className="filter-search">
                                    <div className="filter-search__text">find</div>
                                    <div className="filter-search__field">
                                        <input
                                            type="text"
                                            id="tournamentsearch-title"
                                            className="field field--sm"
                                            name="title"
                                            placeholder="enter name"
                                            value={filter.search}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>
                                <div className="filter-items">
                                    <div className="filter-item">
                                        <div className="filter-item__text prop">date:</div>
                                        <div className="filter-item__value">
                                            <div className={`datepicker-el ${filter.dateBegin ? 'selected' : ''}`}>
                                                <div style={{ height: 0 }}>
                                                    {isOpenDatepicker && (
                                                        <div
                                                            style={{
                                                                position: 'fixed',
                                                                top: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                left: 0,
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            onClick={() => {
                                                                setOpenDatepicker(false);
                                                            }}
                                                        />
                                                    )}
                                                    <DatePicker
                                                        customInput={<div style={{ minWidth: '40px' }} />}
                                                        selected={filter.dateBegin}
                                                        onChange={handleDateBeginChange}
                                                        open={isOpenDatepicker}>
                                                        <div className="datepicker--buttons">
                                                            {filter.dateBegin !== null && (
                                                                <span
                                                                    className="datepicker--button"
                                                                    onClick={() => handleDateBeginChange(null)}
                                                                    style={{
                                                                        color: '#DF0D14',
                                                                        fontWeight: 'bold',
                                                                    }}>
                                                                    Clear
                                                                </span>
                                                            )}
                                                        </div>
                                                    </DatePicker>
                                                </div>
                                                <div
                                                    className="datepicker-el__btn"
                                                    onClick={() => setOpenDatepicker(true)}>
                                                    <div className="datepicker-el__btn-text prop">
                                                        {filter.dateBegin
                                                            ? moment(filter.dateBegin).format('MM/DD/YYYY')
                                                            : 'choose'}
                                                    </div>
                                                    <div className="datepicker-el__btn-icon">
                                                        <svg className="icon">
                                                            <use href={`${MainSymbols}#image-chevron`}></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-item">
                                        <div className="filter-item__text prop">status:</div>
                                        <div className="filter-item__value">
                                            <div className="select">
                                                <div className="select-btn">
                                                    <Select
                                                        value={filter.status.value}
                                                        onChange={handleStatusChange}
                                                        options={SELECT_STATUS_OPTIONS.filter(
                                                            (_) =>
                                                                myTournaments ||
                                                                _.value !== TournamentStatus.STATUS_PENDING,
                                                        )}
                                                        onOpen={() => {
                                                            setOpenDatepicker(false);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-item">
                                        <div className="filter-item__text prop">type:</div>
                                        <div className="filter-item__value">
                                            <div className="select">
                                                <div className="select-btn">
                                                    <Select
                                                        value={filter.type.value}
                                                        onChange={handleTypeChange}
                                                        options={selectTypeOptions}
                                                        onOpen={() => {
                                                            setOpenDatepicker(false);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-item">
                                        <div className="filter-item__text prop">sort by:</div>
                                        <div className="filter-item__value">
                                            <div className="select">
                                                <div className="select-btn">
                                                    <Select
                                                        value={filter.order.value}
                                                        onChange={handleOrderChange}
                                                        options={SELECT_ORDER_OPTIONS}
                                                        onOpen={() => {
                                                            setOpenDatepicker(false);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};
