import { Player } from '~app/types/player.type';
import { Team } from '~app/types/team.type';
import { Tournament } from '~app/types/tournament.type';

export enum ChampionOrder {
    DATE,
    POOL,
    TYPE,
    STATUS,
    TITLE,
}

export const SELECT_CHAMPIONS_ORDER_OPTIONS = [
    { value: ChampionOrder.DATE, label: 'Date' },
    { value: ChampionOrder.POOL, label: 'Prize pool' },
    { value: ChampionOrder.TYPE, label: 'Type' },
    { value: ChampionOrder.STATUS, label: 'Status' },
    { value: ChampionOrder.TITLE, label: 'Title' },
];

export interface IChampion {
    player?: Partial<Player> | null;
    team?: Partial<Team> | null;
    tournament: Partial<Tournament>;
}
