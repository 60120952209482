import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { Image } from '~app/components/image';
import { TournamentParticipantPlayerCard } from '~app/components/tournament-participant-player-card';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { Bracket } from '~app/types/bracket.type';
import { Participant } from '~app/types/participant.type';
import { shuffle } from '~app/utils';
import API from '~app/utils/apis';

export interface ITournamentBracketRelegationParticipantsModalRef {
    open: (_bracket: Bracket) => Promise<void>;
}

export const TournamentBracketRelegationParticipants = forwardRef(
    (props: any, ref: React.Ref<ITournamentBracketRelegationParticipantsModalRef>): JSX.Element | null => {
        const { getAvatar } = useRootContext();
        const { refreshTournament, tournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [isLoading, setLoading] = useState<boolean>(true);
        const [isSaving, setSaving] = useState<boolean>(false);
        const [search, setSearch] = useState<string>('');

        const [bracket, setBracket] = useState<Bracket | null>(null);
        const [bracketParticipants, setBracketParticipants] = useState<number[]>([]);
        const [participants, setParticipants] = useState<Participant[]>([]);

        useImperativeHandle(ref, () => ({
            async open(_bracket: Bracket) {
                setOpen(true);
                setSaving(false);
                setLoading(true);
                setBracket(_bracket);

                try {
                    const currentBracketParticipants = await API.Brackets.getRelegationParticipants(_bracket.id);
                    setBracketParticipants(currentBracketParticipants);
                } finally {
                    setLoading(false);
                }
            },
        }));

        const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
        }, []);

        const updateBracketParticipants = useCallback(
            (participant: Participant) => () => {
                setBracketParticipants((prev) => {
                    const result = [...prev];
                    const participantId = participant.id;

                    if (!participantId) {
                        return result;
                    }

                    const index = result.findIndex((_) => _ === participantId);

                    if (index === -1) {
                        result.push(participantId);
                    } else {
                        result.splice(index, 1);
                    }

                    return result;
                });
            },
            [],
        );

        const selectOrUnselectAll = useCallback(() => {
            setBracketParticipants((prev) => {
                if (participants.length === prev.length) {
                    return [];
                }

                return participants.map((_) => _.id);
            });
        }, [participants]);

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
            setBracket(null);
            setBracketParticipants([]);
        }, []);

        const onClickSaveHandler = useCallback(async () => {
            if (bracket) {
                try {
                    setSaving(true);
                    await API.Brackets.updateRelegationFillFirstRound(bracket.id, shuffle([...bracketParticipants]));
                    await refreshTournament();

                    onClickCloseHandler();
                } finally {
                    setSaving(false);
                }
            }
        }, [bracketParticipants, bracket, onClickCloseHandler]);

        useEffect(() => {
            if (tournament) {
                setParticipants(Object.keys(tournament.participants).map((key) => tournament.participants[+key]));
            }
        }, [tournament]);

        if (!isOpen || bracket === null) {
            return null;
        }

        return (
            <ModalContainer
                isShowLoader={isSaving || isLoading}
                onClickClose={onClickCloseHandler}
                title={
                    <>
                        Edit participants <span>/ {bracket.title}</span>
                    </>
                }>
                <ModalContentSlot>
                    <>
                        {!isLoading && (
                            <>
                                <div className="content-block">
                                    <div className="control">
                                        <div className="control-side">
                                            <div className="prop">find player</div>
                                        </div>
                                        <div className="control-content">
                                            <div className="control-fields">
                                                <div className="control-field">
                                                    <input
                                                        type="text"
                                                        id="tournamentsearch-title"
                                                        className="field"
                                                        name="filterParticipants"
                                                        placeholder="nickname"
                                                        value={search}
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="content-block">
                                    <div className="control control--top">
                                        <div className="control-side">
                                            <div className="prop">Enabled count of participants</div>
                                        </div>
                                        <div className="control-content">
                                            <div className="control-fields">
                                                <div className="control-field">
                                                    <div className="control-info">
                                                        <div className="control-info__value">
                                                            <span
                                                                className={`js-attached-count ${bracketParticipants.length !== bracket.participants ? 'error' : ''}`}>
                                                                {bracketParticipants.length}
                                                            </span>{' '}
                                                            of {bracket.participants}
                                                        </div>
                                                        <div className="control-info__help">
                                                            <div className="text--sm js-attached-message">
                                                                {bracketParticipants.length > bracket.participants
                                                                    ? 'You need decrease count of participants'
                                                                    : ''}
                                                                {bracketParticipants.length < bracket.participants
                                                                    ? 'You need increase count of participants'
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="content-block">
                                    <div className="table table--control">
                                        <div className="table-content">
                                            <div className="table-inner">
                                                {participants.length === 0 && (
                                                    <p style={{ color: '#DF0D14' }}>
                                                        First add participants of tournament
                                                    </p>
                                                )}

                                                {participants.length > 0 && (
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <div className="checkbox">
                                                                        <label
                                                                            className="checkbox-label"
                                                                            htmlFor="insertParticipantsAll">
                                                                            <input
                                                                                className="checkbox-input"
                                                                                type="checkbox"
                                                                                id="insertParticipantsAll"
                                                                                checked={
                                                                                    participants.length ===
                                                                                    bracketParticipants.length
                                                                                }
                                                                                onChange={selectOrUnselectAll}
                                                                            />
                                                                            <div className="checkbox-content">
                                                                                <div className="checkbox-style"></div>
                                                                                <div className="checkbox-text h6">
                                                                                    select all
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </th>
                                                                {tournament?.type?.isTeamMode && <th>Team name</th>}
                                                                {tournament?.type?.isTeamMode && <th>Team members</th>}
                                                                {!tournament?.type?.isTeamMode && <th>Nickname</th>}
                                                                {!tournament?.type?.isTeamMode && <th>class</th>}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {participants.map((participant, index) => {
                                                                const avatar = getAvatar(participant.player);
                                                                const isAttached = bracketParticipants.includes(
                                                                    participant.id,
                                                                );

                                                                return (
                                                                    <tr
                                                                        key={participant.id}
                                                                        className={isAttached ? '' : 'disabled'}>
                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                            <div className="checkbox checkbox--toggler">
                                                                                <label className="checkbox-label">
                                                                                    <input
                                                                                        className="checkbox-input"
                                                                                        type="checkbox"
                                                                                        id={`tableColumn-${index}`}
                                                                                        checked={isAttached}
                                                                                        onChange={updateBracketParticipants(
                                                                                            participant,
                                                                                        )}
                                                                                    />

                                                                                    <div className="checkbox-content">
                                                                                        <div className="checkbox-style"></div>
                                                                                        <div
                                                                                            className="checkbox-text h6"
                                                                                            data-text-in="enabled"
                                                                                            data-text-out="disabled">
                                                                                            {isAttached
                                                                                                ? 'enabled'
                                                                                                : 'disabled'}
                                                                                        </div>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </td>
                                                                        {tournament?.type?.isTeamMode && (
                                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                                {participant.team?.name}
                                                                            </td>
                                                                        )}
                                                                        {tournament?.type?.isTeamMode && (
                                                                            <td>
                                                                                <div className="participants">
                                                                                    {participant.team?.players?.map(
                                                                                        (player) => (
                                                                                            <TournamentParticipantPlayerCard
                                                                                                player={player}
                                                                                                key={player.id}
                                                                                            />
                                                                                        ),
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                        )}
                                                                        {!tournament?.type?.isTeamMode && (
                                                                            <td>
                                                                                <div className="table-player">
                                                                                    <div className="table-player__avatar">
                                                                                        <Image
                                                                                            img={avatar.img}
                                                                                            webp={avatar.webp}
                                                                                            defaultImg={
                                                                                                avatar.defaultImg
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="table-player__name js-filter-by">
                                                                                        {participant.player?.nick}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        )}
                                                                        {!tournament?.type?.isTeamMode && (
                                                                            <td>{participant.player?.class?.name}</td>
                                                                        )}
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button
                            className="btn"
                            onClick={onClickSaveHandler}
                            disabled={isSaving || isLoading || bracketParticipants.length !== bracket.participants}>
                            save and continue
                        </button>
                        <button className="btn" onClick={onClickCloseHandler} disabled={isSaving || isLoading}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
