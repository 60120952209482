import React, { useCallback, useRef, useState } from 'react';

import { Select } from '~app/components/select';
import { useTournamentsContext } from '~app/contexts';
import { ITournamentMainInfoModalRef, TournamentMainInfoModal } from '~app/modals/tournament-main-info.modal';
import { SELECT_STATUS_OPTIONS } from '~app/types/tournament.type';
import API from '~app/utils/apis';

export const TournamentPanel = () => {
    const { tournament, refreshTournament } = useTournamentsContext();

    const [isSavingStatus, setSavingStatus] = useState<boolean>(false);
    const tournamentMainInfoModalRef = useRef<ITournamentMainInfoModalRef>(null);

    const openTournamentMainInfoModal = useCallback(() => {
        if (tournament !== null) {
            tournamentMainInfoModalRef.current?.open(tournament);
        }
    }, [tournamentMainInfoModalRef, tournament]);

    const onChangeStatusHandler = useCallback(
        async (selectedOption: { value: number; label: string } | null) => {
            if (!!tournament && tournament.status !== selectedOption?.value) {
                try {
                    setSavingStatus(true);
                    await API.Tournaments.setStatus(tournament?.slug, selectedOption?.value ?? 0);
                    await refreshTournament();
                } catch (e) {
                    console.error(e);
                } finally {
                    setSavingStatus(false);
                }
            }
        },
        [tournament],
    );

    if (tournament === null) {
        return null;
    }

    return (
        <>
            <div className="a-panel">
                <div className="container">
                    <div className="a-panel-container">
                        <div className="a-panel-left">
                            <button type="button" className="btn btn--sm" onClick={openTournamentMainInfoModal}>
                                edit main info
                            </button>
                        </div>
                        <div className="a-panel-right">
                            <div style={{ position: 'relative' }}>
                                <Select
                                    value={tournament.status}
                                    onChange={onChangeStatusHandler}
                                    options={SELECT_STATUS_OPTIONS.filter((_) => _.value >= 0)}
                                    showLoader={isSavingStatus}
                                    menuPlacement="top"
                                    controlStyle={{
                                        border: '2px solid #333333',
                                        outline: 0,
                                        backgroundColor: '#333333',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TournamentMainInfoModal ref={tournamentMainInfoModalRef} />
        </>
    );
};
