import { BracketColumn } from '~app/types/bracket-column.type';
import { IBracketGroup } from '~app/types/bracket-group.interface';
import { IBracketRound } from '~app/types/bracket-round.interface';
import { IGroupStanding } from '~app/types/group-standing.interface';
import { IStanding } from '~app/types/standing.interface';

export enum BracketType {
    TABLE = 'TABLE',
    RELEGATION = 'RELEGATION',
    OLD_GROUP = 'OLD_GROUP',
    SWISS = 'SWISS',
    GROUP = 'GROUP',
}

export type Bracket = {
    best_of: number | null;
    with_draw: number | null;
    type: BracketType;
    editable: number;
    editable_participants: number;
    editable_scores: number;
    group_count: number | null;
    id: number;
    ordinal: number | null;
    participants: number;
    round_count: number;
    second_defeat: number;
    third_place: number;
    title: string;
    tournament_id: number;
    created_at: Date;
    updated_at: Date;
    rounds: IBracketRound[];
    groups: IBracketGroup[];
    standings: IStanding[] | IGroupStanding[];
    roundsMain?: {
        title: string;
        rounds: IBracketRound[];
    };
    roundsGrand?: {
        title: string;
        rounds: IBracketRound[];
    };
    roundsDefeat: {
        title: string;
        rounds: IBracketRound[];
    } | null;
    headers: {
        id: number;
        order: number;
        title: string;
    }[];
    table_columns?: BracketColumn[];
    rows: {
        id: number;
        participant_id: number;
        columns: { column_id: string; value: string }[];
        wight: number;
    }[];
};
