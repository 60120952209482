import React, { useMemo } from 'react';

import { useTournamentsContext } from '~app/contexts';

export const TournamentBracketOldGroup = (): JSX.Element | null => {
    const { currentBracket, currentRoundId, tournament } = useTournamentsContext();

    const round = useMemo(() => {
        if (!currentBracket || !currentRoundId) {
            return null;
        }

        return currentBracket.rounds.find((_) => _.ordinal === +currentRoundId) || null;
    }, [currentBracket, currentRoundId]);

    if (!currentBracket || !tournament) {
        return null;
    }

    return (
        <div className="container--sm">
            <div className="section-inner">
                <div className="container--sm">
                    <div className="tabs active">
                        <div className="tabs-item">
                            <div className="filter-content filter-content--sm">
                                <div
                                    className="groups"
                                    style={{ display: 'flex', flexWrap: 'wrap', margin: '8px -15px' }}>
                                    {round?.groups.map((group) => {
                                        return (
                                            <div
                                                className="group-wrap"
                                                style={{ flex: 1, minWidth: '50%' }}
                                                key={group.order}>
                                                <div className="group group--admin" style={{ margin: '0 30px' }}>
                                                    <div className="group-title prop">{group.title}</div>
                                                    <div className="group-items">
                                                        {group.duels.map((duel) => {
                                                            return (
                                                                <div className="group-item" key={duel.id}>
                                                                    <div className="group-item__inner">
                                                                        <div className="group-item__num">
                                                                            {duel.order}
                                                                        </div>
                                                                        <div className="group-item__results">
                                                                            <div
                                                                                className={`group-item__result ${
                                                                                    duel.participant1.winner &&
                                                                                    duel.participant1.score !== null
                                                                                        ? 'brackets-item--winner'
                                                                                        : ''
                                                                                }`}>
                                                                                <div
                                                                                    className="brackets-item__title"
                                                                                    style={{
                                                                                        color:
                                                                                            duel.participant1.player
                                                                                                ?.color ?? '#fff',
                                                                                    }}>
                                                                                    {duel.participant1.player
                                                                                        ?.external_link && (
                                                                                        <a
                                                                                            href={
                                                                                                duel.participant1.player
                                                                                                    ?.external_link
                                                                                            }
                                                                                            className="no-decor"
                                                                                            target="_blank"
                                                                                            rel="noreferrer">
                                                                                            {
                                                                                                duel.participant1.player
                                                                                                    ?.nick
                                                                                            }
                                                                                        </a>
                                                                                    )}
                                                                                    {!duel.participant1.player
                                                                                        ?.external_link &&
                                                                                        duel.participant1.player?.nick}
                                                                                </div>
                                                                                <div className="group-item__score">
                                                                                    {duel.participant1.score || '-'}
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className={`group-item__result ${
                                                                                    duel.participant2.winner &&
                                                                                    duel.participant2.score !== null
                                                                                        ? 'brackets-item--winner'
                                                                                        : ''
                                                                                }`}>
                                                                                <div
                                                                                    className="brackets-item__title"
                                                                                    style={{
                                                                                        color:
                                                                                            duel.participant2.player
                                                                                                ?.color ?? '#fff',
                                                                                    }}>
                                                                                    {duel.participant2.player
                                                                                        ?.external_link && (
                                                                                        <a
                                                                                            href={
                                                                                                duel.participant2.player
                                                                                                    ?.external_link
                                                                                            }
                                                                                            className="no-decor"
                                                                                            target="_blank"
                                                                                            rel="noreferrer">
                                                                                            {
                                                                                                duel.participant2.player
                                                                                                    ?.nick
                                                                                            }
                                                                                        </a>
                                                                                    )}
                                                                                    {!duel.participant2.player
                                                                                        ?.external_link &&
                                                                                        duel.participant2.player?.nick}
                                                                                </div>
                                                                                <div className="group-item__score">
                                                                                    {duel.participant2.score || '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
