import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';

export interface IConfirmationModalRef {
    open: (title: string | JSX.Element, description: string | JSX.Element, onConfirm: () => Promise<void>) => void;
}

export interface IConfirmationModalProps {}

export const ConfirmationModal = forwardRef(
    (props: IConfirmationModalProps, ref: React.Ref<IConfirmationModalRef>): JSX.Element | null => {
        const [isOpen, setOpen] = useState<boolean>(false);
        const [isProcessing, setProcessing] = useState<boolean>(false);
        const [title, setTitle] = useState<string | JSX.Element>('');
        const [description, setDescription] = useState<string | JSX.Element>('');
        const [onConfirm, setOnConfirm] = useState<(() => Promise<void>) | null>(null);

        useImperativeHandle(ref, () => ({
            open(title: string | JSX.Element, description: string | JSX.Element, onConfirm: () => Promise<void>) {
                setOpen(true);
                setProcessing(false);
                setTitle(title);
                setDescription(description);
                setOnConfirm(() => onConfirm);
            },
        }));

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
            setTitle('');
            setDescription('');
            setOnConfirm(null);
        }, []);

        const onClickConfirmHandler = useCallback(async () => {
            if (onConfirm === null) {
                return;
            }

            try {
                setProcessing(true);
                await onConfirm();
                onClickCloseHandler();
            } finally {
                setProcessing(false);
            }
        }, [onConfirm]);

        if (!isOpen) {
            return null;
        }

        return (
            <ModalContainer onClickClose={onClickCloseHandler} title={title} isShowLoader={isProcessing}>
                <ModalContentSlot>
                    <>{description}</>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button type="button" className="btn" onClick={onClickConfirmHandler}>
                            Confirm
                        </button>
                        <button className="btn" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
