import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { useRootContext } from '~app/contexts';
import { Tournament, TOURNAMENT_STATUS_DICT } from '~app/types/tournament.type';
import { makeS3ResourceLink } from '~app/utils';

interface ITournamentHeadProps {
    tournament: Tournament;
}
export const TournamentHead = ({ tournament }: ITournamentHeadProps): JSX.Element => {
    const { showLoader } = useRootContext();

    return (
        <section className="section section--head">
            <div className="section-bg">
                <div className="section-bg__overlay">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div
                    className="section-bg__img"
                    style={{ backgroundImage: `url(${makeS3ResourceLink(tournament.bg_image)})` }}
                />
            </div>
            <div className="section-inner">
                <div className="container">
                    <div className="section-back">
                        <Link className="link-back" to="/tournaments" onClick={showLoader}>
                            <svg className="icon">
                                <use href={`${MainSymbols}#image-chevron`}></use>
                            </svg>
                            All tournaments
                        </Link>
                    </div>
                    <div className="section-title">
                        <h1 className="h2" id="tour-title">
                            {tournament.title}
                        </h1>
                    </div>
                    <SimpleBar className="infos">
                        <div className="infos-inner">
                            <div className="info">
                                <div className="info-icon">
                                    <svg className="icon">
                                        <use href={`${MainSymbols}#image-shield`}></use>
                                    </svg>
                                </div>
                                <div className="icon-content">
                                    <div className="info-value h6">{tournament.type.name}</div>
                                    <div className="info-prop prop">type</div>
                                </div>
                            </div>
                            <div className="info">
                                <div className="icon-content">
                                    <div className="info-value h6">{moment(tournament.date).format('D MMMM YYYY')}</div>
                                    <div className="info-prop prop">date</div>
                                </div>
                            </div>
                            <div className="info">
                                <div className="icon-content">
                                    <div className="info-value h6">{tournament.time.substring(0, 5)}</div>
                                    <div className="info-prop prop">time</div>
                                </div>
                            </div>
                            <div className="info">
                                <div className="icon-content">
                                    <div className="info-value h6">{tournament.language_name}</div>
                                    <div className="info-prop prop">language</div>
                                </div>
                            </div>
                            <div className="info">
                                <div className="icon-content">
                                    <div className="info-value h6">{tournament.organizer_name}</div>
                                    <div className="info-prop prop">organizer</div>
                                </div>
                            </div>
                            <div className="info">
                                <div className="icon-content">
                                    <div className="info-value h6">{TOURNAMENT_STATUS_DICT.get(tournament.status)}</div>
                                    <div className="info-prop prop">status</div>
                                </div>
                            </div>
                        </div>
                    </SimpleBar>
                </div>
            </div>
        </section>
    );
};
