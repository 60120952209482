import React, { useCallback, useMemo } from 'react';

import { TeamName } from '~app/components/common/team-name';
import { EditableField } from '~app/components/editable-field/editable-field';
import { useTournamentsContext } from '~app/contexts';
import { IBracketGroupDuel } from '~app/types/bracket-group-duel.interface';

import styles from './bracket-group-duel.module.scss';

interface RelegationBracketDuelItemProps {
    tournamentParticipantId: number | null;
    duelParticipantId: number | null;
    duel: IBracketGroupDuel;
    bestOf?: number | null;
    opponentScore?: number;
    canEdit?: boolean;
    onChangeScore?: (value?: string) => Promise<void>;
}

export const BracketGroupDuelItem = ({
    tournamentParticipantId,
    duelParticipantId,
    duel,
    bestOf = null,
    opponentScore,
    canEdit,
    onChangeScore,
}: RelegationBracketDuelItemProps): JSX.Element | null => {
    const { tournament } = useTournamentsContext();

    const participant = useMemo(() => {
        if (!tournament) {
            return { winner: false, score: 0 };
        }

        const _participant = !tournament.participants
            ? null
            : Object.values(tournament.participants).find((_) => _.id === tournamentParticipantId);

        return {
            ..._participant,
            winner: duel.winner_id === duelParticipantId,
            score: duel.participant_one_id === duelParticipantId ? duel.score_one : duel.score_two,
        };
    }, [tournamentParticipantId, tournament, duel]);

    const scoreValidator = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const strValue = event.target.value.replace(/\D/, '');

            if (
                strValue.length > 0 &&
                bestOf !== null &&
                opponentScore !== undefined &&
                +strValue > bestOf - opponentScore
            ) {
                event.target.value = (bestOf - opponentScore).toString();
            } else {
                event.target.value = strValue;
            }
        },
        [bestOf, opponentScore],
    );

    return (
        <div
            className={`${styles.duelItem} ${participant.winner && participant.score !== null ? 'brackets-item--winner' : ''} ${duel.draw === 1 ? styles.draw : ''}`}>
            <div
                className="brackets-item__title"
                style={{
                    color: participant.player?.class?.avatar,
                    flex: 1,
                }}>
                {participant.player?.external_link && (
                    <a href={participant.player?.external_link} className="no-decor" target="_blank" rel="noreferrer">
                        {participant.player?.nick}
                    </a>
                )}
                {!participant.player?.external_link && participant.player?.nick}
                {participant.team && <TeamName team={participant.team} />}
            </div>
            {canEdit && !!onChangeScore && (
                <div
                    className="field"
                    style={{
                        width: '60px',
                        padding: 0,
                        position: 'relative',
                    }}>
                    <EditableField
                        name={participant.player?.nick || participant.team?.name || ''}
                        value={participant.score?.toString() ?? undefined}
                        defaultValue="-"
                        onSave={onChangeScore}
                        isDisabled={!participant}
                        onSavedDelay={0}
                        valueValidator={scoreValidator}
                    />
                </div>
            )}
            {!canEdit && <div className="group-item__score">{participant.score ?? '-'}</div>}
        </div>
    );
};
