import React, { useCallback, useMemo, useRef } from 'react';

import EditIcon from '~app/assets/sprites/edit.svg';
import TrashIcon from '~app/assets/sprites/trash.svg';
import { Image } from '~app/components/image';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { ConfirmationModal, IConfirmationModalRef } from '~app/modals/confirmation.modal';
import {
    ITournamentParticipantModalRef,
    TournamentParticipantModal,
} from '~app/modals/tournament-participants/tournament-participant.modal';
import { Player } from '~app/types/player.type';
import { cn } from '~app/utils';
import API from '~app/utils/apis';

import S from './tournament-participant-player-card.module.scss';

type TournamentPlayerParticipantProps = {
    player: Player;
    participantId?: number;
    onDelete?: (playerId: number) => void;
};

export const TournamentParticipantPlayerCard = ({
    player,
    participantId,
    onDelete,
}: TournamentPlayerParticipantProps): JSX.Element => {
    const { getAvatar } = useRootContext();
    const { tournament, refreshTournament, isAllowedEditMode } = useTournamentsContext();

    const avatar = useMemo(() => getAvatar(player), [player]);

    const confirmationModalRef = useRef<IConfirmationModalRef>(null);
    const tournamentParticipantModalRef = useRef<ITournamentParticipantModalRef>(null);

    const onClickDeleteHandler = useCallback(() => {
        if (tournament === null || (!participantId && !onDelete) || !isAllowedEditMode) {
            return;
        }

        const title = (
            <>
                Delete {tournament.type.isTeamMode ? 'player' : 'participant'}: <span>{player.nick}</span>
            </>
        );
        const description = (
            <>
                Please confirm the deletion of the <span>{player.nick}</span>{' '}
                {tournament.type.isTeamMode ? 'from team' : 'participant'}
            </>
        );

        const deleteParticipantPlayer = async () => {
            if (participantId) {
                await API.Tournaments.deleteTournamentParticipant(tournament.slug, participantId);
                await refreshTournament();
            }
        };

        const deleteTeamPlayer = async () => {
            if (onDelete) {
                onDelete(player.id);
            }
        };

        confirmationModalRef.current?.open(
            title,
            description,
            tournament.type.isTeamMode ? deleteTeamPlayer : deleteParticipantPlayer,
        );
    }, [tournament, isAllowedEditMode, player, participantId, onDelete]);

    const onClickEditHandler = useCallback(() => {
        if (!participantId || !isAllowedEditMode) {
            return;
        }

        tournamentParticipantModalRef.current?.open({ id: participantId, player });
    }, [isAllowedEditMode, participantId, player]);

    return (
        <div className={S.participant}>
            <div className={S.participantAvatar}>
                <Image img={avatar.img} webp={avatar.webp} defaultImg={avatar.defaultImg} />
            </div>
            <div className={S.participantContent}>
                <div className={cn(S.participantTitle, 'h6')} style={{ color: player.class?.avatar }}>
                    {player.external_link && (
                        <a
                            href={player.external_link}
                            style={{ color: player.class?.avatar }}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(event) => event.stopPropagation()}>
                            {player.nick}
                        </a>
                    )}
                    {!player.external_link && player.nick}
                </div>
                <div className={S.participantProp}>{player.class?.name}</div>
            </div>
            {tournament !== null && (!!participantId || !!onDelete) && isAllowedEditMode && (
                <div className={S.participantControls}>
                    <div className={S.icon} onClick={onClickDeleteHandler}>
                        <img src={TrashIcon} alt="" />
                    </div>
                    {!!participantId && (
                        <div className={S.icon} onClick={onClickEditHandler}>
                            <img src={EditIcon} alt=" " />
                        </div>
                    )}
                </div>
            )}

            <ConfirmationModal ref={confirmationModalRef} />
            <TournamentParticipantModal ref={tournamentParticipantModalRef} />
        </div>
    );
};
