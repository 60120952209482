import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { createFilter, CSSObjectWithLabel, GroupBase, OptionProps } from 'react-select';
import ReactSelect from 'react-select/base';
import CreatableReactSelect from 'react-select/creatable';

import { Loader } from '~app/components/loader/loader';

interface ISelectProps {
    value: number | null;
    onChange: (selectedOption: { value: number; label: string } | null) => void;
    options: { value: number; label: string }[];
    onOpen?: () => void;
    isFullWidth?: boolean;
    width?: string;
    placeholder?: string;
    isDisabled?: boolean;
    components?: { [key: string]: (props: any) => JSX.Element };
    isSearchable?: boolean;
    isClearable?: boolean;
    onInputChange?: (value: string) => void;
    onCreateOption?: (value: string) => void;
    showLoader?: boolean;
    menuPlacement?: 'bottom' | 'top';
    controlStyle?: CSSProperties;
}

export const Select = ({
    value,
    onChange,
    options,
    onOpen,
    isFullWidth,
    width,
    placeholder,
    isDisabled,
    isSearchable,
    onInputChange,
    isClearable,
    onCreateOption,
    showLoader,
    menuPlacement = 'bottom',
    controlStyle,
}: ISelectProps): JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [searchableValue, setSearchableValue] = useState<string>('');

    const selectedValue = useMemo(() => {
        return options.find((_) => _.value === value) ?? null;
    }, [value, options]);

    const open = useCallback(() => {
        setOpen(true);

        if (onOpen) {
            onOpen();
        }
    }, []);

    const close = useCallback(() => {
        setOpen(false);
    }, []);

    const selectProps = useMemo(() => {
        return {
            isDisabled,
            placeholder,
            styles: {
                container: (baseStyle: CSSObjectWithLabel) =>
                    ({
                        ...baseStyle,
                        ...(isFullWidth && { width: width ?? '100%' }),
                    }) as CSSObjectWithLabel,
                control: (baseStyles: CSSObjectWithLabel) =>
                    ({
                        ...baseStyles,
                        border: 'none',
                        background: 'none',
                        height: '28px',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        color: '#F2F2F2',
                        flexWrap: 'nowrap',

                        ...(isFullWidth && {
                            border: '1px solid #3C3C3C',
                            outline: 0,
                            backgroundColor: '#3C3C3C',
                        }),

                        ...(controlStyle && { ...controlStyle }),

                        svg: {
                            width: '22px',
                            height: '22px',
                            marginTop: '-3px',
                            path: {
                                fill: '#DF0D14',
                            },
                        },
                        '&:hover': {
                            color: '#DF0D14',
                            svg: {
                                path: {
                                    fill: '#F2F2F2',
                                },
                            },
                        },
                    }) as CSSObjectWithLabel,
                dropdownIndicator: (baseStyle: CSSObjectWithLabel) =>
                    ({
                        ...baseStyle,
                        padding: '0',
                    }) as CSSObjectWithLabel,
                indicatorSeparator: () =>
                    ({
                        display: 'none',
                    }) as CSSObjectWithLabel,
                menu: (baseStyle: CSSObjectWithLabel) =>
                    ({
                        ...baseStyle,
                        zIndex: 100,
                        padding: 0,
                        width: isFullWidth ? '100%' : 'max-content',
                        ...(menuPlacement === 'bottom' && { top: '20px' }),
                        ...(menuPlacement === 'top' && {
                            top: 'unset',
                            bottom: '24px',
                            right: 0,
                        }),
                    }) as CSSObjectWithLabel,
                menuList: (baseStyle: CSSObjectWithLabel) =>
                    ({
                        ...baseStyle,
                        width: isFullWidth ? '100%' : 'max-content',
                        padding: '5px 10px',
                        maxHeight: '120px',
                    }) as CSSObjectWithLabel,
                option: (
                    baseStyle: CSSObjectWithLabel,
                    state: OptionProps<
                        { value: number; label: string },
                        false,
                        GroupBase<{
                            value: number;
                            label: string;
                        }>
                    >,
                ) =>
                    ({
                        ...baseStyle,
                        color: state.isSelected ? '#DF0D14' : '#1A1A1A',
                        background: 'none',
                        padding: '5px 0',
                        textTransform: 'uppercase',
                        lineHeight: 1,
                        fontFamily: 'RobotoCondensed-Bold',
                        margin: 0,
                        whiteSpace: 'nowrap',
                        transition: '0.3s ease-in-out',
                        cursor: 'pointer',
                        fontSize: '14px',
                        '&:hover': {
                            color: '#DF0D14 !important',
                        },
                    }) as CSSObjectWithLabel,
                singleValue: (baseStyles: CSSObjectWithLabel) =>
                    ({
                        ...baseStyles,
                        color: 'inherit',
                        textTransform: 'uppercase',
                        lineHeight: 1,
                        fontSize: '12px',
                        fontFamily: 'RobotoCondensed-Bold',
                        maxWidth: '140px',
                    }) as CSSObjectWithLabel,
                input: (baseStyles: CSSObjectWithLabel) =>
                    ({
                        ...baseStyles,
                        color: '#fff',
                    }) as CSSObjectWithLabel,
            },
            value: selectedValue,
            onChange,
            options,
            inputValue: searchableValue,
            onInputChange: (value: string) => {
                if (isSearchable) {
                    setSearchableValue(value);
                }
                // if (onInputChange) {
                //     onInputChange(value);
                // }
            },
            menuIsOpen: isOpen,
            onMenuClose: close,
            onMenuOpen: open,
            isSearchable,
            filterOption: createFilter({
                matchFrom: 'any',
                stringify: (option) => `${option.label}`,
            }),
        };
    }, [
        isDisabled,
        selectedValue,
        onChange,
        options,
        isSearchable,
        searchableValue,
        onInputChange,
        isOpen,
        close,
        open,
    ]);

    if (isClearable) {
        return (
            <>
                {showLoader && <Loader size="sm" />}
                <CreatableReactSelect
                    {...selectProps}
                    isClearable={isClearable}
                    createOptionPosition="first"
                    onCreateOption={onCreateOption}
                />
            </>
        );
    }

    return (
        <>
            {showLoader && <Loader size="sm" />}
            <ReactSelect {...selectProps} />
        </>
    );
};
