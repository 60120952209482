import React from 'react';

import { TournamentBracketGroupTable } from '~app/components/tournament-bracket-group/tournament-bracket-group-table';
import { useTournamentsContext } from '~app/contexts';

export const TournamentBracketGroupStandings = (): JSX.Element | null => {
    const { currentBracket, tournament } = useTournamentsContext();

    if (!currentBracket || !tournament) {
        return null;
    }

    return (
        <div className="container--sm">
            <div className="section-inner">
                <div className="container--sm">
                    <div className="tabs active">
                        <div className="tabs-item">
                            <div className="filter-content filter-content--sm">
                                <div
                                    className="groups"
                                    style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                                    {currentBracket?.groups.map((group) => {
                                        return (
                                            <TournamentBracketGroupTable
                                                key={group.id}
                                                group={group}
                                                canEdit={false}
                                                bracket={currentBracket}
                                                tournament={tournament}
                                                isShowGroupName
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
