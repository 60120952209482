import React, { useCallback, useState } from 'react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { LANGUAGES_LIST } from '~i18n/constants';
import i18n from '~i18n/index';

export const LanguageSwitch = (): JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const toggleLanguageList = useCallback(() => {
        setOpen(!isOpen);
    }, [isOpen]);

    const onSelectLanguage = useCallback((value: string) => {
        const lg = LANGUAGES_LIST.includes(value) ? value : 'en';
        i18n.changeLanguage(lg).then();
        setOpen(false);
    }, []);

    return (
        <div className="header-lang">
            <div className={`dropdown ${isOpen ? 'active' : ''}`}>
                <div className="dropdown-result" onClick={toggleLanguageList}>
                    <div className="dropdown-result__text">{i18n.language}</div>
                    <div className="dropdown-result__icon">
                        <svg className="icon">
                            <use href={`${MainSymbols}#image-chevron`}></use>
                        </svg>
                    </div>
                </div>
                {isOpen && (
                    <div className="dropdown-box">
                        <div className="close">
                            <div className="close-inner" onClick={toggleLanguageList}>
                                <div className="close-icon">
                                    <svg className="icon">
                                        <use href={`${MainSymbols}#image-chevron`}></use>
                                    </svg>
                                </div>
                                <div className="close-text">close</div>
                            </div>
                        </div>
                        <div className="dropdown-box__inner">
                            <ul className="dropdown-items">
                                {LANGUAGES_LIST.map((lang) => {
                                    return (
                                        <li className="dropdown-item" key={lang} style={{ cursor: 'pointer' }}>
                                            <a
                                                className={`dropdown-link ${i18n.language.toLowerCase() === lang.toLowerCase() ? 'active' : ''}`}
                                                onClick={() => onSelectLanguage(lang)}>
                                                {lang}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
