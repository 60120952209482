import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import LogoBig from '~app/assets/img/logo-big.png';
import MainSymbol from '~app/assets/sprites/main.symbol.svg';
import { useRootContext } from '~app/contexts';
import { ILoginModalRef, LoginModal } from '~app/modals/login.modal';
import { ITournamentMainInfoModalRef, TournamentMainInfoModal } from '~app/modals/tournament-main-info.modal';
import { UserRole } from '~app/types/user.interface';
import { makeS3ResourceLink } from '~app/utils';
import { useOutsideAlerter } from '~app/utils/hooks/use-outside.hook';

export const HeaderPerson = (): JSX.Element => {
    const { isGuest, profile, logout } = useRootContext();
    const loginModalRef = useRef<ILoginModalRef>(null);
    const tournamentMainInfoModalRef = useRef<ITournamentMainInfoModalRef>(null);
    const [isOpenDropDownMenu, setOpenDropDownMenu] = useState<boolean>(false);

    const selfRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(selfRef, () => setOpenDropDownMenu(false));

    const permissions = useMemo(() => {
        return {
            adminAccess: profile && [UserRole.ROOT, UserRole.ADMIN, UserRole.ORGANIZER].includes(profile.role),
            canCreateTournament: profile && [UserRole.ROOT, UserRole.ADMIN, UserRole.ORGANIZER].includes(profile.role),
            canCreateMedea: false, // profile && [UserRole.ROOT].includes(profile.role),
            canEditUsers: false, // profile && [UserRole.ROOT].includes(profile.role),
            profile: false, // profile && [UserRole.ROOT].includes(profile.role),
        };
    }, [profile]);

    const openLoginModal = useCallback(() => {
        loginModalRef.current?.open();
        setOpenDropDownMenu(false);
    }, [loginModalRef]);

    const openTournamentMainInfoModal = useCallback(() => {
        tournamentMainInfoModalRef.current?.open();
        setOpenDropDownMenu(false);
    }, [tournamentMainInfoModalRef]);

    const toggleDropdownList = useCallback(() => {
        setOpenDropDownMenu(!isOpenDropDownMenu);
    }, [isOpenDropDownMenu]);

    return (
        <div className="header-personal">
            {isGuest && (
                <a className="header-menu__link" onClick={openLoginModal}>
                    Login
                </a>
            )}

            {profile && (
                <div className={`dropdown ${isOpenDropDownMenu ? 'active' : ''}`} ref={selfRef}>
                    <div className="dropdown-result" onClick={toggleDropdownList}>
                        <div className="dropdown-result__media">
                            <img
                                className="dropdown-result__bg"
                                src={profile.avatar ? makeS3ResourceLink(profile.avatar) : LogoBig}
                                alt=""
                            />
                        </div>
                        <div className="dropdown-result__icon">
                            <svg className="icon">
                                <use href={`${MainSymbol}#image-chevron`}></use>
                            </svg>
                        </div>
                    </div>
                    <div className="dropdown-box bottom" style={{ position: 'absolute', right: 0 }}>
                        <div className="close" onClick={toggleDropdownList}>
                            <div className="close-inner">
                                <div className="close-icon">
                                    <svg className="icon">
                                        <use href={`${MainSymbol}#image-chevron`}></use>
                                    </svg>
                                </div>
                                <div className="close-text">close</div>
                            </div>
                        </div>
                        <div className="dropdown-box__inner">
                            <ul className="dropdown-items">
                                <li className="dropdown-item" style={{ cursor: 'pointer' }}>
                                    <a className="dropdown-link">{profile.email}</a>
                                </li>
                            </ul>

                            {permissions.adminAccess && (
                                <ul className="dropdown-items">
                                    {permissions.canCreateTournament && (
                                        <li className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <a className="dropdown-link" onClick={openTournamentMainInfoModal}>
                                                Create tournament
                                            </a>
                                        </li>
                                    )}

                                    {permissions.canCreateMedea && (
                                        <li className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <Link
                                                className="dropdown-link"
                                                to="media/create"
                                                onClick={() => setOpenDropDownMenu(false)}>
                                                Create media
                                            </Link>
                                        </li>
                                    )}

                                    {permissions.canCreateTournament && (
                                        <li className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <Link
                                                className="dropdown-link"
                                                to="/profile/tournaments"
                                                onClick={() => setOpenDropDownMenu(false)}>
                                                my tournaments
                                            </Link>
                                        </li>
                                    )}

                                    {permissions.canEditUsers && (
                                        <li className="dropdown-item" style={{ cursor: 'pointer' }}>
                                            <Link
                                                className="dropdown-link"
                                                to="/cabinet"
                                                onClick={() => setOpenDropDownMenu(false)}>
                                                edit users
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            )}
                            <ul className="dropdown-items">
                                {permissions.profile && (
                                    <li className="dropdown-item" style={{ cursor: 'pointer' }}>
                                        <Link
                                            className="dropdown-link"
                                            to="/cabinet/profile"
                                            onClick={() => setOpenDropDownMenu(false)}>
                                            settings
                                        </Link>
                                    </li>
                                )}
                                <li className="dropdown-item" style={{ cursor: 'pointer' }}>
                                    <a className="dropdown-link" onClick={logout}>
                                        logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            <LoginModal ref={loginModalRef} />
            <TournamentMainInfoModal ref={tournamentMainInfoModalRef} />
        </div>
    );
};
