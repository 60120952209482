import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TournamentBrackets } from '~app/components/tournament-brackets';
import { TournamentHead } from '~app/components/tournament-head';
import { TournamentMedia } from '~app/components/tournament-media';
import { TournamentNavigation } from '~app/components/tournament-navigation';
import { TournamentPanel } from '~app/components/tournament-panel';
import { TournamentParticipants } from '~app/components/tournament-participants';
import { TournamentPrizes } from '~app/components/tournament-prizes';
import { TournamentRules } from '~app/components/tournament-rules';
import { TournamentSchedule } from '~app/components/tournament-schedule';
import { useRootContext } from '~app/contexts';
import { useTournamentsContext } from '~app/contexts/TournamentsContext';
import { UserRole } from '~app/types/user.interface';

export const TournamentPage = (): JSX.Element | null => {
    const { slug, tournament_tab } = useParams();
    const navigate = useNavigate();

    const { hideLoader, profile } = useRootContext();
    const { tournament, fetchTournament, clearTournamentsData } = useTournamentsContext();

    const [tab, setTab] = useState<string>('brackets');

    const canEdit = useMemo(() => {
        if (profile === null || tournament === null) {
            return false;
        }

        switch (profile.role) {
            case UserRole.ROOT: {
                return true;
            }
            case UserRole.ADMIN: {
                return tournament.created_by === profile.id;
            }
            case UserRole.ORGANIZER: {
                return tournament.organizer_id === profile.id;
            }
            default: {
                return false;
            }
        }
    }, [profile, tournament]);

    useEffect(() => {
        return () => {
            clearTournamentsData();
        };
    }, []);

    useEffect(() => {
        if (!slug) {
            navigate('/tournaments');

            return;
        }

        fetchTournament(slug).then();
    }, [slug]);

    useEffect(() => {
        if (tournament !== null) {
            hideLoader();
        }
    }, [tournament]);

    useEffect(() => {
        const allowedTabs = ['brackets', 'participants', 'schedule', 'rules', 'prizes', 'media'];
        if (tournament_tab && allowedTabs.includes(tournament_tab)) {
            setTab(tournament_tab);
        }
    }, [tournament_tab]);

    if (tournament === null) {
        return null;
    }

    return (
        <>
            <TournamentHead tournament={tournament} />
            <TournamentNavigation tab={tab} onClick={setTab} />

            {tab === 'brackets' && <TournamentBrackets canEdit={canEdit} />}
            {tab === 'participants' && <TournamentParticipants />}
            {tab === 'schedule' && <TournamentSchedule canEdit={canEdit} />}
            {tab === 'rules' && <TournamentRules />}
            {tab === 'prizes' && <TournamentPrizes />}
            {tab === 'media' && <TournamentMedia />}

            {canEdit && <TournamentPanel />}
        </>
    );
};
