import moment from 'moment/moment';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import bg5 from '~app/assets/img/bg5.jpg';
import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { MediaCard } from '~app/components/media-card';
import { Share } from '~app/components/share';
import { useMainContext, useMediaContext, useRootContext } from '~app/contexts';
import { IMedia } from '~app/types/media.interface';
import { makeS3ResourceLink } from '~app/utils';

export const MediaPage = (): JSX.Element | null => {
    const { slug } = useParams();
    const navigate = useNavigate();

    const { hideLoader, showLoader } = useRootContext();
    const { media, fetchMedia, clearMediaData } = useMediaContext();
    const { statistics, fetchStatistics, clearMainData } = useMainContext();

    useEffect(() => {
        fetchStatistics().then();

        return () => {
            clearMediaData();
            clearMainData();
        };
    }, []);

    useEffect(() => {
        if (!slug) {
            navigate('media');

            return;
        }

        fetchMedia(slug).then();
    }, [slug]);

    useEffect(() => {
        if (media !== null && statistics !== null) {
            hideLoader();
        }
    }, [media, statistics]);

    if (media === null) {
        return null;
    }

    return (
        <>
            <section className="section section--head">
                <div className="section-bg">
                    <div className="section-bg__overlay">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div
                        className="section-bg__img"
                        style={{ backgroundImage: `url(${makeS3ResourceLink(media.bg_image)})` }}
                    />
                </div>
                <div className="section-inner">
                    <div className="container">
                        <div className="section-back">
                            <Link className="link-back" to="/media" onClick={showLoader}>
                                <svg className="icon">
                                    <use href={`${MainSymbols}#image-chevron`}></use>
                                </svg>
                                All media
                            </Link>
                        </div>
                        <div className="section-title">
                            <h1 className="h2">{media.title}</h1>
                        </div>
                        <SimpleBar className="infos">
                            <div className="infos-inner">
                                <div className="info">
                                    <div className="icon-content">
                                        <div className="info-value h6">{moment(media.date).format('D MMMM YYYY')}</div>
                                        <div className="info-prop prop">date</div>
                                    </div>
                                </div>
                                <div className="info">
                                    <div className="icon-content">
                                        <div className="info-value h6">
                                            {media.is_text ? 'text' : ''}
                                            {!!media.is_text && !!media.is_video ? '+' : ''}
                                            {media.is_video ? 'video' : ''}
                                        </div>
                                        <div className="info-prop prop">type</div>
                                    </div>
                                </div>
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            </section>

            <section className="section section--main section--sm">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container--sm text-content" dangerouslySetInnerHTML={{ __html: media.content }} />
                    <div className="container--sm text-content">
                        <Share />
                    </div>
                </div>
            </section>
            <section className="section section--sm">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }} />
                </div>
                <div className="section-inner">
                    <div className="section-head">
                        <div className="container--sm">
                            <div className="section-head__container">
                                <h2 className="h3 section-head__title">other media</h2>
                                <Link className="section-head__link" to="/media">
                                    All media
                                    <svg className="icon">
                                        <use href={`${MainSymbols}#image-chevron`}></use>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="container">
                            <SimpleBar className="news">
                                <div className="news-inner">
                                    {statistics?.media?.map((item: IMedia) => <MediaCard key={item.id} media={item} />)}
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
