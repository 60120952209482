import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import bg5 from '~app/assets/img/bg5.jpg';
import bg9 from '~app/assets/img/bg9.jpg';
import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { useRootContext } from '~app/contexts';

export const ContactsPage = (): JSX.Element => {
    const { settingsDict, hideLoader } = useRootContext();

    useEffect(() => {
        if (settingsDict !== null) {
            setTimeout(hideLoader, 500);
        }
    }, [settingsDict]);

    return (
        <>
            <section className="section section--head">
                <div className="section-bg">
                    <div className="section-bg__overlay">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg9})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container">
                        <div className="section-title">
                            <h1 className="h2">Contacts</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--main section--sm">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container--sm">
                        <div className="contacts">
                            <div className="mb">
                                If you have any questions or want to create a tournament, use our platform as an
                                organizer, sponsor, developer or streamer, please contact us at these contacts
                            </div>
                            {settingsDict !== null && (
                                <div className="contacts-items">
                                    <div className="contacts-item">
                                        <Link
                                            className="contacts-link"
                                            target="_blank"
                                            rel="nofollow"
                                            to={settingsDict.contacts_discord}>
                                            <span className="contacts-link__icon">
                                                <svg className="icon">
                                                    <use href={`${MainSymbols}#image-contacts-discord`}></use>
                                                </svg>
                                            </span>
                                            <span className="h5 contacts-link__text">Discord</span>
                                        </Link>
                                    </div>
                                    <div className="contacts-item">
                                        <Link
                                            className="contacts-link"
                                            rel="nofollow"
                                            to={`mailto:${settingsDict.contacts_email}`}>
                                            <span className="contacts-link__icon">
                                                <svg className="icon">
                                                    <use href={`${MainSymbols}#image-contacts-email`}></use>
                                                </svg>
                                            </span>
                                            <span className="h5 contacts-link__text">
                                                {settingsDict.contacts_email}
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="contacts-item">
                                        <Link
                                            className="contacts-link"
                                            target="_blank"
                                            rel="nofollow"
                                            to={settingsDict.contacts_tiktok}>
                                            <span className="contacts-link__icon">
                                                <svg className="icon">
                                                    <use href={`${MainSymbols}#image-contacts-tiktok`}></use>
                                                </svg>
                                            </span>
                                            <span className="h5 contacts-link__text">Tik Tok</span>
                                        </Link>
                                    </div>
                                    <div className="contacts-item">
                                        <Link
                                            className="contacts-link"
                                            target="_blank"
                                            rel="nofollow"
                                            to={settingsDict.contacts_twitch}>
                                            <span className="contacts-link__icon">
                                                <svg className="icon">
                                                    <use href={`${MainSymbols}#image-contacts-twitch`}></use>
                                                </svg>
                                            </span>
                                            <span className="h5 contacts-link__text">Twitch</span>
                                        </Link>
                                    </div>
                                    <div className="contacts-item">
                                        <Link
                                            className="contacts-link"
                                            target="_blank"
                                            rel="nofollow"
                                            to={settingsDict.contacts_youtube}>
                                            <span className="contacts-link__icon">
                                                <svg className="icon">
                                                    <use href={`${MainSymbols}#image-contacts-youtube`}></use>
                                                </svg>
                                            </span>
                                            <span className="h5 contacts-link__text">YouTube</span>
                                        </Link>
                                    </div>
                                    <div className="contacts-item">
                                        <Link
                                            className="contacts-link"
                                            target="_blank"
                                            rel="nofollow"
                                            to={settingsDict.contacts_twitter}>
                                            <span className="contacts-link__icon">
                                                <svg className="icon">
                                                    <use href={`${MainSymbols}#image-contacts-twitter`}></use>
                                                </svg>
                                            </span>
                                            <span className="h5 contacts-link__text">Twitter</span>
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
