import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import TrashIcon from '~app/assets/sprites/trash.svg';
import { useTournamentsContext } from '~app/contexts';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { IPrize, PrizeType } from '~app/types/prize.interface';
import { Tournament } from '~app/types/tournament.type';
import API from '~app/utils/apis';

const EMPTY_PRIZE: Partial<IPrize> = {
    description: '',
    money: '',
};

export interface ITournamentPrizesModalRef {
    open: (_tournament?: Tournament) => Promise<void>;
    close: () => void;
}

export const TournamentPrizesModal = forwardRef(
    (_props: any, ref: React.Ref<ITournamentPrizesModalRef>): JSX.Element | null => {
        const { refreshTournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [isSaving, setSaving] = useState<boolean>(false);
        const [tournamentSlug, setTournamentSlug] = useState<string | null>(null);
        const [prizes, setPrizes] = useState<Partial<IPrize>[]>([]);

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
        }, []);

        const addEmptyPrize = useCallback(
            (type: PrizeType) => () => {
                setPrizes((prev) => {
                    return [...prev, { ...EMPTY_PRIZE, type_id: type }];
                });
            },
            [],
        );

        const deletePrize = useCallback(
            (index: number) => () => {
                setPrizes((prev) => {
                    const result = [...prev];
                    result.splice(index, 1);

                    return result;
                });
            },
            [],
        );

        const updateRow = useCallback(
            (index: number, field: string, newValue?: any) => (event?: React.ChangeEvent<HTMLElement>) => {
                setPrizes((prev) => {
                    const result = [...prev];
                    const item = prev[index] ? { ...prev[index] } : null;

                    if (item) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        item[field] = newValue ?? event?.target.value;

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        result[index] = item;
                    }

                    return result;
                });
            },
            [],
        );

        const saveAndContinueOnClickHandler = useCallback(async () => {
            if (tournamentSlug) {
                setSaving(true);
                try {
                    const data = prizes.filter((_) => !!_.money && !!_.description);

                    await API.Tournaments.updateTournamentPrizes(tournamentSlug, data);
                    await refreshTournament();
                    setOpen(false);
                } catch (err) {
                    console.error(err);
                } finally {
                    setSaving(false);
                }
            }
        }, [tournamentSlug, prizes]);

        useImperativeHandle(ref, () => ({
            async open(_tournament?: Tournament) {
                if (_tournament) {
                    setOpen(true);
                    setTournamentSlug(_tournament.slug);
                    const tournamentPrizes: Partial<IPrize>[] = _tournament.prizes ? [..._tournament.prizes] : [];

                    tournamentPrizes.push({
                        type_id: PrizeType.STANDARD,
                        money: _tournament.prize_one ?? '',
                        description: '1ST PLACE PRIZE',
                        order: 1,
                        enabled: !!_tournament.prize_one,
                    });
                    tournamentPrizes.push({
                        type_id: PrizeType.STANDARD,
                        money: _tournament.prize_two ?? '',
                        description: '2ND PLACE PRIZE',
                        order: 2,
                        enabled: !!_tournament.prize_two,
                    });
                    tournamentPrizes.push({
                        type_id: PrizeType.STANDARD,
                        money: _tournament.prize_three ?? '',
                        description: '3RD PLACE PRIZE',
                        order: 3,
                        enabled: !!_tournament.prize_three,
                    });
                    tournamentPrizes.push({
                        type_id: PrizeType.STANDARD,
                        money: _tournament.prize_four ?? '',
                        description: '4TH PLACE PRIZE',
                        order: 4,
                        enabled: !!_tournament.prize_four,
                    });

                    if (tournamentPrizes.filter((_) => _.type_id === PrizeType.SECONDARY).length === 0) {
                        tournamentPrizes.push({ type_id: PrizeType.SECONDARY });
                    }

                    if (tournamentPrizes.filter((_) => _.type_id === PrizeType.SPECIAL).length === 0) {
                        tournamentPrizes.push({ type_id: PrizeType.SPECIAL });
                    }

                    setPrizes(tournamentPrizes);
                }
            },
            close() {
                setOpen(false);
            },
        }));

        if (!isOpen || tournamentSlug === null) {
            return null;
        }

        return (
            <ModalContainer isShowLoader={isSaving} onClickClose={onClickCloseHandler} title={<>Edit prizes</>}>
                <ModalContentSlot>
                    <>
                        <div className="content-block">
                            <h6 className="content-block__title">Standard Prizes</h6>
                            <div className="add-prizes">
                                {prizes.map((prize, index) => {
                                    if (prize.type_id !== PrizeType.STANDARD) {
                                        return null;
                                    }

                                    return (
                                        <div className={`add-prize ${prize.enabled ? '' : 'disabled'}`} key={index}>
                                            <div className="add-prize__checkbox">
                                                <div className="checkbox checkbox--toggler">
                                                    <label className="checkbox-label">
                                                        <input
                                                            className="checkbox-input"
                                                            type="checkbox"
                                                            id={`prize-${index}`}
                                                            checked={prize.enabled}
                                                            onChange={() => {
                                                                updateRow(index, 'enabled', !prize.enabled)();
                                                                updateRow(index, 'money', '')();
                                                            }}
                                                        />

                                                        <div className="checkbox-content">
                                                            <div className="checkbox-style" />
                                                            <div
                                                                className="checkbox-text h6"
                                                                data-text-in="enabled"
                                                                data-text-out="disabled">
                                                                {prize.enabled ? 'enabled' : 'disabled'}
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="add-prize__title">
                                                <div className="prop">{prize.description}</div>
                                            </div>
                                            <div className="add-prize__value">
                                                <input
                                                    type="text"
                                                    className={`field ${prize.enabled ? '' : 'disabled'}`}
                                                    style={{
                                                        paddingRight: '38px',
                                                    }}
                                                    placeholder="new row"
                                                    value={prize.money}
                                                    onChange={updateRow(index, 'money')}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="content-block dev-special">
                            <h6 className="content-block__title">special prizes</h6>
                            <div className="add append">
                                <div className="add-table">
                                    <div className="table table--static table--controls">
                                        <div className="table-content">
                                            <div className="table-inner">
                                                <table>
                                                    <tbody className="sort-rows append-wrap" data-append="rule">
                                                        {prizes.map((prize, index) => {
                                                            if (prize.type_id !== PrizeType.SPECIAL) {
                                                                return null;
                                                            }

                                                            return (
                                                                <tr
                                                                    className="sort-row append-item"
                                                                    key={`rule-${index}`}>
                                                                    <td>
                                                                        <div className="add-cell">
                                                                            <input
                                                                                type="text"
                                                                                className="field"
                                                                                style={{
                                                                                    paddingRight: '38px',
                                                                                }}
                                                                                placeholder="enter name of prize"
                                                                                value={prize.description}
                                                                                onChange={updateRow(
                                                                                    index,
                                                                                    'description',
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </td>

                                                                    <td className="add-table__lg">
                                                                        <div className="add-cell">
                                                                            <input
                                                                                type="text"
                                                                                className="field"
                                                                                style={{
                                                                                    paddingRight: '38px',
                                                                                }}
                                                                                placeholder="enter prize"
                                                                                value={prize.money}
                                                                                onChange={updateRow(index, 'money')}
                                                                            />
                                                                        </div>
                                                                    </td>

                                                                    <td className="add-table__controls add-table__controls--top">
                                                                        <div className="table-controls">
                                                                            <div className="clear">
                                                                                <a
                                                                                    className="clear-btn"
                                                                                    onClick={deletePrize(index)}>
                                                                                    <img
                                                                                        className="icon icon-button"
                                                                                        src={TrashIcon}
                                                                                        alt=""
                                                                                        style={{
                                                                                            filter: 'invert(100%)',
                                                                                            width: '24px',
                                                                                            position: 'unset',
                                                                                        }}
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-controls">
                                    <div className="add-controls__btn">
                                        <button className="btn btn--md" onClick={addEmptyPrize(PrizeType.SPECIAL)}>
                                            add one
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-block dev-secondary">
                            <h6 className="content-block__title">secondary prizes</h6>
                            <div className="add append">
                                <div className="add-table">
                                    <div className="table table--static table--controls">
                                        <div className="table-content">
                                            <div className="table-inner">
                                                <table>
                                                    <tbody className="sort-rows append-wrap" data-append="rule">
                                                        {prizes.map((prize, index) => {
                                                            if (prize.type_id !== PrizeType.SECONDARY) {
                                                                return null;
                                                            }

                                                            return (
                                                                <tr
                                                                    className="sort-row append-item"
                                                                    key={`rule-${index}`}>
                                                                    <td>
                                                                        <div className="add-cell">
                                                                            <input
                                                                                type="text"
                                                                                className="field"
                                                                                style={{
                                                                                    paddingRight: '38px',
                                                                                }}
                                                                                placeholder="enter name of prize"
                                                                                value={prize.description}
                                                                                onChange={updateRow(
                                                                                    index,
                                                                                    'description',
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </td>

                                                                    <td className="add-table__lg">
                                                                        <div className="add-cell">
                                                                            <input
                                                                                type="text"
                                                                                className="field"
                                                                                style={{
                                                                                    paddingRight: '38px',
                                                                                }}
                                                                                placeholder="enter prize"
                                                                                value={prize.money}
                                                                                onChange={updateRow(index, 'money')}
                                                                            />
                                                                        </div>
                                                                    </td>

                                                                    <td className="add-table__controls add-table__controls--top">
                                                                        <div className="table-controls">
                                                                            <div className="clear">
                                                                                <a
                                                                                    className="clear-btn"
                                                                                    onClick={deletePrize(index)}>
                                                                                    <img
                                                                                        className="icon icon-button"
                                                                                        src={TrashIcon}
                                                                                        alt=""
                                                                                        style={{
                                                                                            filter: 'invert(100%)',
                                                                                            width: '24px',
                                                                                            position: 'unset',
                                                                                        }}
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-controls">
                                    <div className="add-controls__btn">
                                        <button className="btn btn--md" onClick={addEmptyPrize(PrizeType.SECONDARY)}>
                                            add one
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button
                            className="btn"
                            type="button"
                            onClick={saveAndContinueOnClickHandler}
                            disabled={isSaving}>
                            Save and continue
                        </button>
                        <button className="btn" type="button" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
