import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Control } from '~app/components/control';
import { Loader } from '~app/components/loader/loader';
import { Select } from '~app/components/select';
import { useTournamentsContext } from '~app/contexts';
import { useDataContext } from '~app/contexts/DataContext';
import { createEmptyPlayer, Player } from '~app/types/player.type';
import { PlayerRegion, REGION_SELECT_OPTIONS } from '~app/types/player-region.enum';
import { ISelectOption } from '~app/types/select-option.interface';
import { cn } from '~app/utils';
import API from '~app/utils/apis';

import S from './tournament-participant-player.module.scss';

type TournamentParticipantPlayerProps = {
    player: Player;
    onUpdatePlayer: (player: Player) => void;
    teamPlayers?: Player[];
};

export const TournamentParticipantPlayer = ({
    player,
    onUpdatePlayer,
    teamPlayers = [],
}: TournamentParticipantPlayerProps): JSX.Element => {
    const dataContext = useDataContext();
    const { tournament } = useTournamentsContext();

    const [isPlayerCreating, setPlayerCreating] = useState<boolean>(false);
    const [isWorldCreating, setWorldCreating] = useState<boolean>(false);
    const [isLoadingByLink, setLoadingByLink] = useState<boolean>(false);
    const [isLinkFocused, setLinkFocused] = useState<boolean>(false);

    const tournamentPlayers = useMemo(() => {
        console.log(teamPlayers);
        if (!tournament || (!tournament.participants && teamPlayers?.length === 0)) {
            return [];
        }

        const _tournamentPlayers = Object.keys(tournament.participants)
            .map((participantId) => {
                if (tournament.type.isTeamMode) {
                    return tournament.participants[+participantId].team?.players ?? [];
                }

                return [tournament.participants[+participantId].player];
            })
            .flat(1)
            .filter((_) => !!_)
            .map((_) => _?.id);

        return [..._tournamentPlayers, ...teamPlayers.map((_) => _?.id)];
    }, [tournament, teamPlayers]);

    const onChangeLinkHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onUpdatePlayer({ ...player, external_link: event.target.value });
        },
        [player],
    );

    const onClickAddFromLinkHandler = useCallback(async () => {
        if (!player.external_link) {
            return;
        }

        setLinkFocused(true);
        try {
            setLoadingByLink(true);

            const res = await API.Players.external(player.external_link);
            let playerId: number | null | undefined = dataContext.playersSelectOptions.find(
                (_) => _.label.toLowerCase() === res.nick.toLowerCase(),
            )?.value;

            if (playerId && tournamentPlayers.includes(playerId)) {
                toast.warn('Player already exists');

                return;
            }

            let worldId: number | null | undefined = dataContext.playerWorldsSelectOptions.find(
                (_) => _.label.toLowerCase() === res.realm.toLowerCase(),
            )?.value;
            const playerClassId = dataContext.playerClassesSelectOptions.find(
                (_) => _.label.toLowerCase() === res.class.toLowerCase(),
            )?.value;
            const playerRaceId = dataContext.playerRacesSelectOptions.find(
                (_) => _.label.toLowerCase() === res.race.toLowerCase(),
            )?.value;
            const playerFractionId = dataContext.playerFactionsSelectOptions.find(
                (_) => _.label.toLowerCase() === res.faction.toLowerCase(),
            )?.value;

            if (!worldId) {
                const world = await API.PlayerWorlds.create({ name: res.realm });

                dataContext.addWorld(world);
                worldId = world.id;
            }

            const playerData = {
                nick: res.nick,
                avatar: res.avatar,
                external_link: res.external_link,
                race: { id: playerRaceId ?? null },
                class: { id: playerClassId ?? null },
                faction: { id: playerFractionId ?? null },
                world: { id: worldId ?? null },
                region: PlayerRegion[res.region as keyof typeof PlayerRegion],
            };

            if (!playerId) {
                const newPlayer = await API.Players.create(playerData);
                dataContext.addPlayer(newPlayer);
                playerId = newPlayer.id;
            } else {
                await API.Players.update(playerId, playerData);
            }

            onUpdatePlayer({
                ...playerData,
                id: playerId,
                world: { id: worldId ?? null },
            });
        } catch (err) {
            console.error(err);
            toast.error((err as unknown as any).error);
        } finally {
            setLoadingByLink(false);
        }
    }, [
        player,
        tournamentPlayers,
        dataContext.playersSelectOptions,
        dataContext.playerClassesSelectOptions,
        dataContext.playerFactionsSelectOptions,
    ]);

    const updateRow = useCallback(
        (field: string, newValue?: any) => (e: { value: number; label?: string } | string | null) => {
            let result = { ...player };

            switch (field) {
                case 'id': {
                    if (e === null) {
                        result = createEmptyPlayer();
                    } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        result = { ...dataContext.players?.find((_) => _.id === e?.value) };
                    }

                    break;
                }
                case 'class_id':
                case 'race_id':
                case 'faction_id':
                case 'world_id': {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    result[field.replace('_id', '')] = { id: (e as ISelectOption)?.value || null };

                    break;
                }
                case 'region': {
                    result.region = e === null ? null : (e as ISelectOption<PlayerRegion>).data;

                    break;
                }
                default: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    result[field] = newValue || e?.value || e || null;
                }
            }

            onUpdatePlayer(result);
        },
        [player, dataContext.players],
    );

    const createPlayer = useCallback(
        async (nick: string) => {
            onUpdatePlayer({ ...createEmptyPlayer(), nick });

            try {
                setPlayerCreating(true);
                const player = await API.Players.create({ nick });
                dataContext.addPlayer(player);
                onUpdatePlayer(player);
            } catch (error) {
                const err = error as unknown as Error;
                toast.error(err.message);
            } finally {
                setPlayerCreating(false);
            }
        },
        [updateRow],
    );

    const createWorld = useCallback(
        async (name: string) => {
            try {
                setWorldCreating(true);
                const world = await API.PlayerWorlds.create({ name });

                dataContext.addWorld(world);
                updateRow('world_id', world.id)(null);
            } finally {
                setWorldCreating(false);
            }
        },
        [updateRow],
    );

    return (
        <div className={cn(S.container)}>
            <div className={S.nick_or_link}>
                <div className={S.nick_or_link__row}>
                    <div className={S.nick_or_link__nick}>
                        <Control
                            title={isLinkFocused ? null : 'Player nickname'}
                            fields={[
                                <>
                                    <div className="select" key="best_of">
                                        <div className="select-btn">
                                            <Select
                                                isSearchable
                                                isClearable
                                                placeholder="Select player..."
                                                value={player.id || null}
                                                onChange={updateRow('id')}
                                                onInputChange={updateRow('nick')}
                                                onCreateOption={createPlayer}
                                                options={dataContext.playersSelectOptions.filter(
                                                    (_) =>
                                                        _.value === player.id || !tournamentPlayers.includes(_.value),
                                                )}
                                                isFullWidth
                                                isDisabled={isPlayerCreating || isLoadingByLink}
                                                showLoader={isPlayerCreating}
                                            />
                                        </div>
                                    </div>
                                </>,
                            ]}
                        />
                    </div>
                    <div className={S.nick_or_link__link}>
                        {isLoadingByLink && <Loader size="sm" style={{ margin: '-8px' }} />}

                        <input
                            className="field field--md"
                            type="text"
                            name="link"
                            placeholder="insert link to add"
                            value={player.external_link ?? ''}
                            onChange={onChangeLinkHandler}
                            disabled={isLoadingByLink}
                            onFocus={() => setLinkFocused(true)}
                            onBlur={() => setLinkFocused(!!player.external_link)}
                        />

                        <button
                            type="button"
                            className="btn btn--md"
                            onClick={onClickAddFromLinkHandler}
                            disabled={!player.external_link || isLoadingByLink}>
                            load
                        </button>
                    </div>
                </div>
            </div>
            <div className="add-table">
                <div className="table">
                    <div className="table-content">
                        <div className="table-inner">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: '134px' }}>
                                            class <span>*</span>
                                        </th>
                                        <th style={{ minWidth: '134px' }}>
                                            race <span>*</span>
                                        </th>
                                        <th style={{ minWidth: '120px' }}>faction</th>
                                        <th style={{ minWidth: '160px' }}>game world</th>
                                        <th style={{ minWidth: '70px' }}>region</th>
                                    </tr>
                                </thead>
                                <tbody className="append-wrap">
                                    <tr className="append-item">
                                        <td>
                                            <div className="add-cell">
                                                <div className="select select--md">
                                                    <div className="select-btn">
                                                        <Select
                                                            value={player.class?.id || null}
                                                            onChange={updateRow('class_id')}
                                                            options={dataContext.playerClassesSelectOptions}
                                                            isDisabled={isPlayerCreating || isLoadingByLink}
                                                            isFullWidth
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="add-cell">
                                                <div className="select select--md">
                                                    <div className="select-btn">
                                                        <Select
                                                            value={player.race?.id || null}
                                                            onChange={updateRow('race_id')}
                                                            options={dataContext.playerRacesSelectOptions}
                                                            isDisabled={isPlayerCreating || isLoadingByLink}
                                                            isFullWidth
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="add-cell">
                                                <div className="select select--md">
                                                    <div className="select-btn">
                                                        <Select
                                                            value={player.faction?.id || null}
                                                            onChange={updateRow('faction_id')}
                                                            options={dataContext.playerFactionsSelectOptions}
                                                            isDisabled={isPlayerCreating || isLoadingByLink}
                                                            isFullWidth
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ minWidth: 'unset' }}>
                                            <div className="add-cell">
                                                <div className="autocomplete">
                                                    <Select
                                                        isSearchable
                                                        isClearable
                                                        value={player.world?.id || null}
                                                        onChange={updateRow('world_id')}
                                                        onInputChange={updateRow('world_name')}
                                                        onCreateOption={createWorld}
                                                        options={dataContext.playerWorldsSelectOptions}
                                                        isFullWidth
                                                        isDisabled={
                                                            isWorldCreating || isPlayerCreating || isLoadingByLink
                                                        }
                                                        showLoader={isWorldCreating}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="add-cell">
                                                <div className="select select--md">
                                                    <div className="select-btn">
                                                        <Select
                                                            value={
                                                                REGION_SELECT_OPTIONS.find(
                                                                    (_) => _.data === player.region,
                                                                )?.value ?? null
                                                            }
                                                            onChange={updateRow('region')}
                                                            options={REGION_SELECT_OPTIONS}
                                                            isDisabled={isPlayerCreating || isLoadingByLink}
                                                            isFullWidth
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
