import { IDuel } from '~app/types/duel.interface';

export enum ROUND_TYPE {
    MAIN = 1,
    DEFEAT = 2,
    GRAND = 3,
}

export interface IBracketRound {
    duels: IDuel[];
    id: number;
    ordinal: number;
    level: number;
    title: string;
    best_of: number | null;
    active: number | null;
    type_id: ROUND_TYPE | null;
    groups: {
        order: number;
        title: string;
        duels: IDuel[];
    }[];
}
