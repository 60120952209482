import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Select } from '~app/components/select';
import { TournamentParticipantPlayerCard } from '~app/components/tournament-participant-player-card';
import { useTournamentsContext } from '~app/contexts';
import { useDataContext } from '~app/contexts/DataContext';
import { TournamentParticipantPlayer } from '~app/modals/tournament-participants/tournament-participant-player';
import { createEmptyPlayer, Player } from '~app/types/player.type';
import { createEmptyTeam, Team } from '~app/types/team.type';
import API from '~app/utils/apis';

type TournamentParticipantTeamProps = {
    team: Team;
    onUpdateTeam: (team: Team) => void;
};

export const TournamentParticipantTeam = ({ team, onUpdateTeam }: TournamentParticipantTeamProps): JSX.Element => {
    const dataContext = useDataContext();
    const { tournament } = useTournamentsContext();

    const [player, setPlayer] = useState<Player>(createEmptyPlayer());
    const [isTeamCreating, setTeamCreating] = useState<boolean>(false);

    const tournamentTeams = useMemo(() => {
        if (!tournament?.participants) {
            return [];
        }

        return Object.keys(tournament.participants)
            .map((participantId) => tournament.participants[+participantId].team?.id)
            .filter((_) => !!_);
    }, [tournament]);

    const createTeam = useCallback(async (name: string) => {
        try {
            setTeamCreating(true);
            const team = await API.Teams.create(name);
            dataContext.addTeam(team);
            onUpdateTeam(team);
        } catch (error) {
            console.error(error);
            const err = error as unknown as Error;
            toast.error(err.message);
        } finally {
            setTeamCreating(false);
        }
    }, []);

    const updateRow = useCallback(
        (field: string, newValue?: any) => (e: { value: number; label: string } | string | null) => {
            let result = { ...team };
            switch (field) {
                case 'id': {
                    if (e === null) {
                        result = createEmptyTeam();
                    } else if (typeof e !== 'string') {
                        const teamFromDB = dataContext.teams?.find((_) => _.id === e?.value);
                        if (teamFromDB) {
                            result = {
                                ...teamFromDB,
                                players: [],
                            };
                        }
                    }
                    break;
                }
                default: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    result[field] = newValue || e?.value || e || null;
                }
            }

            onUpdateTeam(result);
        },
        [team, dataContext.teams, tournament],
    );

    const onClickAddPlayerHandler = useCallback(() => {
        if (player.id === -1) {
            return;
        }
        team.players.push({ ...player });
        onUpdateTeam(team);
        setPlayer(createEmptyPlayer);
    }, [player, team]);

    const onDeletePlayerHandler = useCallback(
        (playerId: number) => {
            const players = [...team.players].filter((p) => p.id !== playerId);
            onUpdateTeam({ ...team, players });
        },
        [team],
    );

    return (
        <div className="append">
            <div className="content-block">
                <div className="content-block append-item">
                    <div className="add-cell" style={{ marginBottom: '16px' }}>
                        <div className="autocomplete">
                            <Select
                                isSearchable
                                isClearable
                                value={team?.id || null}
                                onChange={updateRow('id')}
                                onInputChange={updateRow('name')}
                                onCreateOption={createTeam}
                                options={dataContext.teamsSelectOptions.filter(
                                    (_) => _.value === team?.id || !tournamentTeams.includes(_.value),
                                )}
                                isFullWidth
                                isDisabled={isTeamCreating}
                                showLoader={isTeamCreating}
                            />
                        </div>
                    </div>

                    {team.id !== -1 && (
                        <>
                            <TournamentParticipantPlayer
                                player={player}
                                onUpdatePlayer={setPlayer}
                                teamPlayers={[...team.players]}
                            />
                            <div style={{ marginTop: '16px', display: 'flex', gap: '32px' }}>
                                <button
                                    className="btn"
                                    type="button"
                                    disabled={
                                        !tournament ||
                                        player.id === -1 ||
                                        tournament.type.playersInTeam === team.players.length
                                    }
                                    onClick={onClickAddPlayerHandler}>
                                    Add player
                                </button>
                            </div>
                        </>
                    )}

                    <div className="participants" style={{ marginTop: '16px' }}>
                        {team.players.map((p) => (
                            <TournamentParticipantPlayerCard
                                player={p}
                                key={`tpt-${p.id}`}
                                onDelete={onDeletePlayerHandler}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
