import React from 'react';

interface IControlProps {
    title: string | null;
    isRequired?: boolean;
    fields?: JSX.Element[];
    main?: JSX.Element;
}
export const Control = (props: IControlProps) => {
    return (
        <div className="control">
            {props.title !== null && (
                <div className="control-side">
                    <div className="prop">
                        {props.title} {props.isRequired && <span>*</span>}
                    </div>
                </div>
            )}

            <div
                className="control-content"
                style={
                    props.title === null
                        ? {
                              width: '100%',
                          }
                        : undefined
                }>
                {props.fields && (
                    <div className="control-fields">
                        {props.fields.map((field, index) => {
                            return (
                                <div key={`control-field-${index}`} className="control-field">
                                    {field}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            {props.main && <div className="control-main">{props.main}</div>}
        </div>
    );
};
