import React, { useCallback } from 'react';

import { TeamName } from '~app/components/common/team-name';
import { EditableField } from '~app/components/editable-field/editable-field';
import { Select } from '~app/components/select';
import { useTournamentsContext } from '~app/contexts';
import { IDuelParticipant } from '~app/types/duel.interface';
import { Participant } from '~app/types/participant.type';
import { ISelectOption } from '~app/types/select-option.interface';
import { Team } from '~app/types/team.type';

import styles from './bracket-relegation-duel.module.scss';

interface RelegationBracketDuelItemProps {
    participant: IDuelParticipant;
    bestOf?: number;
    opponentScore?: number;
    canEdit?: boolean;
    canEditParticipant?: boolean;
    participantsOptions?: ISelectOption<Participant>[];
    bracketParticipants?: number[];
    onChangeScore?: (value?: string) => Promise<void>;
    onChangeParticipant?: (item: ISelectOption<Participant> | null) => void;
}

export const BracketRelegationDuelItem = ({
    participant,
    canEdit,
    bestOf,
    opponentScore,
    canEditParticipant,
    participantsOptions,
    bracketParticipants,
    onChangeScore,
    onChangeParticipant,
}: RelegationBracketDuelItemProps): JSX.Element | null => {
    const { tournament } = useTournamentsContext();

    const scoreValidator = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const strValue = event.target.value.replace(/\D/, '');

            if (
                strValue.length > 0 &&
                bestOf !== undefined &&
                opponentScore !== undefined &&
                +strValue > bestOf - opponentScore
            ) {
                event.target.value = (bestOf - opponentScore).toString();
            } else {
                event.target.value = strValue;
            }
        },
        [bestOf, opponentScore],
    );

    return (
        <div
            className={`${styles.duelItem} ${participant.winner && participant.score !== null ? 'brackets-item--winner' : ''}`}>
            {canEditParticipant && !!participantsOptions && !!bracketParticipants && !!onChangeParticipant && (
                <div
                    style={{
                        flex: 1,
                    }}>
                    <Select
                        value={participant.id}
                        onChange={onChangeParticipant}
                        options={participantsOptions.filter(
                            (_) => participant.id === _.value || !bracketParticipants.includes(_.value),
                        )}
                        isFullWidth
                        isClearable
                    />
                </div>
            )}
            {!canEditParticipant && participant.player?.nick && (
                <div
                    className="brackets-item__title"
                    style={{
                        color: participant.player?.color,
                        flex: 1,
                    }}>
                    {participant.player?.external_link && (
                        <a
                            href={participant.player?.external_link}
                            className="no-decor"
                            target="_blank"
                            rel="noreferrer"
                            onClick={(event) => {
                                // event.preventDefault();
                                event.stopPropagation();
                            }}>
                            {participant.player?.nick}
                        </a>
                    )}
                    {!participant.player?.external_link && participant.player?.nick}
                </div>
            )}
            {!canEditParticipant && participant.team?.name && (
                <TeamName team={(tournament?.participants[participant.id ?? 0]?.team ?? {}) as Team} />
            )}
            {canEdit && !!onChangeScore && (
                <div
                    className="field"
                    style={{
                        width: '60px',
                        minWidth: '60px',
                        height: '38px',
                        padding: 0,
                        position: 'relative',
                    }}>
                    <EditableField
                        name={participant.player?.nick ?? ''}
                        value={participant.score?.toString() ?? undefined}
                        defaultValue="-"
                        onSave={onChangeScore}
                        isDisabled={!participant.id}
                        onSavedDelay={0}
                        valueValidator={scoreValidator}
                    />
                </div>
            )}
            {!canEdit && <div className="group-item__score">{participant.score ?? '-'}</div>}
        </div>
    );
};
