import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Select } from '~app/components/select';
import { BracketRelegationDuel } from '~app/components/tournament-bracket-relegation/bracket-relegation-duel';
import { useTournamentsContext } from '~app/contexts';
import { Bracket } from '~app/types/bracket.type';
import { IBracketRound, ROUND_TYPE } from '~app/types/bracket-round.interface';
import { Participant } from '~app/types/participant.type';
import { ISelectOption } from '~app/types/select-option.interface';
import API from '~app/utils/apis';
import { BEST_OF_OPTIONS } from '~app/utils/constants';

interface IRelegationBracketRoundProps {
    bracket: Bracket;
    round: IBracketRound;
    canEdit: boolean;
    participantsOptions: ISelectOption<Participant>[];
    bracketParticipants: number[];
}

export const BracketRelegationRound = ({
    bracket,
    round,
    canEdit,
    participantsOptions,
    bracketParticipants,
}: IRelegationBracketRoundProps): JSX.Element | null => {
    const { refreshTournament } = useTournamentsContext();
    const [bestOf, setBestOf] = useState<number>(round.best_of ?? bracket.best_of ?? BEST_OF_OPTIONS[0].value);
    const [saving, setSaving] = useState<boolean>(false);

    const canEditParticipant = useMemo(() => {
        return !saving && canEdit && round.type_id === ROUND_TYPE.MAIN && round.level === 1;
    }, [round, canEdit, saving]);

    const handleBestOfChange = useCallback(async (e: { value: number; label: string } | null) => {
        try {
            setSaving(true);
            const newBestOf = e?.value ?? BEST_OF_OPTIONS[0].value;
            setBestOf(newBestOf);
            await API.Brackets.updateRelegationRound(bracket.id, round.id, { best_of: newBestOf });
            await refreshTournament();
        } catch (e) {
            console.error(e);
            setBestOf(round.best_of ?? bracket.best_of ?? BEST_OF_OPTIONS[0].value);
        } finally {
            setSaving(false);
        }
    }, []);

    useEffect(() => {
        setBestOf(round.best_of ?? bracket.best_of ?? BEST_OF_OPTIONS[0].value);
    }, [bracket, round]);

    return (
        <div className="brackets-col" style={{ flex: 1 }} key={round.id}>
            <div className="brackets-title">
                <div
                    className="group-title prop"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{round.title}</span>
                    <div className="select" key="organizer_id">
                        <div className="select-btn" style={{ position: 'relative', width: '68px' }}>
                            {canEdit && round.active !== 1 && (
                                <Select
                                    value={bestOf}
                                    onChange={handleBestOfChange}
                                    options={BEST_OF_OPTIONS.filter((_) => _.value % 2)}
                                    isFullWidth
                                    isDisabled={saving}
                                    showLoader={saving}
                                    controlStyle={{ border: '0 !important' }}
                                />
                            )}
                            {(!canEdit || round.active === 1) && (
                                <span style={{ textAlign: 'right', width: '100%' }}>
                                    {BEST_OF_OPTIONS.find((_) => _.value === bestOf)?.label}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="brackets-round">
                {round.duels.map((duel) => {
                    return (
                        <div className="brackets-game" key={duel.id}>
                            <BracketRelegationDuel
                                duel={duel}
                                round={round}
                                bracket={bracket}
                                bestOf={bestOf}
                                bracketParticipants={bracketParticipants}
                                participantsOptions={participantsOptions}
                                canEdit={canEdit && !saving}
                                canEditParticipant={canEditParticipant}
                            />
                        </div>
                    );
                })}
            </div>
            <div className="brackets-title">
                <div className="group-title prop">{round.title}</div>
            </div>
        </div>
    );
};
