import React, { useEffect, useMemo, useRef, useState } from 'react';

import bg5 from '~app/assets/img/bg5.jpg';
import { Empty } from '~app/components/empty';
import { Loader } from '~app/components/loader/loader';
import { Share } from '~app/components/share';
import { TournamentParticipantPlayerCard } from '~app/components/tournament-participant-player-card';
import { TournamentParticipantTeamCard } from '~app/components/tournament-participant-team-card';
import { TournamentWinners } from '~app/components/tournament-winners';
import { useDataContext, useTournamentsContext } from '~app/contexts';
import {
    ITournamentParticipantModalRef,
    TournamentParticipantModal,
} from '~app/modals/tournament-participants/tournament-participant.modal';
import { Player } from '~app/types/player.type';
import { Tournament } from '~app/types/tournament.type';

export const TournamentParticipants = (): JSX.Element | null => {
    const { tournament, isAllowedEditMode } = useTournamentsContext();
    const { fetchPlayersData, fetchTeams } = useDataContext();
    const [isLoadDataForEditMode, setLoadDataForEditMode] = useState(false);

    const tournamentParticipantModalRef = useRef<ITournamentParticipantModalRef>(null);

    const adminFilter = useMemo(() => {
        if (!tournament || !isAllowedEditMode) {
            return null;
        }

        return (
            <div className="filter filter--admin">
                <div className="filter-main">
                    <div className="filter-wrap">
                        <div className="filter-inner">
                            <div className="filter-btns">
                                <div className="filter-btn">
                                    <button
                                        className="btn btn--sm"
                                        style={{ position: 'relative' }}
                                        disabled={isLoadDataForEditMode}
                                        onClick={() => tournamentParticipantModalRef.current?.open()}>
                                        {isLoadDataForEditMode && <Loader size="sm" />}
                                        Add participant
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [isAllowedEditMode, tournament, isLoadDataForEditMode]);

    useEffect(() => {
        if (!isAllowedEditMode) {
            return;
        }

        const loadDataForEditMode = async (_tournament: Tournament | null) => {
            try {
                setLoadDataForEditMode(true);
                await fetchPlayersData().then();
                console.log(_tournament?.type);
                if (_tournament?.type?.isTeamMode) {
                    await fetchTeams(_tournament.id);
                }
            } finally {
                setLoadDataForEditMode(false);
            }
        };

        loadDataForEditMode(tournament).then();
    }, [isAllowedEditMode, tournament?.id]);

    if (!tournament) {
        return null;
    }

    return (
        <>
            <TournamentWinners />
            <section className="section section--main section--sm">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container--sm">
                        {adminFilter}
                        <div className="filter-content">
                            {Object.keys(tournament.participants).length === 0 && <Empty />}

                            <div className={tournament.type.isTeamMode ? 'participants-teams' : 'participants'}>
                                {Object.keys(tournament.participants).map((participantId) => {
                                    const participant = tournament.participants[+participantId];

                                    if (participant.player !== undefined) {
                                        return (
                                            <TournamentParticipantPlayerCard
                                                player={tournament.participants[+participantId].player as Player}
                                                participantId={+participantId}
                                                key={participantId}
                                            />
                                        );
                                    }

                                    if (participant.team !== undefined) {
                                        return (
                                            <TournamentParticipantTeamCard
                                                key={participantId}
                                                team={participant.team}
                                                participantId={+participantId}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </div>
                        {adminFilter}
                        <Share />
                    </div>
                </div>
            </section>

            <TournamentParticipantModal ref={tournamentParticipantModalRef} />
        </>
    );
};
