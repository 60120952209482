import React, { CSSProperties } from 'react';

import preloader from '~app/assets/img/preloader-t.gif';

import styles from './loader.module.scss';

interface ILoaderProps {
    size: 'sm' | 'md' | 'lg';
    className?: string;
    style?: CSSProperties;
}

export const Loader = (props: ILoaderProps): JSX.Element => {
    return (
        <div className={[styles.container, props.className].join(' ')} style={props.style}>
            <img className={styles[props.size]} src={preloader} alt="" />
        </div>
    );
};
