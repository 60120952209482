import React from 'react';
import SimpleBar from 'simplebar-react';

import { useTournamentsContext } from '~app/contexts';
import { BracketType } from '~app/types/bracket.type';
import { ALPHABET } from '~app/utils/constants';

export const TournamentBracketMenu = (): JSX.Element | null => {
    const { currentBracket, currentRoundId, setCurrentRoundId } = useTournamentsContext();

    if (!currentBracket || currentBracket.type === BracketType.TABLE) {
        return null;
    }

    return (
        <SimpleBar className="nav nav--sm">
            <div className="container--sm">
                <div className="nav-container">
                    <ul className="nav-list" id="group-or-swiss-menu">
                        {currentBracket?.type === BracketType.RELEGATION && !!currentBracket.roundsMain && (
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${currentRoundId === 'main-bracket' ? 'active' : ''}`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setCurrentRoundId('main-bracket')}>
                                    {currentBracket.roundsMain.title}
                                </a>
                            </li>
                        )}

                        {currentBracket?.type === BracketType.RELEGATION &&
                            !!currentBracket.second_defeat &&
                            !!currentBracket.roundsDefeat && (
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${currentRoundId === 'defeat-bracket' ? 'active' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setCurrentRoundId('defeat-bracket')}>
                                        {currentBracket.roundsDefeat.title}
                                    </a>
                                </li>
                            )}

                        {currentBracket?.type === BracketType.RELEGATION && !!currentBracket.roundsGrand && (
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${currentRoundId === 'grand-bracket' ? 'active' : ''}`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setCurrentRoundId('grand-bracket')}>
                                    {currentBracket.roundsGrand.title}
                                </a>
                            </li>
                        )}

                        {currentBracket?.type === BracketType.GROUP &&
                            currentBracket.groups
                                ?.sort((a, b) => a.ordinal - b.ordinal)
                                .map((group) => (
                                    <li className="nav-item" key={group.ordinal}>
                                        <a
                                            className={`nav-link ${currentRoundId === group.id.toString() ? 'active' : ''}`}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setCurrentRoundId(group.id.toString())}>
                                            Group {ALPHABET[group.ordinal - 1]}
                                        </a>
                                    </li>
                                ))}

                        {(currentBracket?.type === BracketType.OLD_GROUP ||
                            currentBracket?.type === BracketType.SWISS) &&
                            currentBracket.rounds?.map((round) => (
                                <li className="nav-item" key={round.ordinal}>
                                    <a
                                        className={`nav-link ${currentRoundId === round.ordinal.toString() ? 'active' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setCurrentRoundId(round.ordinal.toString())}>
                                        {round.title}
                                    </a>
                                </li>
                            ))}

                        {(currentBracket?.type === BracketType.GROUP ||
                            ((currentBracket?.type === BracketType.OLD_GROUP ||
                                currentBracket?.type === BracketType.SWISS) &&
                                currentBracket.standings?.length)) > 0 && (
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${currentRoundId === 'standings' ? 'active' : ''}`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setCurrentRoundId('standings')}>
                                    standings
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </SimpleBar>
    );
};
