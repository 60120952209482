import { PlayerClass } from '~app/types/player-class.type';
import { PlayerFaction } from '~app/types/player-faction.type';
import { PlayerRace } from '~app/types/player-race.type';
import { PlayerRegion } from '~app/types/player-region.enum';
import { PlayerWorld } from '~app/types/player-world.type';

export type Player = {
    id: number;
    nick: string | null;
    avatar: string | null;
    external_link: string | null;
    class?: Partial<PlayerClass>;
    faction?: Partial<PlayerFaction>;
    race?: Partial<PlayerRace>;
    world?: Partial<PlayerWorld>;
    region?: PlayerRegion | null;
};

export const createEmptyPlayer = (): Player => {
    return {
        id: -1,
        nick: null,
        avatar: null,
        external_link: null,
        class: { id: null },
        faction: { id: null },
        race: { id: null },
        world: { id: null },
        region: null,
    };
};
