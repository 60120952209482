import React, { useCallback, useRef } from 'react';

import EditIcon from '~app/assets/sprites/edit.svg';
import TrashIcon from '~app/assets/sprites/trash.svg';
import { TournamentParticipantPlayerCard } from '~app/components/tournament-participant-player-card';
import { useTournamentsContext } from '~app/contexts';
import { ConfirmationModal, IConfirmationModalRef } from '~app/modals/confirmation.modal';
import {
    ITournamentParticipantModalRef,
    TournamentParticipantModal,
} from '~app/modals/tournament-participants/tournament-participant.modal';
import { Team } from '~app/types/team.type';
import API from '~app/utils/apis';

import S from './tournament-participant-team-card.module.scss';

type TournamentPlayerParticipantProps = {
    team: Team;
    participantId?: number;
};

export const TournamentParticipantTeamCard = ({
    team,
    participantId,
}: TournamentPlayerParticipantProps): JSX.Element => {
    const { tournament, refreshTournament, isAllowedEditMode } = useTournamentsContext();

    const confirmationModalRef = useRef<IConfirmationModalRef>(null);
    const tournamentParticipantModalRef = useRef<ITournamentParticipantModalRef>(null);

    const onClickDeleteHandler = useCallback(() => {
        if (tournament === null || !participantId || !isAllowedEditMode) {
            return;
        }

        const title = (
            <>
                Delete participant: <span>{team.name}</span>
            </>
        );
        const description = (
            <>
                Please confirm the deletion of the <span>{team.name}</span> participant
            </>
        );
        const deleteBracket = async () => {
            await API.Tournaments.deleteTournamentParticipant(tournament.slug, participantId);
            await refreshTournament();
        };

        confirmationModalRef.current?.open(title, description, deleteBracket);
    }, [tournament, isAllowedEditMode, team, participantId]);

    const onClickEditHandler = useCallback(() => {
        if (!participantId || !isAllowedEditMode) {
            return;
        }

        tournamentParticipantModalRef.current?.open({ id: participantId, team });
    }, [isAllowedEditMode, participantId, team]);

    return (
        <div className={S.participant}>
            <div className="participants-team">
                <div className="participants">
                    <div className="participants-title h6">{team.name}</div>
                    <div className="participants">
                        {team.players?.map((player) => (
                            <TournamentParticipantPlayerCard player={player} key={player.id} />
                        ))}
                    </div>
                </div>
            </div>

            {tournament !== null && !!participantId && isAllowedEditMode && (
                <div className={S.participantControls}>
                    <div className={S.icon} onClick={onClickDeleteHandler}>
                        <img src={TrashIcon} alt="" />
                    </div>
                    <div className={S.icon} onClick={onClickEditHandler}>
                        <img src={EditIcon} alt="" />
                    </div>
                </div>
            )}

            <ConfirmationModal ref={confirmationModalRef} />
            <TournamentParticipantModal ref={tournamentParticipantModalRef} />
        </div>
    );
};
