export enum UserStatus {
    A,
    B,
    C,
    D,
    F,
    G,
    R,
    T,
    Q,
    W,
    k,
}

export enum UserRole {
    ADMIN = 'admin',
    ROOT = 'root',
    ORGANIZER = 'organizer',
    USER = 'user',
}

export interface IUser {
    id: number;
    username: string;
    email: string;
    avatar: string;
    role: UserRole;
    status: UserStatus;
    language_id: number | null;
    marks: string;
    time_zone: string | null;
    created_at: number;
    updated_at: number;
}
