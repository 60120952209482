import { Player } from '~app/types/player.type';

export type Team = {
    id: number | null;
    name: string | null;
    players: Player[];
};

export const createEmptyTeam = (): Team => {
    return {
        id: -1,
        name: null,
        players: [],
    };
};
