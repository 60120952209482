import { useFormik } from 'formik';
import moment from 'moment';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Control } from '~app/components/control';
import { DatePicker } from '~app/components/datepicker';
import { Loader } from '~app/components/loader/loader';
import { Select } from '~app/components/select';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { useDataContext } from '~app/contexts/DataContext';
import { ModalContainer, ModalContentSlot, ModalFooterSlot } from '~app/modals/modal-container';
import { IFileInfo } from '~app/types/file-info.type';
import { Tournament, TournamentFormikProps, TournamentStatus } from '~app/types/tournament.type';
import { UserRole } from '~app/types/user.interface';
import { makeS3ResourceLink } from '~app/utils';
import API from '~app/utils/apis';
import { timeZones } from '~app/utils/constants';
import { requestSecure } from '~app/utils/request';

export interface ITournamentMainInfoModalRef {
    open: (_tournament?: Tournament) => Promise<void>;
    close: () => void;
}

export const TournamentMainInfoModal = forwardRef(
    (props: any, ref: React.Ref<ITournamentMainInfoModalRef>): JSX.Element | null => {
        const navigate = useNavigate();
        const { profile, tournamentTypesSelectOptions } = useRootContext();
        const { languagesSelectOptions, fetchLanguages } = useDataContext();
        const { refreshTournament } = useTournamentsContext();

        const [isOpen, setOpen] = useState<boolean>(false);
        const [isSaving, setSaving] = useState<boolean>(false);
        const [isLoading, setLoading] = useState<boolean>(true);
        const [isBgImageUploading, setBgImageUploading] = useState<boolean>(false);
        const [tournament, setTournament] = useState<Partial<Tournament> | null>(null);
        const [organizersList, setOrganizersList] = useState<{ value: number; label: string }[]>([]);

        const formik = useFormik<TournamentFormikProps>({
            initialValues: {
                startDate: null,
                startTime: null,
                finalDate: null,
                finalTime: null,
                organizer_id: null,
                type_id: null,
                language_id: null,
                time_zone: null,
            },
            validationSchema: yup.object({
                startDate: yup.date().required(),
                startTime: yup.date().required(),
                bgImage: yup.string().required(),
                organizer_id: yup.number().required(),
                type_id: yup.number().required(),
                title: yup.string().required(),
                language_id: yup.number().required(),
                time_zone: yup.number().required(),
            }),
            onSubmit: async () => {
                if (!formik.isValid) {
                    return;
                }

                const data: Partial<Tournament> = {
                    title: formik.values.title,
                    bg_image: formik.values.bgImage,
                    time_zone: formik.values.time_zone ?? undefined,
                    date: moment(formik.values.startDate).format('YYYY-MM-DD'),
                    date_final: moment(formik.values.finalDate).format('YYYY-MM-DD'),
                    time: moment(formik.values.finalDate).format('HH:mm:ss'),
                    time_final: moment(formik.values.finalTime).format('HH:mm:ss'),
                    organizer_id: formik.values.organizer_id,
                    type: { id: formik.values.type_id ?? null },
                    language_id: formik.values.language_id ?? undefined,
                    pool_custom: formik.values.pool_custom ?? undefined,
                };

                try {
                    setSaving(true);

                    if (formik.values.slug) {
                        const res = await API.Tournaments.updateTournament(formik.values.slug, data);
                        if (formik.values.slug === res.slug) {
                            await refreshTournament();
                        } else {
                            navigate(`/tournaments/${res.slug}`);
                        }
                    } else {
                        const res = await API.Tournaments.createTournament(data);
                        navigate(`/tournaments/${res.slug}`);
                    }

                    await formik.setValues({
                        startDate: null,
                        startTime: null,
                        finalDate: null,
                        finalTime: null,
                        organizer_id: null,
                        type_id: null,
                        language_id: null,
                        time_zone: null,
                    });

                    setOpen(false);
                    setTournament(null);
                } catch (e) {
                    console.error(e);
                } finally {
                    setSaving(false);
                }
            },
        });

        const [bgImage, setBgImage] = useState<IFileInfo | null>(null);

        const canChangeType = useMemo<boolean>(() => {
            return (
                !tournament ||
                (Object.keys(tournament.participants ?? {}).length === 0 &&
                    (!tournament.brackets || tournament.brackets.length === 0) &&
                    tournament.status === TournamentStatus.STATUS_PENDING)
            );
        }, [tournament]);

        useImperativeHandle(ref, () => ({
            async open(_tournament?: Tournament) {
                setOpen(true);
                setSaving(false);
                setLoading(true);
                setBgImage(null);

                await formik.setValues({
                    startDate: null,
                    startTime: null,
                    finalDate: null,
                    finalTime: null,
                    organizer_id: null,
                    type_id: null,
                    language_id: null,
                    time_zone: null,
                });

                if (_tournament) {
                    setTournament(_tournament);
                    await formik.setValues({
                        slug: _tournament.slug,
                        startDate: _tournament.date ? moment(_tournament.date).toDate() : null,
                        startTime: _tournament.time ? moment(_tournament.time, 'HH:mm:ss').toDate() : null,
                        finalDate:
                            _tournament.date_final && _tournament.date_final !== '0000-00-00'
                                ? moment(_tournament.date_final).toDate()
                                : null,
                        finalTime: _tournament.time_final ? moment(_tournament.time_final, 'HH:mm:ss').toDate() : null,
                        organizer_id: _tournament.organizer_id,
                        type_id: _tournament.type?.id || null,
                        language_id: _tournament.language_id,
                        time_zone: _tournament.time_zone,
                        pool_custom: _tournament.pool_custom,
                        bgImage: _tournament.bg_image,
                        title: _tournament.title,
                    });
                }

                formik.validateForm().then();
            },
            close() {
                setOpen(false);
            },
        }));

        const onClickCloseHandler = useCallback(() => {
            setOpen(false);
        }, []);

        const handleOrganizerChange = useCallback((e: { value: number; label: string } | null) => {
            formik.setFieldValue('organizer_id', e?.value ?? null);
        }, []);

        const handleTypeChange = useCallback((e: { value: number; label: string } | null) => {
            formik.setFieldValue('type_id', e?.value ?? null);
        }, []);

        const handleLanguageChange = useCallback((e: { value: number; label: string } | null) => {
            formik.setFieldValue('language_id', e?.value ?? null);
        }, []);

        const handleTimeZoneChange = useCallback((e: { value: number; label: string } | null) => {
            formik.setFieldValue('time_zone', e?.value ?? null);
        }, []);

        const handleBgImageChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files;

            formik.setFieldValue('bgImage', null).then();

            if (!files || !files.length) {
                setBgImage(null);

                return;
            }

            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                const reader = new FileReader();

                reader.readAsDataURL(file);

                reader.onload = async () => {
                    const fileInfo = {
                        name: file.name,
                        type: file.type,
                        size: Math.round(file.size / 1000),
                        base64: reader.result,
                        file: file,
                    };

                    setBgImage(fileInfo);

                    try {
                        setBgImageUploading(true);
                        const postFilesResp = await requestSecure<{ path: string }>('/files', {
                            method: 'POST',
                            data: fileInfo,
                        });

                        formik.setFieldValue('bgImage', postFilesResp.path).then();
                    } catch (error) {
                        setBgImage(null);
                    } finally {
                        setBgImageUploading(false);
                    }
                };
            }
        }, []);

        useEffect(() => {
            const asyncHandler = async () => {
                if (profile === null || !isOpen) {
                    return;
                }

                await formik.setFieldValue('organizer_id', profile.id);

                await fetchLanguages();

                if (profile.role === UserRole.ADMIN || profile.role === UserRole.ROOT) {
                    const res = await API.Organizers.fetchList();
                    const options = res.map((_) => ({ value: _.id, label: _.username }));
                    setOrganizersList(options);
                }
                setLoading(false);
            };

            asyncHandler().then();
        }, [profile, isOpen]);

        if (!isOpen || !profile) {
            return null;
        }

        if (tournament?.status === TournamentStatus.STATUS_IN_PROGRESS) {
            return (
                <ModalContainer onClickClose={onClickCloseHandler} title="Forbidden action">
                    <ModalContentSlot>
                        <div className="content-block">
                            <p className="secondary">You cannot change main info while tournament in progress</p>
                        </div>
                    </ModalContentSlot>
                    <ModalFooterSlot>
                        <button className="btn" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </ModalFooterSlot>
                </ModalContainer>
            );
        }

        return (
            <ModalContainer
                isShowLoader={isSaving || isLoading}
                onClickClose={onClickCloseHandler}
                title={
                    <>
                        {!tournament?.id ? 'Add' : 'Edit'} main info <span>/tournament/{profile?.role}</span>
                    </>
                }>
                <ModalContentSlot>
                    <form className="popup-content" onSubmit={formik.handleSubmit}>
                        <div className="content-block">
                            <div className="controls">
                                {(profile?.role === UserRole.ADMIN || profile?.role === UserRole.ROOT) && (
                                    <Control
                                        title={'Organizer'}
                                        isRequired
                                        fields={[
                                            <div className="select" key="organizer_id">
                                                <div className="select-btn">
                                                    <Select
                                                        value={formik.values.organizer_id}
                                                        onChange={handleOrganizerChange}
                                                        options={organizersList}
                                                        isFullWidth
                                                        isDisabled={
                                                            !!tournament &&
                                                            tournament.status !== TournamentStatus.STATUS_PENDING
                                                        }
                                                    />
                                                </div>
                                            </div>,
                                        ]}
                                    />
                                )}

                                <Control
                                    title={'The name of the tournament'}
                                    isRequired
                                    fields={[
                                        <div className="input" key="title">
                                            <input
                                                type="text"
                                                className="field"
                                                name="title"
                                                placeholder="title"
                                                value={formik.values.title}
                                                onChange={formik.handleChange}
                                                disabled={
                                                    !!tournament &&
                                                    tournament.status !== TournamentStatus.STATUS_PENDING
                                                }
                                            />
                                        </div>,
                                    ]}
                                />

                                <Control
                                    title={'tournament type'}
                                    isRequired
                                    fields={[
                                        <div className="select" key="type_id">
                                            <div className="select-btn">
                                                <Select
                                                    value={formik.values.type_id}
                                                    onChange={handleTypeChange}
                                                    options={tournamentTypesSelectOptions}
                                                    isFullWidth
                                                    isDisabled={!canChangeType}
                                                />
                                            </div>
                                        </div>,
                                    ]}
                                />

                                <Control
                                    title={'language'}
                                    isRequired
                                    fields={[
                                        <div className="select" key="language_id">
                                            <div className="select-btn">
                                                <Select
                                                    value={formik.values.language_id}
                                                    onChange={handleLanguageChange}
                                                    options={languagesSelectOptions}
                                                    isFullWidth
                                                    isDisabled={
                                                        !!tournament &&
                                                        tournament.status !== TournamentStatus.STATUS_PENDING
                                                    }
                                                />
                                            </div>
                                        </div>,
                                    ]}
                                />

                                <Control
                                    title={'timezone'}
                                    isRequired
                                    fields={[
                                        <div className="select" key="time_zone">
                                            <div className="select-btn">
                                                <Select
                                                    value={formik.values.time_zone}
                                                    onChange={handleTimeZoneChange}
                                                    options={timeZones}
                                                    isFullWidth
                                                    isDisabled={
                                                        !!tournament &&
                                                        tournament.status !== TournamentStatus.STATUS_PENDING
                                                    }
                                                />
                                            </div>
                                        </div>,
                                    ]}
                                />

                                <Control
                                    title={'Prize Pool'}
                                    fields={[
                                        <div className="input" key="pool_custom">
                                            <input
                                                type="text"
                                                className="field"
                                                name="pool_custom"
                                                placeholder="prize pool"
                                                value={formik.values.pool_custom}
                                                onChange={formik.handleChange}
                                            />
                                        </div>,
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="content-block">
                            <div className="controls">
                                <div className="control">
                                    <div className="control-side">
                                        <div className="prop">
                                            Tournament start date and time <span>*</span>
                                        </div>
                                    </div>
                                    <div className="control-content">
                                        <div className="control-fields">
                                            <div className="control-field control-field--sm">
                                                <DatePicker
                                                    date={formik.values.startDate}
                                                    onChange={(date) => {
                                                        formik.setFieldValue('startDate', date);
                                                    }}
                                                    maxDate={formik.values.finalDate}
                                                    dateFormat="DD/MM/YYYY"
                                                />
                                            </div>
                                            <div className="control-field control-field--sm">
                                                <DatePicker
                                                    date={formik.values.startTime}
                                                    onChange={(date) => {
                                                        formik.setFieldValue('startTime', date);
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={60}
                                                    timeCaption="Time"
                                                    dateFormat="hh:mm a"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="control">
                                    <div className="control-side">
                                        <div className="prop">Tournament finale date and time (last match)</div>
                                    </div>
                                    <div className="control-content">
                                        <div className="control-fields">
                                            <div className="control-field control-field--sm">
                                                <DatePicker
                                                    date={formik.values.finalDate}
                                                    onChange={(date) => {
                                                        formik.setFieldValue('finalDate', date);
                                                    }}
                                                    minDate={formik.values.startDate}
                                                    dateFormat="DD/MM/YYYY"
                                                />
                                            </div>
                                            <div className="control-field control-field--sm">
                                                <DatePicker
                                                    date={formik.values.finalTime}
                                                    onChange={(date) => {
                                                        formik.setFieldValue('finalTime', date);
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={60}
                                                    timeCaption="Time"
                                                    dateFormat="hh:mm a"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-block">
                            <div className="controls">
                                <Control
                                    title={'tournament cover'}
                                    isRequired
                                    fields={[
                                        <div className="control-field" key="tournament-cover">
                                            <div className="control-file">
                                                <label className="control-file__btn" htmlFor="tournamentBg">
                                                    <div
                                                        className="btn btn--md"
                                                        data-text-in="add cover"
                                                        data-text-out="change-cover">
                                                        {bgImage || formik.values.bgImage ? 'Edit' : 'Add'} cover
                                                    </div>
                                                    <input
                                                        className="control-file__field"
                                                        type="file"
                                                        id="tournamentBg"
                                                        onChange={handleBgImageChange}
                                                        disabled={
                                                            !!tournament &&
                                                            tournament.status !== TournamentStatus.STATUS_PENDING
                                                        }
                                                    />
                                                </label>
                                            </div>
                                        </div>,
                                    ]}
                                    main={
                                        <div
                                            className={`control-file__bg ${bgImage || formik.values.bgImage ? 'active' : ''}`}>
                                            {isBgImageUploading && <Loader size="sm" />}
                                            {(bgImage?.base64 || formik.values.bgImage) && (
                                                <img
                                                    id="bg_image_preview"
                                                    style={{
                                                        objectPosition: 'center center !important',
                                                    }}
                                                    src={
                                                        bgImage
                                                            ? (bgImage.base64 as string)
                                                            : makeS3ResourceLink(formik.values.bgImage)
                                                    }
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </form>
                </ModalContentSlot>
                <ModalFooterSlot>
                    <>
                        <button className="btn" type="submit" disabled={!formik.isValid} onClick={formik.submitForm}>
                            Save and continue
                        </button>
                        <button className="btn" type="button" onClick={onClickCloseHandler}>
                            cancel
                        </button>
                    </>
                </ModalFooterSlot>
            </ModalContainer>
        );
    },
);
