import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Loader } from '~app/components/loader/loader';
import { BracketSwissDuel } from '~app/components/tournament-bracket-swiss/bracket-swiss-duel';
import { useTournamentsContext } from '~app/contexts';
import { Participant } from '~app/types/participant.type';
import { ISelectOption } from '~app/types/select-option.interface';
import { IStanding } from '~app/types/standing.interface';
import API from '~app/utils/apis';
import { BEST_OF_OPTIONS } from '~app/utils/constants';

import styles from './tournament-bracket-swiss.module.scss';

interface ITournamentBracketSwissProps {
    canEdit: boolean;
    onClickInsertParticipants: () => void;
}

export const TournamentBracketSwiss = ({
    canEdit,
    onClickInsertParticipants,
}: ITournamentBracketSwissProps): JSX.Element | null => {
    const { currentBracket, currentRoundId, tournament, refreshTournament } = useTournamentsContext();

    const [isGenerating, setGenerating] = useState<boolean>(false);

    const bracketParticipants = useMemo<number[]>(() => {
        return currentBracket?.standings.map((_) => (_ as IStanding).id) ?? [];
    }, [currentBracket]);

    const participantsOptions = useMemo<ISelectOption<Participant>[]>(() => {
        const participants = tournament?.participants ?? [];

        return Object.keys(participants).map((playerId) => ({
            label: participants[+playerId].player?.nick || participants[+playerId].team?.name || '',
            value: participants[+playerId].id,
            data: { ...participants[+playerId] },
        }));
    }, [tournament]);

    const canEditParticipant = useMemo(() => {
        return canEdit;
    }, [canEdit]);

    const round = useMemo(() => {
        if (!currentBracket || !currentRoundId) {
            return null;
        }

        return currentBracket.rounds.find((_) => _.ordinal === +currentRoundId) || null;
    }, [currentBracket, currentRoundId]);

    const canGenerate = useMemo(() => {
        if (!currentBracket || !round) {
            return false;
        }

        const previousRound = currentBracket.rounds.find((_) => _.ordinal === round.ordinal - 1);

        return (
            !!previousRound &&
            previousRound.duels.every((duel) => {
                return (
                    (duel.participant1.score > 0 && duel.participant1.winner) ||
                    (duel.participant2.score > 0 && duel.participant2.winner) ||
                    duel.draw === 1 ||
                    (!tournament?.type.isTeamMode &&
                        ((duel.participant1.player?.nick === null && duel.participant2.player?.nick !== null) ||
                            (duel.participant1.player?.nick !== null && duel.participant2.player?.nick === null))) ||
                    (tournament?.type.isTeamMode &&
                        ((duel.participant1.team?.name === null && duel.participant2.team?.name !== null) ||
                            (duel.participant1.team?.name !== null && duel.participant2.team?.name === null)))
                );
            })
        );
    }, [currentBracket, round]);

    const onClickGenerateHandler = useCallback(async () => {
        if (currentBracket && round) {
            try {
                setGenerating(true);
                await API.BracketSwiss.generateRound(currentBracket.id, round.id);
                await refreshTournament();
            } catch (err) {
                console.error(err);
                toast.error((err as unknown as any).error);
            } finally {
                setGenerating(false);
            }
        }
    }, [currentBracket, round, tournament]);

    if (!currentBracket || !tournament || !round) {
        return null;
    }

    return (
        <div className="container--sm">
            <div className={styles.controls}>
                <div className={styles.controls_divider} />
                {canEdit && round.ordinal === 1 && round.active === 0 && (
                    <button className="btn btn--sm" style={{ margin: '0 8px' }} onClick={onClickInsertParticipants}>
                        Insert participants
                    </button>
                )}
                {canEdit && round.ordinal > 1 && round.active === 0 && (
                    <button
                        className="btn btn--sm"
                        style={{ margin: '0 8px', position: 'relative' }}
                        disabled={!canGenerate}
                        onClick={onClickGenerateHandler}>
                        Generate
                        {isGenerating && <Loader size="sm" />}
                    </button>
                )}
                <div className={['select-btn', styles.controls_boSelect].join(',')}>
                    <div
                        style={{
                            textAlign: 'right',
                            width: '100%',
                            lineHeight: '59px',
                            padding: '0 16px',
                            background: '#3c3c3c',
                        }}>
                        {BEST_OF_OPTIONS.find((_) => _.value === currentBracket.best_of)?.label}
                    </div>
                </div>
            </div>
            <div className="section-inner">
                <div className="container--sm">
                    <div className="tabs active">
                        <div className="tabs-item">
                            <div className="filter-content filter-content--sm">
                                <div className="groups">
                                    <div className="group-wrap">
                                        <div className="group group--admin">
                                            <div className="group-items">
                                                {round?.duels.map((duel) => {
                                                    return (
                                                        <BracketSwissDuel
                                                            key={duel.id}
                                                            duel={duel}
                                                            round={round}
                                                            bracket={currentBracket}
                                                            canEdit={canEdit}
                                                            bracketParticipants={round.duels
                                                                .map((_) => [
                                                                    _.participant1.id ?? 0,
                                                                    _.participant2.id ?? 0,
                                                                ])
                                                                .flat()}
                                                            participantsOptions={
                                                                round.ordinal === 1
                                                                    ? participantsOptions
                                                                    : participantsOptions.filter((_) =>
                                                                          bracketParticipants.includes(_.value),
                                                                      )
                                                            }
                                                            canEditParticipant={canEditParticipant}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
